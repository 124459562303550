import { Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { SelectOption } from '../Select'
import SelectWithAutocomplete from '../SelectWithAutocomplete'
import { FilterSlotProps } from '../FilterSheet'

export function LocationFilter({ 
    title = "Filtrar por tienda",
    state, 
    stateSetter, 
    locationOptions,
    helperText="La tienda de destino del pedido",
    label="Tienda de destino"
}: FilterSlotProps & { 
    title?: string
    locationOptions: SelectOption[] 
    helperText?: string
    label?: string
}) {

    const selectedOption = useMemo(
        () => (
            locationOptions
                .find(it => it.value === state.get('location'))
                ?? null
        ),
        [state, locationOptions]
    )

    const setValue = useCallback((option: SelectOption | null) => {
        const finalValue = option?.value ?? null
        if (finalValue != null) {
            return stateSetter.set('location', finalValue)
        }
        return stateSetter.remove('location')
    }, [stateSetter])
    return (
        <section>
            <Typography className="pb-2" variant='subtitle1'>{title}</Typography>
            <div className='flex flex-col gap-2 sm:flex-col'>
                <SelectWithAutocomplete
                    name="locationId"
                    label={label}
                    sx={{ minWidth: 150 }}
                    selectedOption={selectedOption}
                    values={locationOptions}
                    helperText={helperText}
                    setValue={setValue}
                />
            </div>
        </section>
    )
}