import { useMutation, useQuery } from '@apollo/client'
import { Button, CircularProgress, Collapse, DialogTitle } from '@mui/material'
import { useFormik } from 'formik'
import React, { useCallback, useMemo } from 'react'
import { z } from 'zod'
import { GetSuppliedProductsQuery, ProductType } from '../../../__generated__/graphql'
import { GET_CURRENT_STOCKS_QUERY } from '../../../network/get-supplied-products-query'
import { RETURN_PRODUCT_MUTATION } from '../../../network/return-product/return-product-mutation'
import BottomSheetDialog from '../../components/bottom-sheet/BottomSheetDialog'
import LoadingButton from '../../components/loading-button'
import { validationFrom } from '../../forms/validation'
import SelectWithAutocomplete from '../SelectWithAutocomplete'
import WeightInput, { parseKiloString, zodWeightSchema } from '../WeightInput'
import { useParams } from 'react-router-dom'
import { LIST_RETURNED_PRODUCTS_QUERY } from '../../../network/return-product/returned-products-query'

interface RefundDialogProps {
    open: boolean,
    onClose: () => void
}

function useSuppliedProducts() {
    return useQuery(GET_CURRENT_STOCKS_QUERY)
}

const ReturnedProductSchema = z.object({
    productId: z.string({
        required_error: 'Debes seleccionar un producto'
    }),
    kilos: zodWeightSchema().optional().nullable()
});

const validationSchema = validationFrom(ReturnedProductSchema)

type SuppliedProduct = GetSuppliedProductsQuery['suppliedProducts'][number]

interface RefundProductFormProps {
    suppliedProducts: Array<SuppliedProduct>,
    formik: any,
    selectedProduct: SuppliedProduct | null,
    isIcecream: boolean
}

function RefundProductForm({
    suppliedProducts,
    formik,
    selectedProduct,
    isIcecream
}: RefundProductFormProps) {

    return (<>
        <SelectWithAutocomplete
            label="Producto"
            name="productId"
            values={suppliedProducts.map((product: any) => ({
                label: product.name,
                value: product.id
            }))}
            selectedOption={
                selectedProduct ? {
                    label: selectedProduct.name,
                    value: selectedProduct.id
                } : null
            }
            setValue={(option) => formik.setFieldValue('productId', option?.value ?? null)}
            error={formik.touched.productId && Boolean(formik.errors.productId)}
            helperText={formik.touched.productId && formik.errors.productId}
        />
        <Collapse in={isIcecream}>
            <WeightInput
                name="kilos"
                fullWidth
                label="Kilos de helado devueltos"
                value={formik.values.kilos}
                error={formik.touched.kilos && Boolean(formik.errors.kilos)}
                helperText={formik.touched.kilos && formik.errors.kilos}
                onChange={event => formik.setFieldValue('kilos', event.target.value)} />
        </Collapse>
    </>)
}


interface ParseAndValidateFormValuesOptions {
    isIcecream: boolean
    productId: string | null;
    kilos: string;
}

function parseAndValidateFormValues({ isIcecream, productId, kilos }: ParseAndValidateFormValuesOptions) {

    if (!productId) {
        return
    }

    if (isIcecream && kilos != null) {
        // Await other PR is merged
        const grams = parseKiloString(kilos!)
        if (Number.isNaN(grams)) {
            return
        }
        return { grams, productId }
    }

    return { grams: null, productId }
}


export default function RefundDialog({
    open,
    onClose: _onClose,
}: RefundDialogProps) {
    const { locationId: forLocationId } = useParams()
    const formik = useFormik({
        initialValues: {
            productId: null,
            kilos: ''
        } as { productId: string | null, kilos: string },
        validationSchema,
        onSubmit: async (formValues, helpers) => {

            const { productId, kilos } = formValues
            const input = parseAndValidateFormValues({
                isIcecream,
                productId,
                kilos
            })

            if (!input || !forLocationId) {
                return
            }

            try {
                await mutate({
                    variables: {
                        input: { ...input, forLocationId: forLocationId! }
                    }
                })
                helpers.resetForm()
            } catch (error: any) {

                alert(error.message)
                console.error('Unable to return product', { error })
            }
        }
    })

    const { data, loading } = useSuppliedProducts()
    const selectedProduct = useMemo(
        () => data?.suppliedProducts?.find(it => {
            return it.id === formik.values.productId
        }) ?? null,
        [data, formik]
    )

    const onClose = useCallback(() => {
        formik.resetForm()
        _onClose()
    }, [formik, _onClose])

    const [mutate, { loading: isSubmitting }] = useMutation(RETURN_PRODUCT_MUTATION, {
        refetchQueries: [LIST_RETURNED_PRODUCTS_QUERY],
        onCompleted: () => {
            formik.resetForm()
            onClose()
        }
    })

    const isIcecream = selectedProduct?.productType === ProductType.Icecream

    return (
        <BottomSheetDialog
            open={open}
            onClose={onClose}>
            <form onSubmit={formik.handleSubmit}>

                <DialogTitle className="text-center">
                    ¿Qué producto ha sido devuelto?
                </DialogTitle>

                {loading && !data && <CircularProgress />}
                {!loading && data &&
                    <div className="flex flex-col w-full gap-4 px-6 pb-24">
                        <RefundProductForm
                            suppliedProducts={data.suppliedProducts}
                            formik={formik}
                            selectedProduct={selectedProduct}
                            isIcecream={isIcecream}
                        />
                    </div>
                }


                <div className="flex flex-row my-4 gap-2 px-6">

                    <Button fullWidth variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>
                    <LoadingButton fullWidth isLoading={isSubmitting} variant="contained" disabled={!formik.isValid} type="submit">
                        Confirmar
                    </LoadingButton>
                </div>
            </form>
        </BottomSheetDialog>
    )
}