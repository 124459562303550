import React, { memo } from 'react'


const spanishEur = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    //@ts-ignore
    trailingZeroDisplay: 'stripIfInteger'
});

export const formatEur = (num: number) => spanishEur.format(num)
export function formatStringEurToCents(priceInEuros: string): string {
    const splitKey = priceInEuros.includes('.') ? '.' : ','
    const [euros, cents] = priceInEuros.split(splitKey)
    const formattedCents = formatCents(cents)

    return `${euros}${formattedCents}`
}

/**
 * Parses a price in euros to cents.
 * 
 * For example "100.5" will be 10050 (integer)
 * For example "100,5" will be 10050 (integer)
 * @param priceInEuros the price in euros with a dot separator for cents
 * @returns the price in cents (integer)
 */
export function parsePriceToCents(priceInEuros: string): number {
    return parseInt(formatStringEurToCents(priceInEuros))
}


function formatCents(cents: string | undefined | null) {
    if (!cents || cents.length === 0) {
        return '00'
    }
    if (cents.length > 2) {
        throw new Error(`Invalid price format: ${cents}`)
    }

    if (cents.length === 1) {
        return `${cents}0`
    }
    return cents
}

/**
 * Transform a intenger in cents to a string in euros.
 * Used for values coming from the backend.
 * 
 * Example: 100 will be "1,00"
 * @param cents 
 * @returns string in euros
 */
export function centsToEurString(cents: number): string {
    if (cents < 0) {
        throw new Error(`Invalid price: ${cents}`)
    }
    const stringCents = cents.toString()
    if (stringCents.length === 1) {
        return `0,0${stringCents}`
    }
    if (stringCents.length === 2) {
        return `0,${stringCents}`
    }
    return `${stringCents.slice(0, -2)},${stringCents.slice(-2)}`
}


/**
 * Price in cents
 */
function Price({ price }: { price: number }) {
    return (<>{formatEur(price / 100)}</>)
}

export default memo(Price)