import { gql } from "../../__generated__/gql";



export const PRODUCTS_TO_BE_PRODUCED_QUERY = gql(/* GraphQL */ `
    query ProductsToBeProduced($filters: ProductToBeProducedFilters!) {
        productsToBeProduced(filters: $filters) {
            product {
                id
                name
                returnable
                deleted
                productType
                icecreamGroup
                createdAt
                updatedAt
            }
            stock
            requestedAmount
            producedAmount
            remainingAmount
        }
    }
`)