import { gql } from "../__generated__/gql";



export const GET_CURRENT_STOCKS_QUERY = gql(/* GraphQL */`
    query GetSuppliedProducts($productType: ProductType) {
        suppliedProducts(productType: $productType) {
            id
            name
            stock
            productType
            icecreamGroup
        }
    }
`)