import { gql } from "../../__generated__/gql";

export const CREATE_BILLING_INFO_MUTATION = gql(`
    mutation CreateBillingInfo($billingInfo: NewBillingInfo!) {
        createBillingInfo(billingInfo: $billingInfo) {
            CIF
            companyName
            registeredAddress
            phoneNumber
            contactEmail
        }
    }
`)