import { gql } from "../../__generated__/gql";

export const DELIVER_BATCH_MUTATION = gql(/* GraphQL */`

    mutation DeliverBatch($delivery: DeliveryBatchDelivery!) {
        deliverDeliveryBatch(args: $delivery) {
            id
        }
    }
    
`)