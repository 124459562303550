import { IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { IcecreamGroup } from '../../../__generated__/graphql';


export default function IcecreamGroupSelectionPage() {
    const navigate = useNavigate()
    return (
        <List>
            <ListItemButton className='select-none cursor-pointer' onClick={() => navigate(`./${IcecreamGroup.Creams}`)}>
                <ListItemIcon>
                    <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Natas"
                    secondary="Gestinar ordenación de Natas en preparación"
                />
                <IconButton edge="end" >
                    <ArrowForwardIcon />
                </IconButton>
            </ListItemButton>
            <ListItemButton className='select-none cursor-pointer' onClick={() => navigate(`./${IcecreamGroup.Fruits}`)}>
                <ListItemIcon>
                    <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Frutas"
                    secondary="Gestinar ordenación de Frutas en preparación"
                />
                <IconButton edge="end" >
                    <ArrowForwardIcon />
                </IconButton>
            </ListItemButton>
        </List>
    )
}