import { gql } from "../../__generated__/gql";

export const LIST_ADMIN_ORDERS_QUERY = gql(/* GraphQL */`
    query ListAdminOrders($filters: OrdersOverviewFilters!) {
        adminOrders(filters: $filters) {
            orderNumber
            observations
            createdAt
            toBeDeliveredOn
            updatedAt
            status
            deliverToLocation {
                name
                address
                suppliedByLocationId
            }
            products {
                product {
                    id
                    name
                }
            }
        }
    }
`)