import { StoreMallDirectory } from "@mui/icons-material";
import { Chip, Typography } from "@mui/material";
import React, { memo } from "react";



export default memo(function Address({ name, address }: { name: string; address: string }) {
    return <Chip
        size="small"
        color="secondary"
        label={
            <Typography color="info" variant="caption">
                {[name, address].join(' - ')}
            </Typography>
        }
        icon={<StoreMallDirectory />} />


})