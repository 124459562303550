import { LocalShipping } from "@mui/icons-material";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import React, { memo } from "react";



export default memo(function DeliveryDate({date}: {date: Date}) {
    return <Chip
    size="small"
    color="info"
    icon={<LocalShipping />}
    label={dayjs(date).format('DD/MM/YYYY')} />
})