import React, { ReactNode } from "react";
import AppToolbar from "../components/app-toolbar";
import { useSession } from "../context/current-session";

type Session = ReturnType<typeof useSession>

export type ToolbarLayoutProps = 
    Required<
        Pick<Session, 'session' | 'clearSession'>
    >
    & {children?: ReactNode}

/**
 * Note: Use directly under <BaseLayout/>
 */
export default function ToolbarLayout({session, clearSession, children}: ToolbarLayoutProps)  {
    return (
        <>
            <AppToolbar session={session} clearSession={clearSession}/>
            <main className="flex flex-col w-full h-full overflow-y-auto">
                {children}
            </main>
        </>
    )
}