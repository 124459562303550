import { gql } from "../__generated__/gql"

export const LOGIN_MUTATION = gql(/* GraphQL */`
    mutation Login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            jwt
            user {
                id
                displayName
                worksAt {
                    location {
                        id
                        name
                    }
                    departments
                }
            }
        }
    }
`)