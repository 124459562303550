import { gql } from "../__generated__/gql";


export const GET_ORDER_BY_ORDER_NUMBER = gql(/* GraphQL */`
    query GetOrderByOrderNumber($orderNumber: Int!) {
        order(orderNumber: $orderNumber) {
            orderNumber
            status
            observations
            toBeDeliveredOn
            deliverToLocation {
                name
                address
            }
            products {
                product {
                    id
                    productType
                    name
                }
                amount
            }
            
            verifiedProducts {
                product {
                    id
                    productType
                    name
                }
                amount
            }
            deliveredProducts {
                id
                productType
                name
                amount
                grams
            }
        }
    }

`)