import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'usehooks-ts'

/**
 * The purpose of this hook is to avoid pushing entries on the naviation
 * stack as we update the query params. Users expect to go to the previous
 * page when clicking the "back" button on the toolbar.
 * 
 * If we don't "replace" the last navigation event, the user will sit through his 
 * entire history of filters he's been viewing
 */
export const useSearchFilters: typeof useSearchParams = (initialState) => {
    const [params, _setParams] = useSearchParams(initialState)
    useEffectOnce(() => {
        // In case we had some initial values, it would be great feedback 
        // to show it in the URL, therefore we force this navigation to happen
        // only if initial state was passed in and it wasn't empty
        if(initialState && Object.keys(initialState).length > 0) {
            _setParams(initialState, {replace: true})
        }
    })
    const setParams: typeof _setParams = React.useCallback(
        (params, options) => _setParams(params, {...options, replace: true}),
        [_setParams]
    )
    return [params, setParams]
}