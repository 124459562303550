import { RetryLink } from "@apollo/client/link/retry";

export const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
    },

    attempts: {
        max: 3,
        retryIf: (error, operation) => {
            console.log('retryIf', { error, operation })
            
            if (
                // error?.response?.status === 500 ||
                error?.response?.status >= 400 ||
                operation.operationName.toLowerCase() === 'login') {
                return false
            }

            // TODO: Check "unauthorized"
            return !!error
        }
    }

});
