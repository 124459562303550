import { gql } from "../__generated__/gql";



export const TOGGLE_DELETE_PRODUCT_MUTATION = gql(`
    mutation ToggleDeleteProduct($id: ID!, $deleted: Boolean!) {
        toggleDeleteProduct(id: $id, deleted: $deleted) {
            name
        }
    }
`)