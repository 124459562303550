import { z } from "zod"
import { validationFrom } from "../../../../forms/validation"
import { zodPriceSchema } from "../../../PriceInput"


export const zodSchema = z.object({
    productId: z.string({ required_error: 'Éste campo es obligatorio' }),
    productionCost: zodPriceSchema(true),
    defaultPrice: zodPriceSchema(true)
})

export const validationSchema = validationFrom(zodSchema)
