import { z } from "zod"
import { validationFrom } from "../../../forms/validation"

export function parsePrice(value: string): number {
    return parseFloat(value.replace(',', '.'))
}

export const zodSchema = z.object({
    displayName: z.string({ required_error: 'Éste campo es obligatorio' }),
    username: z.string({ required_error: 'Éste campo es obligatorio' }),
    password: z.string({ required_error: 'Éste campo es obligatorio' }),
    confirmPassword: z.string({ required_error: 'Éste campo es obligatorio'}),
    elevated: z.boolean()
}).refine(data => data.password === data.confirmPassword, {
    message: "Las contraseñas no coinciden",
    path: ['confirmPassword']
});

export const validationSchema = validationFrom(zodSchema)
