import { gql } from "../../__generated__/gql";


export const UPDATE_ISSUE_STATUS_MUTATION = gql(/* GraphQL */`

    mutation UpdateIssueStatus($id: ID!, $resolved: Boolean!) {

        updateIssueStatus(id: $id, resolved: $resolved) {
            id
        }

    }
`)