import { gql } from "../../__generated__/gql";

export const RETURN_PRODUCT_MUTATION = gql(/* GraphQL */ `
  mutation ReturnProduct($input: ReturnProduct!) {
    returnProduct(input: $input) {
      id
      product {
        id
        name
        productType
      }
      grams
      createdAt
    }
  }
`)