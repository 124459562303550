import { createTheme } from '@mui/material/styles';
import { esES as dataGridES } from '@mui/x-data-grid/locales';
import { esES as coreES } from '@mui/material/locale';
import { esES as datePickersES } from '@mui/x-date-pickers/locales';

const theme = createTheme(
    {
        palette: {
            mode: 'light',
            primary: {
                main: '#7aa228',
                contrastText: '#f5f6f4'
            },
            secondary: {
                main: '#28a28d',
                contrastText: '#fff'
            },
            background: {
                default: '#e3eec5',
                paper: '#f4f8e8',
            }
        },
        typography: {
            fontFamily: 'Quicksand',
        },
        components: {
            MuiAlert: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.severity === 'info' && {
                            backgroundColor: '#60a5fa',
                        }),
                    }),
                },
            }
        },
    },
    dataGridES,
    coreES,
    datePickersES,
);

export default theme;