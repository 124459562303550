import AddIcon from '@mui/icons-material/Add';
import { Fab, LinearProgress } from '@mui/material';
import { GridActionsCellItem, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { formatGrams } from '../../components/WeightComponent';
import WeightInput, { parseKiloString } from '../../components/WeightInput';
import NewTrayFormDialog from '../../components/admin/NewTrayFormDialog';
import BDGDataGrid from '../../components/data-grid/BDGDataGrid';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_TRAYS_QUERY } from '../../../network/trays/list-trays-query';
import { UPDATE_TRAY_MUTATION } from '../../../network/trays/update-tray-mutation';
import { dateCell } from '../../components/data-grid/shared-cells';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListTraysQuery } from '../../../__generated__/graphql';
import { DELETE_TRAY_MUTATION } from '../../../network/trays/delete-tray-mutation';


const staticColumns = [
    {
        field: 'name',
        minWidth: 250,
        editable: true,
        headerName: 'Nombre descriptivo de la cubeta',
    },
    {
        editable: true,
        minWidth: 200,
        field: 'grams',
        headerName: 'Peso de la cubeta',
        type: 'string',
        renderEditCell(params) {
            return (
                <div className="flex justify-center items-center px-2 py-1">
                    <WeightInput
                        label={null}
                        variant='standard'
                        value={params.value}
                        onChange={(event) => params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: event.target.value
                        })}
                    />
                </div>
            )
        },
        valueSetter: (value: string, row: any) => {
            if (!value) {
                return row
            }

            const grams = parseKiloString(value)
            if (value && !Number.isNaN(grams) && grams > 0) {
                return { ...row, grams }
            }

            return row
        },
        // Transform to string so we can apply the weight manipulation
        valueGetter: (value: number) => formatGrams(value),
        valueFormatter: (value: string) => (
            value != null
                ? `${value} kg`
                : 'Sin peso'
        ),
    },
    dateCell('createdAt', 'Fecha de creación'),
    dateCell('updatedAt', 'Fecha de actualización'),

] satisfies Array<GridColDef>


type Tray = ListTraysQuery['trays'][number]


export default function TrayManagementPage() {
    const apiRef = useGridApiRef()
    const [showDialog, setShowDialog] = useState(false)
    const { loading: fetching, data } = useQuery(LIST_TRAYS_QUERY)
    const [update, { loading: updating }] = useMutation(UPDATE_TRAY_MUTATION, {
        refetchQueries: [LIST_TRAYS_QUERY]
    })
    
    const [deleteTray, { loading: deleting }] = useMutation(DELETE_TRAY_MUTATION, {
        refetchQueries: [LIST_TRAYS_QUERY],
        onError: (error) => {
            alert(error.message)
        }
    })

    const onDelete = useCallback((row: Tray) => {
        deleteTray({
            variables: {
                id: row.id
            }
        })
    }, [deleteTray])

    const columns = useMemo(() => [...staticColumns, {
        field: 'actions',
        type: 'actions',
        getActions: (params) => ([
            <GridActionsCellItem
                icon={<DeleteIcon />}
                onClick={() => onDelete(params.row)}
                label="Eliminar" />
        ])
    }] satisfies Array<GridColDef>, [onDelete])

    const loading = fetching || updating || deleting

    useLayoutEffect(() => {
        apiRef.current?.autosizeColumns({
            includeHeaders: true,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiRef?.current, data?.trays])

    return (
        <>
            {loading && <LinearProgress />}
            <BDGDataGrid
                loading={loading}
                apiRef={apiRef}
                columns={columns}
                rows={data?.trays ?? []}
                getRowId={row => row.id}
                processRowUpdate={async (newRow, oldRow) => {
                    if (!newRow?.grams) {
                        return oldRow
                    }
                    if (!newRow?.name) {
                        return oldRow
                    }

                    try {

                        await update({
                            variables: {
                                input: {
                                    id: newRow.id,
                                    name: newRow.name,
                                    grams: newRow.grams
                                }
                            }
                        })

                        return newRow
                    } catch (error: any) {
                        alert(error.message)
                        return oldRow
                    }
                }}
            />
            <NewTrayFormDialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
            />
            <Fab
                size='large'
                color='primary'
                style={{ position: 'fixed', bottom: 56, right: 24 }}
                onClick={() => setShowDialog(true)}>
                <AddIcon />
            </Fab>
        </>
    )
}