import { gql } from "../../__generated__/gql";



export const GET_PENDING_DELIVERY_BATCHES = gql(/* GraphQL */`
    query GetPendingDeliveryBatches($filters: StrictDateFilters!) {

        pendingDeliveryBatches(filters: $filters) {
            id
            orderNumber
            products {
                id
            }
            order {
                orderNumber
                createdAt
                observations
                toBeDeliveredOn
                updatedAt
                status
                deliverToLocation {
                    name
                    address
                }
            } 
        }
    }    
`)