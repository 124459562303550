import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import BottomSheetDialog from "../../bottom-sheet/BottomSheetDialog";
import LoadingButton from "../../loading-button";
import { validationSchema, zodSchema } from "./user-form-helpers";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const defaultInitialValues = {
    displayName: "",
    username: "",
    password: "",
    confirmPassword: "",
    elevated: false
}


export interface UserFormProps {
    open?: boolean
    create?: boolean
    loading?: boolean
    initialValues?: {
        displayName: string
        username: string
        password: string
        confirmPassword: string
        elevated: boolean
    },
    onSubmit?: (value: ReturnType<typeof zodSchema.parse>, resetForm: () => void) => void
    onClose: () => void
}

type FormWithDataProps = Pick<UserFormProps, 'onSubmit' | 'initialValues' | 'loading' | 'onClose' | 'create'>

function FormWithData({
    create,
    loading,
    initialValues = defaultInitialValues,
    onSubmit,
    onClose
}: FormWithDataProps) {

    const [showPassword, setShowPassword] = useState<Boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<Boolean>(false)

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form, helpers) => {
            return onSubmit?.(zodSchema.parse(form), helpers.resetForm)
        }
    })


    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">
                <TextField
                    type="text"
                    name="displayName"
                    label="Nombre"
                    placeholder="Alias del usuario"
                    className="grow"
                    error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                    helperText={formik.touched.displayName && formik.errors.displayName}
                    onBlur={formik.handleBlur}
                    value={formik.values.displayName ?? ""}
                    onChange={formik.handleChange}
                />
                <TextField
                    type="text"
                    name="username"
                    label="Cuenta de usuario"
                    placeholder="Nombre de usuario"
                    className="grow"
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                    onBlur={formik.handleBlur}
                    value={formik.values.username ?? ""}
                    onChange={formik.handleChange}
                />
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="Contraseña"
                    placeholder="Contraseña"
                    className="grow"
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onBlur={formik.handleBlur}
                    value={formik.values.password ?? ""}
                    onChange={formik.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    onClick={() => setShowPassword(prev => !prev)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    label="Confirmar Contraseña"
                    placeholder="Confirmar Contraseña"
                    className="grow"
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword ?? ""}
                    onChange={formik.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    onClick={() => setShowConfirmPassword(prev => !prev)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormControlLabel
                    checked={formik.values.elevated}
                    control={<Checkbox
                        inputProps={{
                            'aria-label': 'controlled'
                        }} />}
                    name="elevated"
                    className="grow"
                    onBlur={formik.handleBlur}
                    value={formik.values.elevated}
                    onChange={formik.handleChange}
                    label="Administrador" />
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        isLoading={loading}
                        disabled={!formik.isValid}
                        type="submit"
                        variant="contained">
                        {create ? 'Dar de alta a un usuario' : 'Guardar cambios'}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </form>
    )
}

export default function UserForm({
    open = false,
    create = true,
    loading = false,
    initialValues,
    onSubmit,
    onClose
}: UserFormProps) {
    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {create ? 'Dar de alta a un usuario' : 'Modificar un usuario'}
            </DialogTitle>
            <FormWithData
                loading={loading}
                create={create}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onClose={onClose} />
        </BottomSheetDialog>
    );
}
