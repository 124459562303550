import { useQuery } from "@apollo/client";
import { LocalShipping } from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import { Badge, Button, Fab, IconButton } from '@mui/material';
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "../../../__generated__/graphql";
import { GET_PENDING_DELIVERY_BATCHES } from "../../../network/delivery-batches/get-pending-delivery-batches-query";
import { parseDate } from "../../../network/gql-links/scalars";
import FilterSheet from "../../components/FilterSheet";
import { OrderMeta, OrderObservations } from "../../components/OrderDetails";
import EmptyRows from "../../components/data-grid/empty-rows";
import { useSearchFilters } from "../../hooks/use-search-filters";
import { ProductListPadding } from "../ProductListPadding";


function paramsToFilters(params: URLSearchParams) {
    const [from, to] = [params.get('from'), params.get('to')].map(it => parseDate(it, undefined))
    if (from && to) return { from, to }
    const today = dayjs().startOf('day')
    const endOfDay = today.endOf('day')
    return {
        from: today.toDate(),
        to: endOfDay.toDate()
    }
}



function OrderItem({ order, products, onDeliver,}: any) {
    return (
        <div
            className="flex flex-row p-6 border border-gray-200 bg-surface rounded-lg">
            <div className="flex flex-col gap-2 w-full">
                <Badge 
                    showZero
                    color="info"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    badgeContent={products.length}>
                    <OrderMeta
                        prefix="Lote para pedido"
                        order={order} />
                </Badge>
                <OrderObservations observations={order.observations} />
            </div>
            <div className="flex flex-col items-center justify-center">
                <IconButton
                    // TODO: Think about when to disable this button
                    disabled={order.status === OrderStatus.Delivered}
                    color="success"
                    onClick={evt => {
                        evt.stopPropagation()
                        evt.preventDefault()
                        onDeliver()
                    }}>
                    <LocalShipping />
                </IconButton>
            </div>
        </div>
    )
}

function DeliveryBatches({ batches, className = "", onResetFilters, onDeliverBatch }: any) {
    if (batches.length === 0) {
        return <EmptyRows labelText="No hay pedidos.">
            <Button variant="outlined" onClick={onResetFilters}>
                Reiniciar filtros
            </Button>
        </EmptyRows>
    }
    return <div className={"flex flex-col w-full h-full gap-2 p-4 max-w-4xl self-center " + className}>
        {batches.map(({id, order, products}: any) => (
            <OrderItem
                key={id}
                order={order}
                products={products}
                onDeliver={() => onDeliverBatch(id)} />
        ))}
        <ProductListPadding />
    </div>
}

export default function DeliveryBatchesPage() {
    const navigate = useNavigate()
    const onDeliverBatch = (batchId: string) => {
        navigate(`/delivery-batches/${batchId}`)
    }

    const [dialogOpen, setDialogOpen] = useState(false)
    const [params, setParams] = useSearchFilters({})

    const filters = useMemo(() => paramsToFilters(params), [params])

    const { loading, data } = useQuery(GET_PENDING_DELIVERY_BATCHES, {
        variables: {
            filters: {
                from: filters.from!,
                to: filters.to!
            }
        }
    })


    return (
        <>
            <DeliveryBatches
                className="mt-1"
                batches={data?.pendingDeliveryBatches ?? []}
                onResetFilters={() => setParams({})}
                onDeliverBatch={onDeliverBatch}
            />
            <Fab
                color="success"
                size='large'
                disabled={loading}
                style={{ position: 'fixed', bottom: 64, right: 32 }}
                onClick={() => setDialogOpen(true)}>
                <TuneIcon />
            </Fab>

            <FilterSheet
                open={dialogOpen}
                dateRange
                dateQuickActions
                onClose={() => setDialogOpen(false)} />

        </>
    );
}