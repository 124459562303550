import { useMutation, useQuery } from '@apollo/client';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Button, Fab, LinearProgress, List, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMap } from 'usehooks-ts';
import { AvailableProductsQuery } from '../../../__generated__/graphql';
import { AVAILABLE_PRODUCTS } from '../../../network/available-products-query';
import { TOGGLE_FAVORITE_MUTATION } from '../../../network/toggle-favorite-mutation';
import AppBarSearch from '../../components/AppBarSearch';
import ProductListSkeleton from '../../components/ProductListSkeleton';
import ProductSelector from '../../components/ProductSelector';
import EmptyRows from '../../components/data-grid/empty-rows';
import ErrorMessage from '../../components/error-message';
import { useCurrentOrder } from '../../context/current-order';
import { filterByLevenshtein } from '../../hooks/use-levenshtein';
import { useProductTypeFromSearchParams } from '../../hooks/use-producttype-from-params';
import { ProductListPadding } from '../ProductListPadding';

interface ProductListProps {
    className?: string
    products: AvailableProductsQuery['availableProducts']
    onFavoriteClick: (productId: string, isFavorite: boolean) => void
}
type Product = AvailableProductsQuery['availableProducts'][number]

function ProductList({ className='', products, onFavoriteClick }: ProductListProps) {
    const navigate = useNavigate()
    const { addToOrder } = useCurrentOrder()
    const [productsAndAmountsByIds, { set, setAll, remove }] = useMap(products.map(it => (
        [it.id, { ...it, amount: 0 }])
    ))

    // This is done whenever "products" changes since 
    // the user may toggle the "isFavorite" flag of a product
    useEffect(() => {
        const entries = products.map(it => {
            const product = productsAndAmountsByIds.get(it.id)
            return [it.id, { ...it, amount: product?.amount ?? 0 }] satisfies [string, NonNullable<typeof product>]
        })
        setAll(entries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, setAll])

    const handleSetQuantity = (product: Product, quantity: number | undefined) => {
        if (quantity != null) {
            set(product.id, { ...product, amount: quantity })
        } else {
            remove(product.id)
        }
    }

    const handleAddToOrder = () => {
        const productsWithAmounts = (
            Array
                .from(productsAndAmountsByIds.values())
                .filter(it => it.amount > 0)
        )
        addToOrder(
            productsWithAmounts.map(({ id, name, productType, amount }) => {
                return {
                    product: { id, name, productType },
                    amount
                }
            })
        )
        alert('¡Productos añadidos al pedido!')
        navigate(-1)
    }

    return (
        <>
            <List className={className}>
                {Array.from(productsAndAmountsByIds.values()).map((product) => (
                    <ProductSelector
                        key={product.id}
                        isFavorite={product.isFavorite}
                        onFavoriteClick={onFavoriteClick}
                        product={product}
                        quantity={product.amount}
                        setQuantity={qtt => handleSetQuantity(product, qtt)} />
                ))}
                {/* so that Fab is always visible */}
                <ProductListPadding />
            </List>
            <Fab
                color="success"
                size='large'
                variant="extended"
                style={{ position: 'fixed', bottom: 24, right: 24 }}
                onClick={handleAddToOrder}
            >
                <AddShoppingCartIcon className="mr-2" />
                Añadir
            </Fab>
        </>
    )
}



export default function OrderProductsPage() {
    const navigate = useNavigate()
    const productType = useProductTypeFromSearchParams()
    const [search, setSearch] = useState('')
    const { loading, data, error: fetchingError } = useQuery(AVAILABLE_PRODUCTS, {
        variables: { productType }
    })

    const [mutate, { loading: mutating, error: mutationError }] = useMutation(TOGGLE_FAVORITE_MUTATION, {
        refetchQueries: [AVAILABLE_PRODUCTS]
    })

    const error = fetchingError || mutationError

    const filteredProducts = useMemo(() => {
        const dataset = data?.availableProducts ?? []
        if(!search) {
            return dataset
        }
        return filterByLevenshtein({
            term: search,
            acceptableDistance: 12,
            items: dataset,
            getComparable: it => it.name
        })
    }, [ data?.availableProducts, search])

    if (!loading && data) {
        if (data.availableProducts.length === 0) {
            return (
                <EmptyRows labelText='No hay productos disponibles'>
                    <Typography className="pb-4">Prueba a seleccionar otra categoría</Typography>
                    <Button variant='contained' onClick={() => navigate(-1)}>Volver atrás</Button>
                </EmptyRows>
            )
        }
        return (
            <>
                <AppBarSearch onSearchChange={setSearch}/>
                <LinearProgress sx={{ opacity: mutating ? 1 : 0 }} />
                <ProductList
                    className="w-full h-full overflow-y-auto"
                    products={filteredProducts}
                    onFavoriteClick={(productId, isFavorite) => mutate({ variables: { productId, isFavorite } })}
                />
            </>
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}