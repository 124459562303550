import { gql } from "../__generated__/gql";


export const TOGGLE_FAVORITE_MUTATION = gql(/* GraphQL */`

    mutation ToggleFavorite($productId: ID!, $isFavorite: Boolean!) {

        toggleFavoriteProduct(productId: $productId, isFavorite: $isFavorite)

    }
    
`)