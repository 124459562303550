import { useQuery } from '@apollo/client'
import { Button, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

import React, { memo } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import StoreIcon from '@mui/icons-material/Store'
import { ListSuppliedLocationsQuery } from '../../__generated__/graphql'
import { MY_SUPPLIED_LOCATIONS_QUERY } from '../../network/locations/my-supplied-locations-query'
import ProductListSkeleton from './ProductListSkeleton'
import EmptyRows from './data-grid/empty-rows'
import ErrorMessage from './error-message'

type Location = ListSuppliedLocationsQuery['mySuppliedLocations'][number]



interface LocationListProps {
    items: ListSuppliedLocationsQuery['mySuppliedLocations']
    onItemClick: (item: Location) => void 
}


function LocationList({ items, onItemClick }: LocationListProps) {
    return (
        <List >
            {items.map(item => (
                <ListItemButton key={item.id} className='select-none cursor-pointer' onClick={() => onItemClick(item)}>
                    <ListItemIcon>
                        <StoreIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={item.name}
                        secondary={item.address}
                    />
                    <IconButton edge="end" aria-label="delete">
                      <ArrowForwardIcon />
                    </IconButton>
                </ListItemButton>
            ))}
        </List>
    )
}



interface SuppliedLocationListProps {
    onNoItemsClick: () => void
    onItemClick: (item: Location) => void
}

function SuppliedLocationList({
    onItemClick, 
    onNoItemsClick
}: SuppliedLocationListProps) {
    const { loading, data, error } = useQuery(MY_SUPPLIED_LOCATIONS_QUERY)
    if (!loading && data) {
        if (data.mySuppliedLocations.length === 0) {
            return (
                <EmptyRows labelText='No estás abasteciendo a ninguna tienda'>
                    <Typography className="pb-4">Solicita a un administrador que añada tiendas a tu fábrica</Typography>
                    <Button variant='contained' onClick={onNoItemsClick}>Volver atrás</Button>
                </EmptyRows>
            )
        }
        return (
            <LocationList items={data.mySuppliedLocations} onItemClick={onItemClick} />
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}

export default memo(SuppliedLocationList)


