import React, { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { LoginMutation } from '../../__generated__/graphql';
import { useLocalStorage } from 'usehooks-ts';

type CurrentSession = LoginMutation
type SessionUser = CurrentSession['login']['user']

export interface AuthenticationState {
    session?: CurrentSession['login']
    isAdmin: boolean
    setSession: (session: CurrentSession['login']) => void
    clearSession: () => void
}

const SessionContext = createContext<AuthenticationState>({
    isAdmin: false,
    session: undefined,
    setSession: () => { },
    clearSession: () => { }
});

export const useSession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};


export const USER_KEY = 'user'
export const JWT_KEY = 'jwt'

export const clearSession = () => {
    // The library has a bug where it 
    // stringifies "undefined" as the value
    // setUser(undefined)
    // setJWT(undefined)
    localStorage.removeItem(USER_KEY)
    localStorage.removeItem(JWT_KEY)
    window.dispatchEvent(new StorageEvent('local-storage'))
}

export const SessionProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useLocalStorage<SessionUser | undefined>(USER_KEY, undefined)
    const [jwt, setJWT] = useLocalStorage<string | undefined>(JWT_KEY, undefined)

    useEffect(() => {
        if (!jwt) return
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [payload] = (jwt).split('.')
        const decodedPayload = JSON.parse(atob(payload))
        const getUnixTimeNow = () => Math.trunc(Date.now() / 1000)
        if (getUnixTimeNow() >= decodedPayload.exp) {
            console.log('session-timed-out, clearingSession')
            clearSession()
        }
    }, [jwt, setUser, setJWT])

    const session = useMemo<CurrentSession['login'] | undefined>(
        () => (user && jwt ? { user, jwt } : undefined),
        [user, jwt]
    )


    useEffect(() => {
        if (!session?.user) return
        console.log('current-session.tsx', [
         `"${session.user.displayName}" works at [${session.user.worksAt?.departments?.join(', ')}]`,
         `for location named "${session.user.worksAt?.location?.name}"` ,
        ].join(' '))
    }, [session?.user])

    const isAdmin = useMemo(() => {
        if (!jwt) return false
        const [payload] = jwt.split('.')
        const jsonString = atob(payload)
        const { elevated } = JSON.parse(jsonString)
        return !!elevated
    }, [jwt])

    const setSession = ({ user, jwt }: CurrentSession['login']) => {
        setUser(user)
        setJWT(jwt)
    }

    return (
        <SessionContext.Provider value={{ session, isAdmin, setSession, clearSession }}>
            {children}
        </SessionContext.Provider>
    );
};