import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthenticationState } from "../ui/context/current-session";
import NotFoundPage from '../ui/pages/NotFoundPage';
import AdminHomePage from '../ui/pages/AdminHomePage'
import LoginPage from "../ui/pages/LoginPage";
import OrderingPage from "../ui/pages/shop/OrderingPage";
import OrderingRecordPage from "../ui/pages/shop/OrderingRecordPage";
import CheckoutPage from "../ui/pages/shop/CheckoutPage";
import OrderProductsPage from "../ui/pages/shop/OrderProductsPage";
import PlacedOrdersOverviewPage from "../ui/pages/factory/PlacedOrdersOverviewPage";
import PrepareProductsPage from "../ui/pages/factory/PrepareProductsPage";
import ProductsPage from '../ui/pages/admin/ProductsPage';
import FactoriesPage from '../ui/pages/admin/LocationsPage';
import UsersPage from "../ui/pages/admin/UsersPage";
import ShopHomePage from "../ui/pages/ShopHomePage";
import BillingInfoPage from "../ui/pages/admin/BillingInfosPage";
import { Department, OrderStatus } from "../__generated__/graphql";
import SupplierHomePage from "../ui/pages/SupplierHomePage";
import PriceManagementPage from "../ui/pages/factory/PriceManagementPage";
import ChooseLocationForPriceManagementPage from "../ui/pages/factory/ChooseLocationForPriceManagementPage";
import ProductsOfSupplierPage from "../ui/pages/factory/ProductsOfSupplierPage";
import OrdersToPreparePage from "../ui/pages/factory/OrdersToPreparePage";
import MyIssuesPage from "../ui/pages/shop/MyIssuesPage";
import IssuesPage from "../ui/pages/delivery/IssuesPage";
import ProduceProductFactoryPage from "../ui/pages/factory/ProduceProductFactoryPage";
import DeliveryBatchesPage from "../ui/pages/delivery/DeliveryBatchesPage";
import AdminOrdersPage from "../ui/pages/admin/AdminOrdersPage";
import SysConfigPage from "../ui/pages/admin/SysConfigPage";
import DeliverBatchPage from "../ui/pages/delivery/DeliverOrderPage";
import OrderDetails from "../ui/components/OrderDetails";
import ChamberStatusPage from "../ui/pages/factory/ChamberStatusPage";
import PreparationTablePage from "../ui/pages/factory/PreparationTablePage";
import TrayManagementPage from "../ui/pages/admin/TrayManagementPage";
import ChooseLocationForRefunds from "../ui/pages/delivery/ChooseLocationForRefunds";
import RefundsPage from "../ui/pages/delivery/RefundsPage";
import IcecreamGroupSelectionPage from "../ui/pages/admin/IcecreamGroupSelectionPage";
import IcecreamGroupSortingPage from "../ui/pages/admin/IcecreamGroupSortingPage";
import CheckOrderPage from "../ui/pages/factory/CheckOrderPage";
import { CheckProductPageStateProvider } from "../ui/hooks/factory/use-check-order-page-state";



export type AuthenticatedPageProps = Pick<AuthenticationState, 'session' | 'isAdmin'>  

function HomePageBasedOnRole({ session, isAdmin }: AuthenticatedPageProps) {
    if (!session) {
        console.warn('Attempting to load HomePage but no session is active')
        return null
    }
    if (isAdmin) {
        return <AdminHomePage />
    }
    const { worksAt } = session.user
    if (!worksAt) {
        console.warn("This user is not assigned to any location", { session })
        return null
    }

    const hasShopKeepDepartment = worksAt.departments.includes(Department.Shopkeep)

    if (hasShopKeepDepartment) {

        return <ShopHomePage />
    } else {

        return <SupplierHomePage session={session} isAdmin={isAdmin} />
    }

}


export default function AuthenticatedRoutes({ session, isAdmin }: AuthenticatedPageProps) {
    return (
        <Routes>
            <Route index element={<HomePageBasedOnRole session={session} isAdmin={isAdmin} />} />
            {/* Factory routes  */}
        
            {/* Factory management */}
            <Route path="/price-management" element={<ChooseLocationForPriceManagementPage/>}/>
            <Route path="/price-management/:locationId" element={<PriceManagementPage />} />
            <Route path="/product-management" element={<ProductsOfSupplierPage session={session} isAdmin={isAdmin} />} />
            <Route path="/see-chamber" element={<ChamberStatusPage />}  />

            {/* Order checking */}
            <Route 
                path="/check-orders" 
                element={
                    <OrdersToPreparePage 
                        session={session} 
                        isAdmin={isAdmin}
                        defaultStatus={OrderStatus.Received}
                    />
                } 
            />
            <Route 
                path="/check-orders/:orderNumber" 
                element={
                    <CheckProductPageStateProvider>
                        <CheckOrderPage />
                    </CheckProductPageStateProvider>
                } 
            />

            {/* Preparator routes */}
            <Route 
                path="/prepare-orders"
                element={
                    <OrdersToPreparePage 
                        session={session} 
                        isAdmin={isAdmin} 
                        defaultStatus={OrderStatus.Corrected}
                    />
                } 
            />

            <Route path="/prepare-orders/:orderNumber" element={<PrepareProductsPage />} />
            <Route path="/preparation-table" element={<PreparationTablePage />} /> 

            {/* Produce product factory route */}
            <Route path="/factory/:department" element={<ProduceProductFactoryPage session={session} isAdmin={isAdmin} />} />
            {/* Bakery route */}
            {/* Pastry route */}
            {/* Shopkeeper routes */}
            <Route path="/see-placed-orders" element={<PlacedOrdersOverviewPage />} />

            {/* Admin  routes  */}
            <Route path="/factories" element={<FactoriesPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/users" element={<UsersPage session={session} isAdmin={isAdmin} />} />
            <Route path="/billing-infos" element={<BillingInfoPage />} />
            <Route path="/product-management" element={<ProductsOfSupplierPage session={session} isAdmin={isAdmin} />}/>
            <Route path="/see-placed-orders" element={<PlacedOrdersOverviewPage/>} />
            
            <Route path="/admin-orders" element={<AdminOrdersPage />} />
            <Route path="/admin-orders/:orderNumber" element={<OrderDetails />} />
            <Route path="/tray-management" element={<TrayManagementPage />} />
            <Route path="/icecream-groups" element={<IcecreamGroupSelectionPage />} />
            <Route path="/icecream-groups/:group" element={<IcecreamGroupSortingPage />} />

            {/* Delivery routes  */}
            <Route path="/issues" element={<IssuesPage />} />

            <Route path="/delivery-batches" element={<DeliveryBatchesPage />} />
            <Route path="/delivery-batches/:batchId" element={<DeliverBatchPage />} />

            <Route path="/prepare-products/:orderId" element={<PrepareProductsPage/>} />

            <Route path="/refunds" element={<ChooseLocationForRefunds/>} />
            <Route path="/refunds/:locationId" element={<RefundsPage/>} />

            {/* Admin  routes  */}
            <Route path="/factories" element={<FactoriesPage />}/>
            <Route path="/products" element={<ProductsPage />}/>
            <Route path="/users" element={<UsersPage session={session} isAdmin={isAdmin}/>}/>
            <Route path="/billing-infos" element={<BillingInfoPage/>}/>
       
            {/* Shop routes  */}
            <Route path="/my-orders" element={<OrderingRecordPage />} />
            <Route path="/my-orders/:orderNumber" element={<OrderDetails />} />
            <Route path="/ordering-from-category" element={<OrderingPage />} />
            <Route path="/order-products" element={<OrderProductsPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/my-issues" element={<MyIssuesPage />} />
            <Route path="/sysconfig" element={<SysConfigPage/>} />

            {/* Shared routes  */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}