import { Button, DialogTitle, Typography } from "@mui/material"
import React from "react"
import BottomSheetDialog from "../../components/bottom-sheet/BottomSheetDialog"


interface CompleteOrderDialogProps {
    open?: boolean
    onComplete: (confirm: boolean) => void
    onClose: () => void
}

export default function CompleteOrderDialog({
    open,
    onComplete,
    onClose,
 }: CompleteOrderDialogProps) {

    return (
        <BottomSheetDialog
            open={open}
            keepMounted={false}
            onClose={onClose}>
            <DialogTitle className="text-center">
                ¿Quieres completar el pedido?
            </DialogTitle>

            <Typography 
                variant="body1" 
                className="px-8 text-center">
                Al completar el pedido cambiará de estado y ya no podrás seguir creando lotes de entrega para el pedido.
            </Typography>

            <div className="h-40"></div>

            <div className="flex flex-col gap-4 p-5 w-full">
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => onComplete(false)}>
                    Necesito seguir creando lotes de entrega
                </Button>

                <Button
                    fullWidth
                    variant='contained'
                    onClick={() => onComplete(true)}>
                    Completar pedido
                </Button>

                <Button
                    fullWidth
                    color="warning"
                    variant='outlined'
                    onClick={onClose}>
                    Cancelar
                </Button>
            </div>

        </BottomSheetDialog>
    )
}