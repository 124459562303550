import { BakeryDining, Cake, Icecream, Verified } from '@mui/icons-material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EuroIcon from '@mui/icons-material/Euro';
import InventoryIcon from '@mui/icons-material/Inventory';
import KitchenIcon from '@mui/icons-material/Kitchen';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Fab } from '@mui/material';
import React, { useState } from 'react';
import { Department } from '../../__generated__/graphql';
import { AuthenticatedPageProps } from '../../routes/AuthenticatedRoutes';
import MonthlyReceiptForm from '../components/accounting/MonthlyReceiptForm';
import NavButton from '../components/nav-button';

type SupplierHomePageProps = Required<AuthenticatedPageProps>

type Session = SupplierHomePageProps['session']


function worksAt({ user }: Session, ...departments: Array<Department>) {
    const result = departments.some(department => (
        user.worksAt?.departments?.includes(department) ?? false
    ))

    return result
}

function IceCreamProducerButtons() {
    return <>
        <NavButton className="w-1/2 h-40 px-1" to="/factory/icecream">
            <Icecream fontSize='large' />
            Fabricación heladería
        </NavButton>
    </>
}

function PastryProducerButtons() {
    return <>
        <NavButton className="w-1/2 h-40 px-1" to="/factory/pastry">
            <Cake fontSize='large' />
            Fabricación pastelería
        </NavButton>
    </>
}

function BakeryProducerButtons() {
    return <>
        <NavButton className="w-1/2 h-40 px-1" to="/factory/bakery">
            <BakeryDining fontSize='large' />
            Fabricación panadería
        </NavButton>
    </>
}

function PreparatorButtons() {
    return <>
        <NavButton className="w-1/2 h-40 px-1" to="/check-orders">
            <Verified fontSize='large' />
            Verificar pedidos
        </NavButton>
        <NavButton className="w-1/2 h-40 px-1" to="/prepare-orders">
            <WidgetsIcon fontSize='large' />
            Preparar lotes de entrega
        </NavButton>
        <NavButton className="w-1/2 h-40 px-1" to="/preparation-table">
            <TableChartOutlinedIcon fontSize='large' />
            Tabla de preparación
        </NavButton>
    </>
}

function ManagementButtons() {
    return <>

        <NavButton className="w-1/2 h-40 px-1" to="/product-management">
            <InventoryIcon fontSize='large' />
            Gestión de productos
        </NavButton>

        <NavButton className="w-1/2 h-40 px-1" to="/price-management">
            <EuroIcon fontSize='large' />
            Gestión de precios
        </NavButton>
    </>
}

function DeliveryButtons() {
    return <>

        <NavButton className="w-1/2 h-40 px-1" to='/delivery-batches'  >
            <LocalShippingIcon fontSize='large' />
            Entrega de lotes
        </NavButton>

        <NavButton className="w-1/2 h-40 px-1" to="/issues">
            <ReportProblemIcon fontSize='large' />
            Ver incidencias
        </NavButton>
        <NavButton className="w-1/2 h-40 px-1" to="/refunds">
            <CurrencyExchangeIcon fontSize='large' />
            Abonos y devoluciones
        </NavButton>
    </>
}

/* Ticket https://github.com/NanoSpicer/BDG-order-management/issues/239 */
/* Only show the chamber status if it's not Preparator or Delivery */
function canShowChamberStatus(session: Session) {
    // So it works at ANY other of the supplier departments
    const validDepts = [
        Department.Bakery,
        Department.Icecream,
        Department.Management,
        Department.Pastry,
        // Department.Delivery // Left out on purpose
        // Department.Preparator, // Left out on purpose
        // Department.Shopkeep // Left out on purpose
    ]
    return worksAt(session, ...validDepts)
}

export default function SupplierHomePage({ session }: SupplierHomePageProps) {
    const worksAtManagement = worksAt(session, Department.Management)
    const [showMonthlyReceiptForm, setShowMonthlyReceiptForm] = useState(false)
    return (
        <section className="flex flex-wrap justify-between w-full p-4 gap-y-4">
            {canShowChamberStatus(session) &&
                <NavButton className="w-1/2 h-40 px-1" to="/see-chamber">
                    <KitchenIcon fontSize='large' />
                    Ver estado de la cámara
                </NavButton>
            }
            {worksAtManagement && <ManagementButtons />}

            {worksAt(session, Department.Icecream) && <IceCreamProducerButtons />}
            {worksAt(session, Department.Pastry) && <PastryProducerButtons />}
            {worksAt(session, Department.Bakery) && <BakeryProducerButtons />}
            {worksAt(session, Department.Preparator) && <PreparatorButtons />}

            {worksAt(session, Department.Delivery) && <DeliveryButtons />}
            {
                worksAtManagement && (<>
                    <MonthlyReceiptForm
                        open={showMonthlyReceiptForm}
                        onClose={() => setShowMonthlyReceiptForm(false)}
                    />
                    <Fab
                        color="success"
                        size='large'
                        style={{ position: 'fixed', bottom: 32, right: 32 }}
                        onClick={() => setShowMonthlyReceiptForm(true)}
                        variant="extended">
                        <ReceiptIcon />
                        <span className="px-1"></span>
                        Generar factura del mes
                    </Fab>
                </>)
            }
        </section>
    )
}