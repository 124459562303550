import { gql } from "../../__generated__/gql";


export const PRODUCE_PRODUCT_MUTATION = gql(/* GraphQL */`
    mutation ProduceProduct($produceProduct: ProduceProduct!) {
        produceProduct(produceProduct: $produceProduct) {
            ...CommonProducedProduct
            product {
                id
                name
            }
        }
    }
`)