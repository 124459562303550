import { z } from "zod"
import { validateCIF, validateDNI, validationFrom } from "../../../forms/validation"

export function parsePrice(value: string): number {
    return parseFloat(value.replace(',', '.'))
}

export const zodSchema = z.object({
    CIF: z.string({ required_error: 'Éste campo es obligatorio' }),
    companyName: z.string({ required_error: 'Éste campo es obligatorio' }),
    registeredAddress: z.string({ required_error: 'Éste campo es obligatorio' }),
    phoneNumber: z.string({ required_error: 'Éste campo es obligatorio' }),
    contactEmail: z.string()
}).refine(data => validateCIF(data.CIF) || validateDNI(data.CIF), {
    message: "El formato del CIF o DNI, no es válido",
    path: ['CIF']
});

export const validationSchema = validationFrom(zodSchema)

