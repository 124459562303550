import { Button, CircularProgress } from "@mui/material"
import React from "react"

export type LoadingButtonProps = Parameters<typeof Button>[0] & {
    isLoading?: boolean
}

export default function LoadingButton({isLoading = false, children, ...props}: LoadingButtonProps) {
    return (
        <Button {...props} disabled={isLoading || (props.disabled ?? false)} >
            {
                isLoading
                    ? <CircularProgress className="mr-2" size={15} color="inherit"/>
                    : null
            }
            {children}
        </Button>
    )
}
