import { useQuery } from '@apollo/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Fab, IconButton, LinearProgress } from '@mui/material';
import { GridFilterListIcon } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { OrderStatus, OrdersForDeliveryOfSupplierQuery } from '../../../__generated__/graphql';
import { parseDate } from '../../../network/gql-links/scalars';
import { ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY } from '../../../network/location-orders/orders-for-delivery-of-supplier-query';
import { AuthenticatedPageProps } from '../../../routes/AuthenticatedRoutes';
import FilterSheet from '../../components/FilterSheet';
import { OrderMeta, OrderObservations } from '../../components/OrderDetails';
import EmptyRows from '../../components/data-grid/empty-rows';
import ErrorMessage from '../../components/error-message';
import { useSearchFilters } from '../../hooks/use-search-filters';
import { ProductListPadding } from '../ProductListPadding';

interface OrderToPrepareItemProps {
    order: OrdersForDeliveryOfSupplierQuery['locationById']['ordersForDelivery'][number]
    expectedOrderStatus: OrderStatus
}
function OrderToPrepareItem({ 
    order,
    expectedOrderStatus
}: OrderToPrepareItemProps) {
    return (
        <div className="flex flex-row p-6 border border-gray-200 bg-surface rounded-lg">
            <div className="flex flex-col gap-2 w-full">
                <OrderMeta order={order} />
                <OrderObservations observations={order.observations} />
            </div>
            <div className="flex flex-col justify-center items-center">
                <IconButton 
                    disabled={order.status !== expectedOrderStatus}
                    component={Link} to={`./${order.orderNumber}`}>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        </div>
    )
}

export type ExtenderOrderStatusFilter = OrderStatus | "ALL"
function useTypedParams(params: URLSearchParams) {
    return useMemo(() => {
        const [from, to] = [params.get('from'), params.get('to')].map(it => parseDate(it, undefined))

        const startDate = from ? dayjs(from) : null
        const endDate = to ? dayjs(to) : null
        const status = ((): ExtenderOrderStatusFilter | null => {
            const status = params.get('status')
            if (status === null) return null
            if (status === "ALL") return 'ALL'
            const allOrderStatuses = [
                OrderStatus.Delivered,
                OrderStatus.Delivering,
                OrderStatus.FullyDispatched,
                OrderStatus.ReadyForPickup,
                OrderStatus.Corrected,
                OrderStatus.Received,
            ]
            return allOrderStatuses.find(it => it === status) ?? null
        })()
        return { tobeDeliveredAfter: startDate, tobeDeliveredBefore: endDate, status: status }
    }, [params])
}

// TODO: Rename this page to "List orders with default status"
export default function OrdersToPreparePage({ session, defaultStatus = OrderStatus.Received }: AuthenticatedPageProps & {
    defaultStatus?: OrderStatus 
}) {
    const [filterDialogOpen, setFilterDialogOpen] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ params ] = useSearchFilters({
        // Issue #192: After a conversation with Damià had on 2024-05-02, 
        // we decided to change the default value for the "from" and "to" parameters to today
        from: dayjs().startOf('day').toISOString(),
        to: dayjs().endOf('day').toISOString(),
        status: defaultStatus.toString()
    })

    const { 
        tobeDeliveredAfter: selectedToBeDeliveredAfter, 
        tobeDeliveredBefore: selectedToBeDeliveredBefore, 
        status: selectedOrderStatus 
    } = useTypedParams(params)

    // Data fetching and mutation
    const locationId = useMemo(() => session?.user?.worksAt?.location?.id, [session?.user?.worksAt?.location?.id])
    const { loading: loadingData, error: errorInData, data } = useQuery(ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY, {
        variables: {
            locationId: locationId!!, orderFilters: {
                toBeDeliveredAfter: selectedToBeDeliveredAfter?.toDate(),
                toBeDeliveredBefore: selectedToBeDeliveredBefore?.toDate(),
                status: selectedOrderStatus === "ALL" || selectedOrderStatus === null ? null : selectedOrderStatus
            }
        },
        skip: !locationId,
    })

    const ordersForDelivery = data?.locationById?.ordersForDelivery ?? []

    return (
        <>
            <LinearProgress sx={{ opacity: loadingData ? 1 : 0 }} />
            {!loadingData && errorInData && <ErrorMessage description={errorInData.message} />}
            {!loadingData && ordersForDelivery.length === 0 && (
                <EmptyRows labelText='Parece ser que no hay pedidos dados tus filtros. Revisa que los pedidos hayan sido verificados'>
                    <Button variant='contained' onClick={() => setFilterDialogOpen(true)}>
                        Probar con otros
                    </Button>
                </EmptyRows>
            )}
            <div className="flex flex-col w-full h-full gap-2 p-4 max-w-4xl self-center">
                {ordersForDelivery.map((order) => (
                    <OrderToPrepareItem
                        order={order}
                        key={order.orderNumber}
                        expectedOrderStatus={defaultStatus}
                    />
                ))}
                <ProductListPadding />
            </div>

            {/* Filters for the orders */}
            <Fab
                size='large'
                color='primary'
                style={{ position: 'fixed', bottom: 24, right: 24 }}
                onClick={() => setFilterDialogOpen(true)}>
                <GridFilterListIcon />
            </Fab>
            <FilterSheet
                open={filterDialogOpen}
                dateQuickActions
                dateRange
                orderStatus
                onClose={() => setFilterDialogOpen(false)} />

        </>
    );
}