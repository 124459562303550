import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import LoadingButton from "../loading-button";
import BottomSheetDialog from "./BottomSheetDialog";

export interface LocationDeleteDialogProps {
    name: string,
    open?: boolean
    loading?: boolean
    onDelete?: () => void
    onClose: () => void
}

export default function LocationDeleteDialog({
    open = false,
    name,
    loading = false,
    onDelete,
    onClose
}: LocationDeleteDialogProps) {
    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {"Eliminar " + name}
            </DialogTitle>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">
                {"Seguro que quieres eliminar " + name + "?"}
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        onClick={onDelete}
                        isLoading={loading}
                        type="submit"
                        variant="contained">
                        {"Borrar"}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </BottomSheetDialog>
    );
}
