import { useMutation, useQuery } from "@apollo/client"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import { Button, Fab } from "@mui/material"
import { GridActionsCellItem, GridColDef, GridDeleteIcon } from "@mui/x-data-grid"
import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LocationByIdQuery, SupplierProduct } from "../../../__generated__/graphql"
import { LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY } from "../../../network/locations/suppliers/list-products-of-supplier-query"
import { ASSIGN_PRODUCT_TO_SUPPLIER_MUTATION, DELETE_SUPPLIED_PRODUCT_MUTATION, UPDATE_PRICES_OF_SUPPLIED_PRODUCT_MUTATION } from "../../../network/locations/suppliers/products-of-supplier-mutations"
import { AuthenticatedPageProps } from "../../../routes/AuthenticatedRoutes"
import ProductListSkeleton from "../../components/ProductListSkeleton"
import AssignProductToSupplierForm from "../../components/admin/location/supplied-product/AssignProductToSupplierForm"
import BottomSheetDeleteDialog from "../../components/bottom-sheet/BottomSheetDeleteDialog"
import BDGDataGrid from "../../components/data-grid/BDGDataGrid"
import EmptyRows from "../../components/data-grid/empty-rows"
import { idCell, productTypeCell } from "../../components/data-grid/shared-cells"
import ErrorMessage from "../../components/error-message"
import Price, { centsToEurString, parsePriceToCents } from "../../components/price"

function calculateInitialValues(selectedItem: SupplierProduct, supplierId: string) {
    return {
        supplierId: supplierId,
        productId: selectedItem.id,
        defaultPrice: centsToEurString(selectedItem.price),
        stock: selectedItem.stock,
        productionCost: centsToEurString(selectedItem.productionCost)
    };
}

type SuppliedProduct = LocationByIdQuery['locationById']['suppliedProducts'][number]
interface SuppliedProductListProps {
    suppliedProducts: Array<SuppliedProduct>;
    onDeleteItemClick: (selectedItem: SuppliedProduct) => void;
    onEditItemClick: (selectedItem: SuppliedProduct) => void;
    slots?: {
        noRowsOverlay?: () => React.ReactNode;
    };
}

enum BottomSheetAction {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    NONE = 'none'
}

function SuppliedProductsList({ suppliedProducts, onDeleteItemClick, onEditItemClick, slots }: SuppliedProductListProps) {
    const columns = [
        idCell,
        { field: 'name', headerName: 'Nombre', flex: 2 },
        { ...productTypeCell, flex: 2 },
        {
            field: 'price', headerName: 'Precio de venta', flex: 2,
            renderCell: (params: { row: SuppliedProduct }) =>
                params
                    ? (<div className="flex justify-start">
                        <Price price={params.row.price} />
                    </div>)
                    : null
        },
        {
            field: 'productionCost',
            headerName: 'Coste de producción', flex: 2,
            renderCell: (params: { row: SuppliedProduct }) =>
                params
                    ? (<div className="flex justify-start">
                        <Price price={params.row.productionCost} />
                    </div>)
                    : null
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            headerName: 'Acciones',
            getActions: (params: { row: SuppliedProduct }) =>
                params
                    ? ([
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            onClick={() => onEditItemClick(params.row)}
                            label="Editar" />,
                        <GridActionsCellItem
                            icon={<GridDeleteIcon />}
                            onClick={() => onDeleteItemClick(params.row)}
                            label="Eliminar" />
                    ])
                    : []
        },
    ] satisfies Array<GridColDef>;

    return (
        <BDGDataGrid
            columns={columns}
            rows={suppliedProducts}
            slots={slots}
        />
    );
}

interface BottomSheetState {
    action: BottomSheetAction
    selectedItem?: SuppliedProduct
}

export default function ProductsOfSupplierPage({ session }: AuthenticatedPageProps) {
    const [bottomSheetState, setBottomSheetState] = useState<BottomSheetState>({ action: BottomSheetAction.NONE })
    const closeModal = () => setBottomSheetState({ action: BottomSheetAction.NONE })
    const createNew = bottomSheetState.action === BottomSheetAction.CREATE
    const navigate = useNavigate()
    const worksAtId = useMemo(() => session?.user.worksAt?.location.id, [session])
    const { loading, data: locationWithSuppliedProducts, error } = useQuery(LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY, {
        variables: { id: worksAtId!! },
        skip: worksAtId === undefined
    });

    const [createSuppliedProduct, { loading: creatingSuppliedProduct }] = useMutation(ASSIGN_PRODUCT_TO_SUPPLIER_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY]
    })

    const [editSuppliedProduct, { loading: editingSuppliedProduct }] = useMutation(UPDATE_PRICES_OF_SUPPLIED_PRODUCT_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY]
    })

    const [deleteSuppliedProduct, { loading: deletingSuppliedProduct }] = useMutation(DELETE_SUPPLIED_PRODUCT_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY]
    })

    if (!loading && locationWithSuppliedProducts) {
        return (
            <div className="flex flex-col w-full h-full">
                <SuppliedProductsList
                    suppliedProducts={locationWithSuppliedProducts.locationById.suppliedProducts}
                    onDeleteItemClick={(selectedItem) => setBottomSheetState({ action: BottomSheetAction.DELETE, selectedItem: selectedItem })}
                    onEditItemClick={(selectedItem) => setBottomSheetState({ action: BottomSheetAction.EDIT, selectedItem: selectedItem })}
                    slots={{
                        noRowsOverlay: () => (
                            <EmptyRows labelText='No hay productos asignados'>
                                <div className="flex flex-col gap-4">
                                    <Button color="primary" variant='contained' onClick={() => setBottomSheetState({ action: BottomSheetAction.CREATE })}>Crear asignación de producto</Button>
                                    <Button color="secondary" variant='outlined' onClick={() => navigate(-1)}>Volver atrás</Button>
                                </div>
                            </EmptyRows>
                        )
                    }}
                />
                <AssignProductToSupplierForm
                    open={bottomSheetState.action === BottomSheetAction.CREATE || bottomSheetState.action === BottomSheetAction.EDIT}
                    create={createNew}
                    supplierId={worksAtId}
                    loading={creatingSuppliedProduct || editingSuppliedProduct}
                    initialValues={bottomSheetState.selectedItem ? calculateInitialValues(bottomSheetState.selectedItem, locationWithSuppliedProducts.locationById.id) : undefined}
                    onSubmit={values => {
                        const suppliedProduct = {
                            defaultPrice: parsePriceToCents(values.defaultPrice),
                            productionCost: parsePriceToCents(values.productionCost),
                            stock: 0,
                            supplierId: locationWithSuppliedProducts.locationById.id,
                            productId: values.productId
                        }

                        if (createNew) {
                            createSuppliedProduct({
                                variables: { input: { ...suppliedProduct } }
                            })
                            return
                        }

                        if (!bottomSheetState.selectedItem) {
                            throw new Error('No supplied product selected')
                        }


                        const selectedItem = bottomSheetState.selectedItem
                        const updatedSuppliedProductParams = {
                            defaultPrice: parsePriceToCents(values.defaultPrice),
                            productionCost: parsePriceToCents(values.productionCost),
                            stock: selectedItem.stock,
                            supplierId: locationWithSuppliedProducts.locationById.id,
                            productId: selectedItem.id
                        }

                        editSuppliedProduct({
                            variables: {
                                input: {
                                    ...updatedSuppliedProductParams
                                }
                            }
                        })

                    }}
                    onClose={closeModal} />
                <BottomSheetDeleteDialog
                    open={bottomSheetState.action === BottomSheetAction.DELETE}
                    name="asignación de producto"
                    loading={deletingSuppliedProduct}
                    onClose={closeModal}
                    onDelete={
                        () => {
                            deleteSuppliedProduct(
                                {
                                    variables: {
                                        input: {
                                            supplierId: locationWithSuppliedProducts.locationById.id,
                                            productId: bottomSheetState.selectedItem!!.id
                                        }
                                    }
                                }
                            )
                        }
                    }
                />

                <Fab
                    size='large'
                    color='primary'
                    style={{ position: 'fixed', bottom: 64, right: 32 }}
                    onClick={() => setBottomSheetState({ action: BottomSheetAction.CREATE })}>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}