import { AppBar, Typography } from "@mui/material"
import dayjs from "dayjs"
import React from "react"

function formatAppBarText(baseText: string | null | undefined, from: dayjs.Dayjs | null, to: dayjs.Dayjs | null) {
    const startingText = baseText ? `${baseText} ` : "Lista de elementos"
    if (!from) return startingText
    const today = dayjs().startOf('day')
    const tomorrow = today.add(1, 'day').endOf('day')
    const formattedFromText = from.isSame(today, 'day') ? "hoy" : `${from.format("DD/MM/YYYY")}`

    if (!to) return `${startingText} a partir de: ${formattedFromText}`

    if (from.isSame(to, 'day')) return `${startingText} para ${formattedFromText}`

    const formattedToText = to.isSame(tomorrow, 'day') ? "mañana" : `${to.format("DD/MM/YYYY")}`
    return `${startingText} entre ${formattedFromText} y ${formattedToText}`
}

type DateFilterAppBarProps = {
    /**
     * The text to display before the date range 
     */
    startingText: string | null | undefined
    from: dayjs.Dayjs | null
    to: dayjs.Dayjs | null
    children?: React.ReactNode
}
export function DateFilterAppBar({ 
    startingText, 
    children,
    from, 
    to 
}: DateFilterAppBarProps) {
    return (<AppBar position="static">
        <div className="py-2 px-4 flex flex-row justify-between">
            <Typography className='py-2 px-4'>
                {formatAppBarText(startingText, from, to)}
            </Typography>
            {children}
        </div>
    </AppBar>)
}