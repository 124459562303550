import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { memo } from 'react';

const BottomSheetPaperProps = {
    sx: {
        m: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        mx: 'auto',
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0
    }
}

const SlideUpTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface BottomSheetDialogProps {
    open?: boolean
    keepMounted?: boolean
    children?: React.ReactNode
    onClose: () => void
}

function BottomSheetDialog({ open = false, onClose, children, keepMounted}: BottomSheetDialogProps) {
    return (
        <Dialog
            open={open}
            TransitionComponent={SlideUpTransition}
            PaperProps={BottomSheetPaperProps}
            keepMounted={keepMounted}
            fullWidth
            onClose={onClose}
        >
            {children}
        </Dialog>
    )
}

export default memo(BottomSheetDialog)