import { ApolloError, MutationFunctionOptions, useMutation, useQuery } from "@apollo/client";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Exact, GetProductsFromOrderQuery, OrderCorrection, ProductType, SetOrderCorrectionsMutation } from "../../../__generated__/graphql";
import { SET_ORDER_CORRECTIONS_MUTATION } from "../../../network/check-order/set-corrected-order-products-mutation";
import { GET_CURRENT_STOCKS_QUERY } from "../../../network/get-supplied-products-query";
import { ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY } from "../../../network/location-orders/orders-for-delivery-of-supplier-query";
import { GET_PRODUCTS_FROM_ORDER_QUERY } from "../../../network/products-from-order-query";
import { IcecreamGroup } from "../../components/IcecreamGroup";

type ProductAmount = {
    amount: number;
    product: {
        id: string;
        productType: ProductType;
        icecreamGroup?: IcecreamGroup | null;
        name: string;
    };
}

interface SwapProductOptions {
    swappedProductId: string
    product: {
        id: string;
        productType: ProductType;
        icecreamGroup?: IcecreamGroup | null;
        name: string;
    }
}

type AddProductOptions = {
    amount: number
    product: {
        id: string;
        productType: ProductType;
        icecreamGroup?: IcecreamGroup | null;
        name: string;
    }
}

interface CheckOrderPageState {
    orderNumber: number
    showProductPicker: boolean,
    setShowProductPicker: (show: boolean) => void,
    remoteData: {
        loading: boolean,
        data?: {
            order: GetProductsFromOrderQuery['order'],
            products: Array<ProductAmount>
            suppliedProducts: Array<{
                id: string;
                name: string;
                stock: number;
                productType: ProductType;
                icecreamGroup?: IcecreamGroup | null;
            }>
        },
        error?: ApolloError
    },

    swappingState: { productId: string, amount: number } | null,
    setSwappingState: React.Dispatch<React.SetStateAction<{
        productId: string;
        amount: number;
    } | null>>

    formState: {
        pendingProducts: Array<ProductAmount>
        checkedProducts: Array<ProductAmount>
    },
    checkProduct: (productId: string) => void
    removeProduct: (productId: string) => void
    swapProduct: (options: SwapProductOptions) => void
    addProduct: (options: AddProductOptions) => void

    submitting: boolean
    submitCorrections: (params: MutationFunctionOptions<SetOrderCorrectionsMutation, Exact<{ corrections: OrderCorrection; }>>) => any
}

const CheckProductsContext = createContext<CheckOrderPageState>({
    orderNumber: Number.NaN,
    showProductPicker: false,
    setShowProductPicker: () => {},
    remoteData: {
        loading: true,
    },

    swappingState: null,
    setSwappingState: () => {},

    formState: {
        pendingProducts: [],
        checkedProducts: []
    },
    checkProduct: () => {},
    removeProduct: () => {},
    swapProduct: () => {},
    addProduct: () => {},

    submitting: false,
    submitCorrections: () => {}
});

type CheckProductsPageStateProps = {
    children: React.ReactNode
}


function useOrderNumber() {
    const { orderNumber } = useParams()
    return orderNumber ? parseInt(orderNumber) : Number.NaN
}

const initialFormState = (products: Array<ProductAmount> = []) => ({
    pendingProducts: products,
    checkedProducts: [] as Array<ProductAmount>
})


export function useCheckOrderPageState() {
    const context = React.useContext(CheckProductsContext)
    if (!context) {
        throw new Error('useCheckOrderPageState must be used within a CheckProductPageStateProvider')
    }
    return context
}

export function CheckProductPageStateProvider({ children }: CheckProductsPageStateProps) {
    const orderNumber = useOrderNumber()
    const navigate = useNavigate()
    const { loading: fetchingProducts, data: availableProducts, error: availableProductsError} = useQuery(GET_CURRENT_STOCKS_QUERY)
    const { loading: fetchingOrder, data: orderData, error: orderDataError} = useQuery(GET_PRODUCTS_FROM_ORDER_QUERY, {
        variables: { orderNumber },
        skip: !orderNumber
    })
    const [submitCorrections, { loading: submitting }] = useMutation(SET_ORDER_CORRECTIONS_MUTATION, {
        refetchQueries: [ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY],
        onCompleted: () => navigate(-1) 
    })
    const error = availableProductsError ?? orderDataError
    const loading = fetchingProducts || fetchingOrder

    const data = useMemo(() => {
        if (loading) return undefined
        if (!orderData?.order) return undefined
        if (!availableProducts?.suppliedProducts) return undefined
        return {
            order: orderData.order,
            products: orderData.order.products,
            suppliedProducts: availableProducts.suppliedProducts
        }
    }, [loading, orderData?.order, availableProducts?.suppliedProducts])

    const [showProductPicker, setShowProductPicker] = useState(false)
    const [swappingState, setSwappingState] = useState<{ productId: string, amount: number } | null>(null)
    const [formState, setFormState] = useState(
        initialFormState()
    )

    // Watch for changes in navigation
    useEffect(() => {
        setFormState(initialFormState())
    }, [orderNumber])

    // Watch for prop changes
    useEffect(() => {
        if(data?.products && data.products.length > 0) {
            setFormState({
                pendingProducts: data.products,
                checkedProducts: []
            })
        }
    }, [data?.products, setFormState])

    const checkProduct = useCallback((productId: string) => {
        setFormState(prev => {
            const productToBeChecked = prev.pendingProducts.find(p => p.product.id === productId)
            if(productToBeChecked!=null) {
                return {
                    pendingProducts: prev.pendingProducts.filter(p => p.product.id !== productId),
                    checkedProducts: [...prev.checkedProducts, productToBeChecked]
                }
            }
            return prev
        })

    }, [setFormState])

    const removeProduct = useCallback((productId: string) => {
        setFormState(prev => {
            return {
                ...prev,
                pendingProducts: prev.pendingProducts.filter(p => p.product.id !== productId),
            }
        })
    }, [setFormState])

    const swapProduct = useCallback(({ swappedProductId, product }: SwapProductOptions) => {
        setFormState(prev => {
            const productToBeSwapped = prev.pendingProducts.find(p => p.product.id === swappedProductId)

            if(!productToBeSwapped) { 
                return prev
            }
            // Remove the swapped product from the pending list
            const pendingProducts = prev.pendingProducts.filter(p => p.product.id !== swappedProductId)
            
            // In case the product already existed in the checked list add it up
            const previuslyCheckedProduct = prev.checkedProducts.find(p => p.product.id === product.id)
            if(previuslyCheckedProduct) {
                return {
                    pendingProducts,
                    checkedProducts: prev.checkedProducts.map(p => {
                        if(p.product.id === product.id) {
                            return {
                                amount: p.amount + productToBeSwapped.amount,
                                product: p.product
                            }
                        }
                        return p
                    })
                }
            }

            return {
                pendingProducts,
                checkedProducts: [...prev.checkedProducts, {
                    amount: productToBeSwapped.amount,
                    product
                }]
            }
        })
    }, [setFormState])
    
    const addProduct = useCallback(({ amount, product }: AddProductOptions) => {
        setFormState(prev => {
            const previuslyCheckedProduct = prev.checkedProducts.find(p => p.product.id === product.id)

            // Add it up
            if(previuslyCheckedProduct) {
                return {
                    ...prev,
                    checkedProducts: prev.checkedProducts.map(p => {
                        if(p.product.id === product.id) {
                            return {
                                amount: p.amount + amount,
                                product: p.product
                            }
                        }
                        return p
                    })
                }
            }

            return {
                ...prev,
                checkedProducts: [...prev.checkedProducts, {
                    amount,
                    product
                }]
            }
        })
    }, [setFormState])
    
    const value = { 
        orderNumber,

        showProductPicker,
        setShowProductPicker,

        remoteData: { loading, data, error },

        swappingState, setSwappingState,

        formState, checkProduct, removeProduct, swapProduct, addProduct ,

        submitting, submitCorrections
    }

    return (
        <CheckProductsContext.Provider value={value}>
            {children}
        </CheckProductsContext.Provider>
    )
}
