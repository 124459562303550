import { TextField, InputAdornment, TextFieldProps } from "@mui/material";
import React, { useCallback } from "react";
import { z } from "zod";

// parses the weight; in kilos (returns float)
export function parseWeight(value: string): number {
    if (value === '') return 0
    return parseFloat(value.replace(',', '.').replace(/\.$/, ''))
}

export type WeightInputProps = TextFieldProps & {
    value?: string
}

export const zodWeightSchema = () => (
    z.string({
        invalid_type_error: 'Éste campo es obligatorio',
        required_error: 'El peso es obligatorio'
    })
        .regex(/^[0-9]+([,.][0-9]{1,3})?$/, 'Formato de peso incorrecto')
        .transform(str => {
            return str.replace(',', '.')
        })
        .refine(
            (value) => {
                const number = parseWeight(value)
                return Number.isNaN(number) === false
            }, {
            message: `El peso tiene que ser mayor que 0`,
        }
        )
        .refine(
            (value) => {
                const number = parseWeight(value)
                return Number.isNaN(number) === false && number <= 20;
            },
            { message: `El peso no puede ser mayor a 20 kgs` }
        )
)

export const zodParseWeight = (value: string) => {
    return zodWeightSchema().safeParse(value)
}


/**
 * 
 * @param value you can pass a string with a comma or a dot
 * @returns 
 */
export function parseKiloString(value: string) {
    const kiloFloat = parseFloat(value.replace(',', '.'))
    if (Number.isNaN(kiloFloat)) {
        throw new Error('Invalid kilo string')
    }

    const grams = kiloFloat * 1000
    return Math.round(grams)
}


/**
 * Usage notes
 * value is a kilo string, e.g. "1.5". If a comma is used this component takes care of replacing with a dot
 * <WeightInput
 *   value={formik.values.weight}
 *   onBlur={formik.handleBlur}
 *   onChange={formik.handleChange}
 *   error={formik.touched.weight && Boolean(formik.errors.weight)}
 *   helperText={formik.touched.weight ? formik.errors.weight : ''} />
 */

export default function WeightInput({
    name = "weight",
    label = "Peso (kg)",
    value,
    onBlur, onChange,
    error, helperText,
    ...rest
}: WeightInputProps) {
    const getError = useCallback(() => (
        Boolean(value) && zodParseWeight(value ?? '').success === false
    ), [value])
    const getHelperText = useCallback((() => {
        const res = zodParseWeight(value ?? '')
        if (!res.success) {
            const [error] = res.error.issues
            return error?.message
        }
        return undefined
    }), [value])

    return (
        <TextField
            type="text"
            name={name}
            label={label}
            inputMode="numeric"
            placeholder="00.000"
            value={value?.replace(',', '.')}
            onBlur={onBlur}
            onChange={onChange}
            InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            error={error && getError()}
            helperText={helperText ?? getHelperText()}
            {...rest} />
    )
}