import { useMutation, useQuery } from "@apollo/client"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import { Button, Fab } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ListBillingInfosQuery } from "../../../__generated__/graphql"
import { CREATE_BILLING_INFO_MUTATION } from "../../../network/billing-info/create-billing-info-mutation"
import { LIST_BILLING_INFOS_QUERY } from "../../../network/billing-info/list-billing-infos-query"
import { UPDATE_BILLING_INFO_MUTATION } from "../../../network/billing-info/update-billing-info-mutation"
import ProductListSkeleton from "../../components/ProductListSkeleton"
import BillingInfoForm, { BillingInfoFormProps } from "../../components/admin/billing-info/BillingInfoForm"
import BDGDataGrid from "../../components/data-grid/BDGDataGrid"
import EmptyRows from "../../components/data-grid/empty-rows"
import ErrorMessage from "../../components/error-message"


type BillingInfo = ListBillingInfosQuery['billingInfos'][number]
interface BillingInfoListProps {
    billingInfos: Array<BillingInfo>;
    onEditItemClick: (selectedBillingInfo: BillingInfo) => void;
    slots?: {
        noRowsOverlay?: () => React.ReactNode;
    };
}

enum BottomSheetAction {
    CREATE = 'create',
    EDIT = 'edit',
    NONE = 'none'
}

function BillingInfoList({ billingInfos, onEditItemClick, slots }: BillingInfoListProps) {
    const columns = [
        { field: 'CIF', headerName: 'CIF', flex: 2 },
        { field: 'companyName', headerName: 'Nombre', flex: 2 },
        { field: 'registeredAddress', headerName: 'Dirección', flex: 1 },
        { field: 'phoneNumber', headerName: 'Teléfono', flex: 1 },
        { field: 'contactEmail', headerName: 'Correo Electrónico', flex: 2 },
        {
            field: 'actions',
            flex: 1,
            headerName: 'Actions',
            getActions: (params: { row: BillingInfo }) => ([
                <GridActionsCellItem
                    icon={<EditIcon />}
                    onClick={() => onEditItemClick(params.row)}
                    label="Editar" />
            ])
        },
    ];

    return (
        <BDGDataGrid
            getRowId={(row) => row.CIF}
            rows={billingInfos}
            columns={columns}
            slots={slots}
        />
    );
}

function calculateInitialValues(billingInfo: BillingInfo): BillingInfoFormProps['initialValues'] {
    return billingInfo
}

interface BottomSheetState {
    action: BottomSheetAction
    selectedItem?: BillingInfo
}

export default function BillingInfoPage() {
    const [bottomSheetState, setBottomSheetState] = useState<BottomSheetState>({ action: BottomSheetAction.NONE })
    const closeModal = () => setBottomSheetState({ action: BottomSheetAction.NONE })
    const createNew = bottomSheetState.action === BottomSheetAction.CREATE
    const navigate = useNavigate()
    const { loading, data, error } = useQuery(LIST_BILLING_INFOS_QUERY)
    const [create, { loading: creating }] = useMutation(CREATE_BILLING_INFO_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_BILLING_INFOS_QUERY]
    })
    const [update, { loading: updating }] = useMutation(UPDATE_BILLING_INFO_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_BILLING_INFOS_QUERY]
    })

    if (!loading && data) {
        return (
            <div className="flex flex-col w-full h-full">
                <BillingInfoList
                    billingInfos={data.billingInfos}
                    onEditItemClick={(selectedItem) => setBottomSheetState({ action: BottomSheetAction.EDIT, selectedItem: selectedItem })}
                    slots={{
                        noRowsOverlay: () => (
                            <EmptyRows labelText='No hay elementos disponibles'>
                                <div className="flex flex-col gap-4">
                                    <Button color="primary" variant='contained' onClick={() => setBottomSheetState({ action: BottomSheetAction.CREATE })}>Dar de alta información de facturación</Button>
                                    <Button color="secondary" variant='outlined' onClick={() => navigate(-1)}>Volver atrás</Button>
                                </div>
                            </EmptyRows>
                        )
                    }}
                />
                <BillingInfoForm
                    open={bottomSheetState.action === BottomSheetAction.CREATE || bottomSheetState.action === BottomSheetAction.EDIT}
                    create={createNew}
                    loading={creating || updating}
                    initialValues={bottomSheetState.selectedItem ? calculateInitialValues(bottomSheetState.selectedItem) : undefined}
                    onSubmit={values => {
                        const billingInfo = {
                            CIF: values.CIF,
                            companyName: values.companyName,
                            registeredAddress: values.registeredAddress,
                            phoneNumber: values.phoneNumber,
                            contactEmail: values.contactEmail,
                        }

                        if (createNew) {
                            create({
                                variables: { billingInfo: billingInfo }
                            })
                        } else {
                            const { CIF, ...updateParams } = billingInfo
                            update({
                                variables: { CIF: CIF, update: updateParams }
                            })
                        }
                    }}
                    onClose={closeModal} />
                <Fab
                    size='large'
                    color='primary'
                    style={{ position: 'fixed', bottom: 64, right: 32 }}
                    onClick={() => setBottomSheetState({ action: BottomSheetAction.CREATE })}>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}