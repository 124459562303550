import { useQuery } from "@apollo/client"
import { Button, CircularProgress, LinearProgress, List, Tab, Tabs, Typography } from "@mui/material"
import React, { HTMLProps, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { GetOrderByOrderNumberQuery, OrderStatus } from "../../__generated__/graphql"
import { GET_ORDER_BY_ORDER_NUMBER } from "../../network/get-order"
import Address from "./Address"
import DeliveryDate from "./DeliveryDate"
import { ProductAmount } from "./ProductListItem"
import ErrorMessage from "./error-message"
import { OrderStatusChip } from "./order-status"
import { ProductListPadding } from "../pages/ProductListPadding"

type Order = GetOrderByOrderNumberQuery['order']

interface OrderProps {
    isDeliveryGuy: boolean
    order: Order
}

interface ProductsToDeliverListProps {
    productsWithAmount: Order['deliveredProducts']
}

function EmptyList() {
    return (
        <div className="flex flex-row justify-center py-16">
            <Typography variant="h5">
                Esta lista está vacía
            </Typography>
        </div>
    )
}


function ProductsToDeliverList({ productsWithAmount }: ProductsToDeliverListProps) {
    const lastIndex = productsWithAmount.length - 1
    if (productsWithAmount.length === 0) {
        return <EmptyList />
    }
    return (
        <List component="div" role="list" sx={{ width: "100%" }} disablePadding>
            {productsWithAmount
                .map(({ id, name, productType, amount, grams }, index) => {
                    return (
                        <ProductAmount
                            key={id}
                            name={name}
                            isLast={index === lastIndex}
                            amount={amount}
                            grams={grams}
                            productType={productType} />
                    )
                })}
        </List>
    )
}

function OrderedProductsList({ products }: { products: Order['products'] }) {
    const lastIndex = products.length - 1
    if (products.length === 0) {
        return <EmptyList />
    }
    return (
        <List component="div" role="list" sx={{ width: "100%" }} disablePadding>
            {products.map(({ product, amount }, index) => {
                return (
                    <ProductAmount
                        key={product.id}
                        name={product.name}
                        isLast={index === lastIndex}
                        amount={amount}
                        productType={product.productType} />
                )
            })}
        </List>
    )
}


const canSeeDeliveredProducts = (status: OrderStatus) => {
    return [OrderStatus.Delivered, OrderStatus.Delivering, OrderStatus.FullyDispatched].includes(status)
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function AllOrderProducts({ order }: Pick<OrderProps, 'order'>) {
    const [tab, setTab] = useState(0)

    return (
        <>
            <Tabs
                variant="fullWidth"
                indicatorColor="secondary"
                value={tab}
                textColor="primary"
            >
                <Tab
                    {...a11yProps(0)}
                    label="Productos solicitados"
                    onClick={() => setTab(0)} />
                <Tab
                    {...a11yProps(1)}
                    label="Productos entregados"
                    onClick={() => setTab(1)} />
            </Tabs>
            {tab === 0 &&
                <div
                    id="tabpanel-ordered"
                    aria-labelledby="tab-ordered"
                    role="tabpanel" className="w-full h-full flex flex-col">
                    {/* // Don't show products whose amount is zero */}
                    <OrderedProductsList
                        products={order.products.filter(it => it.amount > 0)}
                    />
                </div>
            }
            {tab === 1 &&
                <div
                    id="tabpanel-delivered"
                    aria-labelledby="tab-delivered"
                    role="tabpanel" className="w-full h-full flex flex-col">
                    {/* // Don't show products whose amount is zero */}
                    <ProductsToDeliverList
                        productsWithAmount={order.deliveredProducts.filter(it => it.amount > 0)}
                    />
                </div>
            }
        </>
    )
}

function OrderProducts({ isDeliveryGuy, order }: OrderProps) {
    const show2Lists = canSeeDeliveredProducts(order.status)

    if (isDeliveryGuy) {
        return <ProductsToDeliverList
            productsWithAmount={order.deliveredProducts.filter(it => it.amount > 0)}
        />
    }

    if (show2Lists) {
        return <AllOrderProducts order={order}/>
    }

    // Don't show products whose amount is zero
    return <OrderedProductsList
        products={order.products.filter(it => it.amount > 0)} />
}

interface OrderMetaProps {
    order: {
        orderNumber: number
        status: OrderStatus
        toBeDeliveredOn: Date
        deliverToLocation: {
            name: string
            address: string
        }
    }
}

export function OrderMeta({ 
    order ,
    prefix="Pedido número",
}: OrderMetaProps & { prefix?: string, suffix?: string }) {
    return (
        <section className="flex flex-col gap-4 sm:gap-2">
            <Typography color="info" variant="h5">
                {prefix} <span className="text-blue-500 underline">
                    #{order.orderNumber}
                </span>
            </Typography>
            <div className="flex flex-wrap items-center gap-2">
                <OrderStatusChip status={order.status} />
                <DeliveryDate date={order.toBeDeliveredOn} />
                <Address name={order.deliverToLocation.name} address={order.deliverToLocation.address} />
            </div>
        </section>
    )
}

export function OrderObservations({ observations }: { observations?: string | null }) {
    if (!observations) return null

    return (
        <section className="flex flex-col gap-2">
            <Typography variant="h5">
                Observaciones
            </Typography>
            <Typography variant="body1">
                {observations ?? "Sin observaciones"}
            </Typography>
        </section>
    )
}


function OrderDetailsContent({ isDeliveryGuy, order }: OrderProps) {
    return (
        <div className="flex flex-col w-full gap-12 sm:gap-8 px-4 sm:px-2">
            <OrderMeta order={order} />
            <OrderObservations observations={order.observations} />
            <section className="flex flex-col gap-2">
                <Typography variant="h5">
                    Productos
                </Typography>
                <OrderProducts isDeliveryGuy={isDeliveryGuy} order={order} />
                {/* Quick workaround to get some padding  */}
                <ProductListPadding />
                <ProductListPadding />
            </section>
        </div>
    )

}


type OrderDetailsProps = HTMLProps<HTMLDivElement> & {
    isDeliveryGuy?: boolean
    orderNumber?: string
    topSlot?: React.ReactNode
    bottomSlot?: React.ReactNode
}


const parseOrderNumber = (orderNumber: string | null | undefined): number | undefined => {
    if (orderNumber == null) return undefined
    const num = parseInt(orderNumber)
    return !Number.isNaN(num) ? num : undefined
}

export default function OrderDetails({
    orderNumber: orderNumberProps = undefined,
    isDeliveryGuy = false,
    topSlot,
    children,
    bottomSlot = children,
    className = '',
    ...rest
}: OrderDetailsProps) {
    const params = useParams()
    const orderNumber = (
        parseOrderNumber(orderNumberProps)
        ?? parseOrderNumber(params.orderNumber)
        ?? null
    )

    const { loading, data, error } = useQuery(GET_ORDER_BY_ORDER_NUMBER, {
        variables: (
            orderNumber != null
                ? { orderNumber }
                : undefined
        )
    })


    return (
        <>
            <LinearProgress style={{ opacity: loading ? 1 : 0 }} />
            {loading && <div className="w-50 h-50 self-center">
                <CircularProgress className="self-center" />
            </div>}
            <section
                className={
                    "flex flex-col w-full items-center mt-8 "
                    + className
                }
                {...rest}>
                <div className="flex flex-col w-full max-w-4xl gap-4">
                    {!loading && error && <ErrorMessage description={error.message}>
                        <Button fullWidth component={Link} to="../" >
                            Volver atrás
                        </Button>
                    </ErrorMessage>}
                    {topSlot}
                    {!loading && data && <OrderDetailsContent isDeliveryGuy={isDeliveryGuy} order={data.order} />}
                    {bottomSlot}
                </div>
            </section>
        </>
    )
}