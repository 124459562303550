import { useMutation } from "@apollo/client";
import { SET_ICECREAM_GROUP_MUTATION } from "../../network/icecream-groups/set-icecream-group-mutation";
import { CLEAR_ICECREAM_GROUP_MUTATION } from "../../network/icecream-groups/remove-icecream-group-mutation";

type Options = Parameters<typeof useMutation>[1]

export function useIcecreamGroupOperations(options: Options) {

    const [setGroup, { loading: settingGroup, error: settingGroupError }] = useMutation(SET_ICECREAM_GROUP_MUTATION, {
        onCompleted: options?.onCompleted,
        refetchQueries: options?.refetchQueries,
    })

    const [removeFromGroup, { loading: removingFromGroup, error: removingFromGroupError }] = useMutation(CLEAR_ICECREAM_GROUP_MUTATION, {
        onCompleted: options?.onCompleted,
        refetchQueries: options?.refetchQueries,
    })

    return {
        setGroup,
        removeFromGroup,
        loading: settingGroup || removingFromGroup,
        error: settingGroupError ?? removingFromGroupError,
    }
}