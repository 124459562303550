import React, { memo } from "react";
import { IcecreamGroup as IcecreamGroupGQL } from "../../__generated__/graphql";


// Use graphql enums
export const IcecreamGroups = {
    Creams:  IcecreamGroupGQL.Creams,
    Fruits: IcecreamGroupGQL.Fruits,
    None: undefined
} as const

export const GroupsAsSelectOptions = [ 
    IcecreamGroups.Creams, 
    IcecreamGroups.Fruits, 
].map(it => ({ label: formatGroup(it), value: it }))


export type IcecreamGroup = typeof IcecreamGroups[keyof typeof IcecreamGroups]
type IcecreamGroupProps = {
    small?: boolean
    group: IcecreamGroupGQL
    onClick?: (group: IcecreamGroupGQL) => void
}


function formatGroup(group?: IcecreamGroupGQL) {

    switch(group) {
        case IcecreamGroups.Creams: return 'Nata'
        case IcecreamGroups.Fruits: return 'Frutas'
        case IcecreamGroups.None: return ''
        default: return `Grupo no reconocido ${group}`
    }
}


const map = new Map([
    [IcecreamGroups.Creams, 'bg-blue-600'],
    [IcecreamGroups.Fruits, 'bg-green-600'],
    [IcecreamGroups.None, ''],
])

function getClassName(group: IcecreamGroupGQL, small = false) {
    const sizeClasses = small ? 'px-1 py-[0.05rem] text-xs' : 'px-2 py-[0.1rem]';
    return `${sizeClasses} rounded-xl text-white ${map.get(group) ?? ''}`;
}


function _IcecreamGroup({ group, onClick, small }: IcecreamGroupProps) {
    if(!group) {
        return null
    }

    return (
        <span key={group} className={getClassName(group, small)} onClick={() => onClick?.(group)}>
            {formatGroup(group)}
        </span>
    )
}



export default memo(_IcecreamGroup)