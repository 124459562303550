import { gql } from "../../__generated__/gql";

export const MY_SUPPLIED_LOCATIONS_QUERY= gql(/* GraphQL */`
    query ListSuppliedLocations {
        mySuppliedLocations {
            id
            name
            address
        }
    }
`)