import React from "react";
import LoginForm from "../components/LoginForm";

export default function LoginPage() {
    return (
        <div className="flex flex-col justify-center items-center px-2 sm:px-5 w-full">
            <div className="h-40"/>
            <LoginForm />
        </div>
    )
}