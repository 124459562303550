import { CommonBillingInfoFragment } from "../__generated__/graphql"

export type BillingInfo = {
    CIF: string
    companyName: string
    registeredAddress: string
    phoneNumber: string
    contactEmail: string
}

export function billingInfoFragmentToBillingInfo(billingInfo: CommonBillingInfoFragment): BillingInfo {
    return { ...billingInfo }

}