import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Toolbar, alpha, styled } from "@mui/material";
import InputBase from '@mui/material/InputBase';
import React, { memo, useCallback, useEffect, useState } from "react";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

type AppBarSearchProps = {
    // Debounced callback that will get called after 400 ms
    onSearchChange: (search: string) => void
}

function AppBarSearch({ onSearchChange: onSearchChanage }: AppBarSearchProps) {
    const [search, setSearch] = useState('')

    // Debounce searches
    useEffect(() => {
        const handle = setTimeout(() => onSearchChanage(search), 400)
        return () => clearTimeout(handle)
    }, [search, onSearchChanage])

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(ev.target.value)
        },
        [setSearch]
    )

    return (
        <AppBar position="sticky">
            <Toolbar>
                <Search >
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        autoFocus
                        fullWidth
                        placeholder="Buscar..."
                        value={search}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Toolbar>
        </AppBar>
    )
}

export default memo(AppBarSearch)