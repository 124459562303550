import { gql } from "../../__generated__/gql";


export const ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY = gql(/* GraphQL */`
    query OrdersForDeliveryOfSupplier($locationId: ID!, $orderFilters: OrderFilters) {
        locationById(id: $locationId) {
            id
            ordersForDelivery(orderFilters: $orderFilters) {
                orderNumber
                status
                toBeDeliveredOn
                observations
                deliverToLocation {
                    id
                    name
                    address
                }
                products{
                    amount
                    product {
                        id
                        name
                        productType
                        icecreamGroup
                    }
                }
            }
        }
    }
`)