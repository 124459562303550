import { ListItem, ListItemText } from '@mui/material'
import React from 'react'
import ProductType from './ProductType'
import { IcecreamGroup as IcecreamGroupEnum, ProductType as ProductTypeEnum } from '../../__generated__/graphql'
import { PropsOf } from '@emotion/react'
import IcecreamGroup from './IcecreamGroup'

type ProductProps = PropsOf<typeof ListItem> & {
    primaryText?: string
    product: {
        name: string,
        productType: ProductTypeEnum
        group?: IcecreamGroupEnum|null
    }
}

export default function Product({ product, primaryText = product.name, children, ...rest}: ProductProps) {
    return (
        <ListItem {...rest}>
            <ListItemText
                primary={primaryText}
                secondary={<>
                    { product.group != null && <IcecreamGroup group={product.group} />}
                    <ProductType productType={product.productType} />
                </>} 
            />
            <span className='px-2'></span>
            {children}
        </ListItem>
    )
}