import { withScalars, type ParsingFunctionsObject } from 'apollo-link-scalars'
import introspectionQueryResult from '../../__generated__/schema.json'
import { IntrospectionQuery, buildClientSchema } from 'graphql'
import dayjs, { Dayjs } from 'dayjs'

export function parseDate<TDefault>(
    dateLike: string | null | undefined,
    defaultValue: TDefault,
): Date | TDefault {
    if (!dateLike) return defaultValue

    const parsedDate = dayjs(dateLike).toDate()
    if(!isValidDate(parsedDate))  {
        return defaultValue
    }
    return parsedDate
}

export const isValidDate = (dateLike: Dayjs | Date | null | undefined): dateLike is Dayjs|Date => {
    if (!dateLike) return false
    if (dateLike instanceof Date) {
        return Number.isNaN(dateLike.getTime()) === false
    }
    return isValidDate(dateLike.toDate())
}

export function formatDate(dateLike: Dayjs | Date | null | undefined) {
    if (!isValidDate(dateLike)) return null
    return dateLike.toISOString()
}


const DateTimeScalar: ParsingFunctionsObject<Date, string> = {
    serialize: (parsed: unknown) => {
        if (parsed instanceof Date) {
            try {
                return dayjs(parsed).toISOString()
            } catch (error) {
                throw new Error('Failed to format date')
            }
        } else if (typeof parsed === 'string') {
            // assume is already serialized
            return parsed
        }

        throw new Error(
            `${parsed} is not a Date and therefore cannot be serialized with DateTimeScalar`,
        )
    },

    parseValue: (raw: unknown) => {

        if (typeof raw === 'string') {
            const parsedDate = dayjs(raw).toDate()
            const isValidDate = (Number.isNaN(parsedDate.getTime()) === false)
            if (isValidDate) {
                return parsedDate
            }
        }

        throw new Error(
            `${raw} is not a string and therefore it cannot be parsed into a Date`,
        )

    }
}

export const scalarsLink = withScalars({
    schema: buildClientSchema(
        // Force casted as per the official documentation:
        // Source: https://github.com/eturino/apollo-link-scalars#example-of-loading-a-schema
        introspectionQueryResult as unknown as IntrospectionQuery,
    ),

    // Add more scalars if required. Upload, JSON, etc.
    typesMap: {
        // Map DateTime scalars to resolve to javascript Date
        DateTime: DateTimeScalar,
    },
})
