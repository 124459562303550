import { useMutation, useQuery } from '@apollo/client';
import { Add, DoneAll, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Card, CardActions, CardContent, Checkbox, Chip, CircularProgress, Collapse, Divider, Fab, FormControlLabel, IconButton, ListItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { GridFilterListIcon } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { OrderStatus } from '../../../__generated__/graphql';
import { BasicProduct } from '../../../models/Order';
import { GET_CURRENT_STOCKS_QUERY } from '../../../network/get-supplied-products-query';
import { parseDate } from '../../../network/gql-links/scalars';
import { PRODUCE_PRODUCT_MUTATION } from '../../../network/produced-products/produce-product-mutation';
import { PRODUCTS_TO_BE_PRODUCED_QUERY } from '../../../network/produced-products/productsToBeProduced-query';
import { AuthenticatedPageProps } from '../../../routes/AuthenticatedRoutes';
import { DateFilterAppBar } from '../../components/DateFilterAppBar';
import FilterSheet from '../../components/FilterSheet';
import IcecreamGroup from '../../components/IcecreamGroup';
import { SelectProductQuantity } from '../../components/ProductSelector';
import ProductType from '../../components/ProductType';
import AddProductBottomSheet from '../../components/add-product/AddProductBottomSheet';
import EmptyRows from '../../components/data-grid/empty-rows';
import ErrorMessage from '../../components/error-message';
import { useProductTypeFromDepartment } from '../../hooks/use-producttype-from-params';
import { useSearchFilters } from '../../hooks/use-search-filters';

export type ExtenderOrderStatusFilter = OrderStatus | "ALL"

export default function ProduceProductFactoryPage({ session }: AuthenticatedPageProps) {
    const selectedProductType = useProductTypeFromDepartment()

    const [showSelectProductForm, setShowSelectProductForm] = useState(false)
    const [filterDialogOpen, setFilterDialogOpen] = useState(false)
    // This is because the Filter dialog already uses the same hook from search params and this
    // will receive an update
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setParams] = useSearchFilters({
        from: dayjs().startOf('day').toISOString(),
        to: dayjs().endOf('day').toISOString()
    })
    const { tobeDeliveredAfterParam, tobeDeliveredBeforeParam } = useMemo(() => {
        const [from, to] = [params.get('from'), params.get('to')].map(it => parseDate(it, undefined))

        const startDate = from ? dayjs(from) : null
        const endDate = to ? dayjs(to) : null
        return { tobeDeliveredAfterParam: startDate, tobeDeliveredBeforeParam: endDate }
    }, [params])


    // Used to populate the add Product form
    const { 
        loading: fetchingProducts,
        data: products,
    } = useQuery(GET_CURRENT_STOCKS_QUERY, {
        variables: {
            productType: selectedProductType
        }
    })

    const { 
        loading: fetchingProductsToBeProduced, 
        data: dataGood, 
        error: productsToBeProducedError
    } = useQuery(PRODUCTS_TO_BE_PRODUCED_QUERY, {
        variables: {
            filters: {
                from: tobeDeliveredAfterParam!.toDate(),
                to: tobeDeliveredBeforeParam!.toDate(),
                productType: selectedProductType,
            }
        }
    })

    const loadingData = fetchingProductsToBeProduced 
    const errorInData = productsToBeProducedError 

    const [produceProduct, { loading: producingProduct }] = useMutation(PRODUCE_PRODUCT_MUTATION, {
        onCompleted: data => {
            console.log(`Producido producto ${data.produceProduct.product.name} (${data.produceProduct.product.id})`)
        },
        refetchQueries: [
            PRODUCTS_TO_BE_PRODUCED_QUERY, 
        ]
    })
    
    const onProduceProduct = useCallback((productId: string, amountToProduce: number) => {
        produceProduct({
            variables: {
                produceProduct: {
                    id: productId,
                    amount: amountToProduce,
                }
            }
        })
    }, [produceProduct])

    const shouldDisplayErrorState = useMemo(() => !loadingData && errorInData, [loadingData, errorInData])
    const shouldDisplayEmptyState = useMemo(() => !loadingData && dataGood?.productsToBeProduced?.length === 0, [loadingData, dataGood?.productsToBeProduced?.length])

    return (
        <>
            <DateFilterAppBar 
                startingText="Productos a producir" 
                from={tobeDeliveredAfterParam} 
                to={tobeDeliveredBeforeParam} 
            >
                <Button
                    variant='outlined' 
                    onClick={()=> setShowSelectProductForm(true)}
                    className="!text-white !border-white"> 
                    <Add/>
                    Añadir producto
                </Button>
            </DateFilterAppBar>
            {shouldDisplayErrorState && <ErrorMessage description={errorInData!.message} />}
            {shouldDisplayEmptyState && (
                <EmptyRows labelText='No hay nada que producir'>
                    <div className="flex flex-col items-center gap-2">
                        No hay productos para producir en las fechas seleccionadas
                        <Button className="w-fit" variant="contained" onClick={() => setFilterDialogOpen(true)}>
                            Cambiar filtros
                        </Button>
                    </div>
                </EmptyRows>
            )}
            <List sx={{ width: "100%", overflowY: 'auto' }}>
                {dataGood?.productsToBeProduced?.map(({ product, producedAmount, stock, remainingAmount, requestedAmount }) => (
                    <ProductToProduceListItem 
                        key={product.id} 
                        product={product} 
                        // No orders requested this item
                        isExtra={requestedAmount === 0}
                        requestedAmount={requestedAmount} 
                        alreadyProducedAmount={producedAmount} 
                        producingProduct={producingProduct} 
                        onProduceProduct={onProduceProduct} 
                        stock={stock}
                        remainingAmount={remainingAmount}
                    />
                ))}
            </List >

            {/* Filters for the orders */}
            < Fab
                size='large'
                color='primary'
                style={{ position: 'fixed', bottom: 24, right: 24 }}
                onClick={() => setFilterDialogOpen(true)}>
                <GridFilterListIcon />
            </Fab >

            <FilterSheet
                open={filterDialogOpen}
                dateRange
                dateQuickActions
                onClose={() => setFilterDialogOpen(false)} />
            
            <AddProductBottomSheet
                open={!fetchingProducts && showSelectProductForm}
                products={products?.suppliedProducts??[]}
                onSelectProduct={(product) => {
                    // Hacky; but this is the cheapest to get 
                    // the behavior without changing a lot of code or messing with localStorage
                    onProduceProduct(product.id, 0)
                }}
                onClose={() => setShowSelectProductForm(false)}/>
        </>
    );
}

interface ProductToProduceListItemProps {
    product: BasicProduct,
    requestedAmount: number,
    producingProduct: boolean,
    alreadyProducedAmount: number,
    isExtra?: boolean
    stock: number
    remainingAmount: number
    onProduceProduct: (productId: string, amountToProduce: number) => void
}
function ProductToProduceListItem({ 
    product, 
    requestedAmount, 
    isExtra = false,
    alreadyProducedAmount, 
    producingProduct, 
    onProduceProduct, 
    stock,
    remainingAmount
}: ProductToProduceListItemProps): React.JSX.Element {
    const [producedAmount, setProducedAmount] = useState(0);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
    const [isProductReady, setIsProductReady] = useState(false);
    const isPendingToProduce = remainingAmount > 0
    return <React.Fragment>
        <ListItem disablePadding sx={{ width: "100%", opacity: 1 }}>
            <Card variant='outlined' sx={{ width: "inherit", height: "inherit" }}>
                <CardContent>
                    <div className="flex w-full h-full justify-between items-center gap-4">
                        <div className="flex flex-col gap-2">
                            <Typography variant="h5" component="div">
                                {product.name}
                            </Typography>
                            <div className="flex flex-wrap gap-2 items-center">
                                <ProductType productType={product.productType} />
                                { product.icecreamGroup && <IcecreamGroup group={product.icecreamGroup} />}
                                { !isExtra && !isPendingToProduce && <Chip size="small" color="success" label="Ya has producido todo lo necesario por hoy" icon={<DoneAll />} />}
                                { isExtra && <Chip size="small" color="info" label="Añadido extra" icon={<Add />} />}
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <Typography className={isPendingToProduce ? "" : "line-through"} variant="h5" component="div"> {`Cantidad a producir: ${requestedAmount}`}</Typography>
                            <Typography variant="h5" component="div"> {`Ya producido: ${alreadyProducedAmount}`}</Typography>
                            <Typography variant="h5" component="div"> {`En cámara: ${stock}`}</Typography>
                        </div>
                    </div>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => setIsCollapsed((previousIsCollapse) => !previousIsCollapse)}
                    >
                        {isCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </CardActions>
                <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
                    <CardContent sx={{ color: "inherit" }}>
                        <SelectProductQuantity
                            quantity={producedAmount}
                            setQuantity={(value: number) => {
                                const newAmount = Math.max(0, value);
                                setProducedAmount(newAmount)
                            }} />
                        {!producingProduct && <>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isProductReady}
                                    onChange={(event) => setIsProductReady(event.target.checked)}
                                    size='small' />}
                                label="producto listo" />
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    onProduceProduct(product.id, producedAmount)
                                    setIsProductReady(false)
                                    setProducedAmount(0)
                                }}
                                disabled={!isProductReady}
                            >
                                Producir
                            </Button>
                        </>}
                        {producingProduct && <CircularProgress size={"2rem"} />}
                    </CardContent>
                </Collapse>
            </Card>
        </ListItem>
        <Divider />
    </React.Fragment>;
}

