import { Alert, Typography } from '@mui/material';
import React from 'react';


export interface ErrorMessageProps {
    title?: string
    description?: string
    children?: React.ReactNode
}
export default function ErrorMessage({ title = 'Algo no ha salido bien…', description, children }: ErrorMessageProps) {

    return (
        <Alert 
            severity="error"
            sx={{
                borderRadius: 4, '.MuiAlert-message': {
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '.25rem'
                }
            }}>
            {title && <Typography variant="body1" className="mb-1">{title}</Typography>}
            {description && <Typography variant="body1">{description}</Typography>}
            {children && <div className="flex flex-col w-full">
                {children}
            </div>}
        </Alert>
    );
}