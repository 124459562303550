import { z } from "zod"
import { validationFrom } from "../../../forms/validation"

export function parsePrice(value: string): number {
    return parseFloat(value.replace(',', '.'))
}

export const zodSchema = z.object({
    name: z.string({ required_error: 'Éste campo es obligatorio' }),
    address: z.string({ invalid_type_error: 'Éste campo es obligatorio' }),
    isSupplier: z.boolean(),
    billingInfoCIF: z.string().nullable(),
    suppliedById: z.string().nullable(),
})

export const validationSchema = validationFrom(zodSchema)
