import { gql } from "../../__generated__/gql";


export const LIST_BILLING_INFOS_QUERY = gql(/* GraphQL */`
    query ListBillingInfos {
        billingInfos {
            CIF
            companyName
            registeredAddress
            phoneNumber
            contactEmail
        }
    }
`)