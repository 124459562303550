import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React from "react";
import ProductType from "../ProductType";
import { OrderStatusChip } from "../order-status";
import IcecreamGroup from "../IcecreamGroup";


export const idCell: GridColDef = {
    field: 'id',
    headerName: 'ID',
    type: 'string',
}

export const orderNumberCell: GridColDef = {
    field: 'orderNumber',
    headerName: 'Nº Pedido',
    type: 'number',
}

export const productTypeCell: GridColDef = {
    field: 'productType',
    headerName: 'Tipo de producto',
    flex: 1,
    renderCell: (params) => {
        if(!params) 
            return null

        return <>
            <ProductType productType={params.row.productType} />
            <span className="w-5">&nbsp;</span>
            {params.row.icecreamGroup &&  <IcecreamGroup group={params.row.icecreamGroup} />}
        </>
        
    }
}


const dateValueFormatter = (value: any) => {
    if (value == null)
        return undefined

    return dayjs(value).format('DD/MM/YYYY')
}


export function dateCell(field: string, headerName: string): GridColDef {
    return {
        flex: 1,
        field,
        type: 'date',
        headerName,
        valueFormatter: dateValueFormatter,
        minWidth: 150
    }
}

export const createdAtCell: GridColDef = dateCell('createdAt', 'Fecha creado')
export const updatedAtCell: GridColDef = dateCell('updatedAt', 'Última modificación')
export const orderStatusCell: GridColDef = {
    flex: 1,
    field: 'status',
    headerName: 'Estado',
    renderCell: params => {
        if(!params) 
            return null
        
        return <OrderStatusChip status={params.value} />
    },
    type: 'string',
}