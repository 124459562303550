import { gql } from "../../__generated__/gql";



export const UPDATE_ORDER_STATUS_MUTATION = gql(/* GraphQL */`
    mutation UpdateOrderStatus($orderNumber: Int!, $status: OrderStatus!) {
        updateOrderStatus(orderNumber: $orderNumber, status: $status) {
            orderNumber
            status
        }
    }
`)