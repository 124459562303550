import { useParams, useSearchParams } from "react-router-dom"
import { Department, ProductType as GQLProductType } from "../../__generated__/graphql"
import { useMemo } from "react"


export function useProductTypeFromSearchParams(): GQLProductType|undefined {
    const [searchParams] = useSearchParams({})
    return useMemo(() => {
        const productType = searchParams.get('productType') as GQLProductType|null
        return productType || undefined
    }, [searchParams])

}


export function useProductTypeFromPathParams(): GQLProductType|undefined {
    const { productType } = useParams()
    return useMemo(() => {
        return productType as GQLProductType|undefined
    }, [productType])
}


/**
 * mapper 
 * @param department 
 * @returns 
 */
const productTypeForFactoryDepartment = (department: string|undefined): GQLProductType => {
    switch (department) {
        // Only these cases are fabricators
        case Department.Bakery: return GQLProductType.Bakery
        case Department.Icecream: return GQLProductType.Icecream
        case Department.Pastry: return GQLProductType.Pastry
        default:
            console.error(`Unexpected factory department: ${department}`) 
            return GQLProductType.Icecream
    }
}

export function useProductTypeFromDepartment(): GQLProductType|undefined {
    const { department } = useParams()
    return useMemo(() => productTypeForFactoryDepartment(department), [department])
}