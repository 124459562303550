import { gql } from "../../__generated__/gql";



export const UPDATE_TRAY_MUTATION = gql(/* GraphQL */`
    mutation UpdateTray($input: UpdateTrayInput!) {
        updateTray(input: $input) {
            id
            name
            grams
        }
    }
`)