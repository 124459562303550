import { useMutation, useQuery } from "@apollo/client"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import { Button, Fab, IconButton } from "@mui/material"
import { GridColDef, GridDeleteIcon } from "@mui/x-data-grid"
import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BillingInfo } from "../../../models/BillingInfo"
import { Location, mapListLocationQueryToLocations } from "../../../models/Location"
import { LIST_LOCATIONS_QUERY } from "../../../network/locations/list-locations-query"
import { CREATE_LOCATION_MUTATION, DELETE_LOCATION_MUTATION, UPDATE_LOCATION_MUTATION } from "../../../network/locations/location-mutations"
import ProductListSkeleton from "../../components/ProductListSkeleton"
import LocationDeleteDialog from "../../components/admin/location/LocationDeleteDialog"
import LocationForm, { LocationFormProps } from "../../components/admin/location/LocationForm"
import BDGDataGrid from "../../components/data-grid/BDGDataGrid"
import EmptyRows from "../../components/data-grid/empty-rows"
import { idCell } from "../../components/data-grid/shared-cells"
import ErrorMessage from "../../components/error-message"


interface LocationListProps {
    locations: Array<Location>
    onEditItemClick: (item: Location) => void
    onDeleteItemClick: (item: Location) => void;
    slots?: {
        noRowsOverlay?: () => React.ReactNode;
    };
}

enum BottomSheetAction {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    NONE = 'none'
}

interface BottomSheetState {
    action: BottomSheetAction
    selectedItem?: Location
}

function LocationList({ locations, onEditItemClick, onDeleteItemClick, slots }: LocationListProps) {
    const columns = [
        idCell,
        { field: 'name', headerName: 'Nombre', flex: 2 },
        { field: 'address', headerName: 'Dirección', flex: 2 },
        {
            field: 'type',
            headerName: 'Es proveedor',
            flex: 2,
            type: "boolean",
            renderCell: (params: { row: Location }) => {
                if(!params) 
                    return null
                return (
                    <div className="flex gap-4 justify-start">

                        {params.row.type === 'supplier' ? 'Sí' : 'No'}
                    </div>
                )
            }
        },
        {
            field: 'billingInfo',
            headerName: 'Nombre de Facturación',
            flex: 2,
            renderCell: (params: { row: Location }) => {
                if(!params) 
                    return null
                const { companyName, CIF } = params.row.billingInfo as BillingInfo || {};
                const billingInfoString = companyName && CIF ? `${companyName} (${CIF})` : '-';
                return <div>{billingInfoString}</div>;
            }
        },
        {
            field: 'supplier',
            headerName: 'Proveedor',
            flex: 2,
            renderCell: (params: { row: Location }) => {
                if(!params) 
                    return null
                const { name, id } = params.row.supplier || {};
                const supplierString = name && id ? `${name} (${id})` : '-';
                return <div>{supplierString}</div>;
            }
        },
        {
            field: 'actions',
            flex: 1,
            headerName: 'Actions',
            renderCell: (params: { row: Location }) => {
                if(!params) 
                    return null
                
                return <div className="flex gap-4 justify-start">
                    <IconButton onClick={() => { onEditItemClick(params.row) }}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteItemClick(params.row)}>
                        <GridDeleteIcon />
                    </IconButton>
                </div>
            }
        },
    ] satisfies Array<GridColDef>;

    return (
        <BDGDataGrid
            rows={locations}
            columns={columns}
            slots={slots}
        /> 
    );
}

function calculateInitialValues(location: Location): LocationFormProps['initialValues'] {
    return {
        id: location.id,
        name: location.name,
        address: location.address,
        isSupplier: location.type === 'supplier',
        billingInfoCIF: location.billingInfo?.CIF ?? null,
        suppliedById: location.supplierId ?? null
    }
}

export default function FactoriesPage() {
    const [locationFormState, setLocationFormState] = useState<BottomSheetState>({ action: BottomSheetAction.NONE })
    const closeModal = () => setLocationFormState({ action: BottomSheetAction.NONE })
    const createNew = locationFormState.selectedItem == null
    const navigate = useNavigate()
    const { loading, data: listLocationsData, error } = useQuery(LIST_LOCATIONS_QUERY)
    const locations = useMemo(() => {
        if (listLocationsData) {
            return mapListLocationQueryToLocations(listLocationsData)
        }
        return undefined
    }, [listLocationsData])
    const [createLocation, { loading: creatingLocation }] = useMutation(CREATE_LOCATION_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_LOCATIONS_QUERY]
    })
    const [updateLocation, { loading: updatingLocation }] = useMutation(UPDATE_LOCATION_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_LOCATIONS_QUERY]
    })

    const [deleteLocation, { loading: deletingLocation }] = useMutation(DELETE_LOCATION_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_LOCATIONS_QUERY]
    })

    if (!loading && locations) {
        return (
            <div className="flex flex-col w-full h-full">
                <LocationList
                    locations={locations}
                    onEditItemClick={(selectedItem) => setLocationFormState({ action: BottomSheetAction.CREATE, selectedItem })}
                    onDeleteItemClick={(selectedItem) => setLocationFormState({ action: BottomSheetAction.DELETE, selectedItem })}
                    slots={{
                        noRowsOverlay: () => (
                            <EmptyRows labelText='No hay proveedores o distribuidores disponibles'>
                                <div className="flex flex-col gap-4">
                                    <Button color="primary" variant='contained' onClick={() => setLocationFormState({ action: BottomSheetAction.CREATE })}>Dar de alta un proveedores o distribuidor</Button>
                                    <Button color="secondary" variant='outlined' onClick={() => navigate(-1)}>Volver atrás</Button>
                                </div>
                            </EmptyRows>
                        )
                    }}
                />
                <LocationForm
                    open={locationFormState.action === BottomSheetAction.CREATE || locationFormState.action === BottomSheetAction.EDIT}
                    create={createNew}
                    loading={creatingLocation || updatingLocation || deletingLocation}
                    initialValues={locationFormState.selectedItem ? calculateInitialValues(locationFormState.selectedItem) : undefined}
                    onSubmit={values => {
                        const locationFormParams = {
                            name: values.name,
                            address: values.address,
                            isSupplier: values.isSupplier,
                            billingInfoCIF: values.billingInfoCIF,
                            suppliedByLocationId: values.suppliedById
                        }
                        if (createNew) {
                            createLocation({
                                variables: { newLocation: locationFormParams }
                            })
                            return
                        }

                        if (!locationFormState.selectedItem) {
                            throw new Error('No location selected')
                        }

                        const selectedItem = locationFormState.selectedItem
                        const updatedLocationParams = {
                            id: selectedItem.id,
                            name: locationFormParams.name,
                            address: locationFormParams.address,
                            isSupplier: selectedItem.type === 'supplier',
                            billingInfoCIF: locationFormParams.billingInfoCIF,
                            suppliedByLocationId: locationFormParams.suppliedByLocationId
                        }
                        updateLocation({
                            variables: { update: updatedLocationParams }
                        })
                    }}
                    onClose={closeModal} />

                {locationFormState.selectedItem &&
                    <LocationDeleteDialog
                        name={locationFormState.selectedItem.name}
                        open={locationFormState.action === BottomSheetAction.DELETE}
                        loading={deletingLocation}
                        onClose={closeModal}
                        onDelete={() => {
                            deleteLocation({
                                variables: { id: locationFormState.selectedItem!.id }
                            })
                        }}
                    />}

                <Fab
                    size='large'
                    color='primary'
                    style={{ position: 'fixed', bottom: 24, right: 24 }}
                    onClick={() => setLocationFormState({ action: BottomSheetAction.CREATE })}>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}