import { Button } from "@mui/material"
import React, { ReactNode } from "react"
import { Link } from "react-router-dom"


type PropsOf<T extends React.FC> = Parameters<T>[0]

export interface NavButtonProps {
    className?: string
    children?: ReactNode
    to: string
    color?: PropsOf<typeof Button>['color']
    disabled?: boolean
}


export default function NavButton({ 
    className='', 
    children=null, 
    to, 
    disabled=false,
    color="primary" }: NavButtonProps) {
    return (
        <div className={className}>
            <Button
                variant="contained"
                color={color}
                disabled={disabled}
                fullWidth
                className='h-full'
                component={Link}
                to={to}
            >
                <div className="flex flex-col gap-y-2 items-center text-center">
                    {children}
                </div>
            </Button>
        </div>
    )
}