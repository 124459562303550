import { useMutation, useQuery } from "@apollo/client";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button, LinearProgress, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { LIST_ISSUES_QUERY } from "../../../network/issues/list-issues-factory-query";
import { UPDATE_ISSUE_STATUS_MUTATION } from "../../../network/issues/update-issue-mutation";
import Issue from "../../components/Issue";
import EmptyRows from "../../components/data-grid/empty-rows";
import ErrorMessage from "../../components/error-message";
import { useSearchFilters } from "../../hooks/use-search-filters";
import DoneIcon from '@mui/icons-material/Done';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const statuses = {
    unresolved: 'unresolved',
    resolved: 'resolved',
}

type Status = typeof statuses[keyof typeof statuses]

interface FilterBarProps {
    className?: string
    status: Status | null
    date: Date | null
    onChangeDate: (date: Date | null) => void
    onChangeStatus: (status: Status | null) => void
}
const tomorrow = () => dayjs().add(1, "day").startOf("day")



function IssueStatusFilter({ status, onChange }: any) {
    return (
        <ToggleButtonGroup
            color="primary"
            sx={{ backgroundColor: 'white' }}
            size="small"
            value={status}
            exclusive
            onChange={(_, value) => onChange(value)}
            aria-label="text alignment"
        >
            <ToggleButton value={statuses.unresolved} aria-label="right aligned">
                <WarningAmberIcon />
            </ToggleButton>
            <ToggleButton value={statuses.resolved} aria-label="left aligned">
                <DoneIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}


function FilterBar({ className = '', date, onChangeDate, status, onChangeStatus }: FilterBarProps) {

    return (
        <div className="flex flex-row items-center justify-end static p-5">
            <DatePicker
                className={[className, "self-center text-white !mx-4 "].join(' ')}
                label="Debe ser recogido"
                slotProps={{
                    field: {
                        clearable: true,
                    },
                    textField: {
                        size: 'small',
                    }
                }}
                value={date ? dayjs(date) : null}
                onChange={val => {
                    if (val?.isValid()) {
                        onChangeDate(val.toDate())
                    } else {
                        onChangeDate(null)
                    }
                }}
                shouldDisableDate={(date) => date.isBefore(tomorrow(), "day")}
            />
            <IssueStatusFilter status={status} onChange={onChangeStatus} />
        </div>
    )
}


export default function IssuesPage() {
    const [params, setParams] = useSearchFilters({
        status: statuses.unresolved,
    })

    const date = (() => {
        const date = params.get('date')
        const parsed = date ? dayjs(date) : null
        if (parsed && !parsed.isValid()) {
            setParams({})
        }
        return parsed?.isValid() ? parsed.toDate() : null
    })()

    const status = (() => {
        const status = params.get('status')
        const values = Object.values(statuses)
        if (!status) {
            return null
        }
        if (values.includes(status) === false) {
            return null
        }
        return status ?? statuses.unresolved
    })()

    const [mutate, { loading: updating, error: updateError }] = useMutation(UPDATE_ISSUE_STATUS_MUTATION, {
        refetchQueries: [LIST_ISSUES_QUERY]
    })
    const { data, error: fetchError, loading: fetching } = useQuery(LIST_ISSUES_QUERY, {
        variables: {
            date: date ?? undefined,
            resolved: status != null ? status === statuses.resolved : undefined
        }
    })

    const loading = fetching || updating
    const error = fetchError ?? updateError

    const grouped = useMemo(() => {
        const groups = Object
            .groupBy(data?.issues ?? [], it => it.pickedUpAt.name)

        return Object.entries(groups).flatMap(([_, values]) => {

            if (!values || values.length === 0)
                return []

            return [{
                place: values[0].pickedUpAt,
                requests: values
            }]
        })
    }, [data?.issues])


    return <>
        <LinearProgress style={{ opacity: loading ? 1 : 0 }} />
        <FilterBar
            date={date}
            status={status}
            onChangeStatus={status => {
                setParams(prev => {
                    if (status == null) {
                        prev.delete('status')
                        return prev
                    }
                    prev.set('status', status)
                    return prev
                })
            }}
            onChangeDate={date => {
                setParams(prev => {
                    if (date == null) {
                        prev.delete('date')
                        return prev
                    }
                    prev.set('date', dayjs(date).toISOString())
                    return prev
                })
            }}
        />
        {!loading && error && <ErrorMessage description={error.message} />}
        <div className="flex flex-wrap w-full justify-around gap-4 p-1">
            {data?.issues.length === 0 &&
                <EmptyRows
                    labelText="Parece que no hay solicitudes de devolución">
                    {date && <Button variant="contained" onClick={() => setParams({})}>Prueba a quitar los filtros</Button>}
                    {!date && <Button variant="contained" component={Link} to="/">Ir a la página de inicio</Button>}
                </EmptyRows>
            }
            {
                grouped.map(({ place, requests }) => (
                    <div key={place.name} className="flex flex-col gap-2 w-full  bg-gray-100 border-gray-200 rounded-2xl shadow mx-4 ">
                        <div className="flex flex-col p-4">
                            <Typography color="text.secondary" variant="body2"> Solicitudes de devolución de </Typography>
                            <Typography variant="h6">{place.name}</Typography>
                            <Typography color="text.secondary" variant="body1"> {place.address} </Typography>
                        </div>
                        <div className="flex flex-wrap w-full justify-around gap-4 px-4 pb-4">
                            {requests.map(req =>
                                <Issue key={req.id} {...req} className="last:pb-4">
                                    <Button
                                        disabled={updating}
                                        variant="contained"
                                        color={req.resolved ? 'warning' : 'primary'}
                                        onClick={() => mutate({
                                            variables: {
                                                id: req.id,
                                                resolved: !req.resolved
                                            }
                                        })}>
                                        {req.resolved && <><UnpublishedIcon className="mr-1" /> Deshacer</>}
                                        {!req.resolved && <><CheckCircleIcon className="mr-1" /> Resolver </>}
                                    </Button>
                                </Issue>
                            )}
                        </div>
                    </div>
                ))
            }
        </div>

    </>
}