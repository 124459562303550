import { AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import React, { memo } from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useSession } from "../context/current-session";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation, useNavigate } from "react-router-dom";
export type AppToolbarProps = Required<Pick<ReturnType<typeof useSession>, 'session' | 'clearSession'>>

function AppToolbar({session, clearSession}: AppToolbarProps) {
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const isRootRoute = pathname === "/"
    return (
        <AppBar position="static">
            <Toolbar component={'nav'}>
                <IconButton 
                    sx={{visibility: isRootRoute ? 'hidden' : undefined}}
                    color="inherit" onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                </IconButton>
                <Button component={Link} to="/" sx={{ flexGrow: 1, color: 'white' }}>
                    <AccountCircleIcon fontSize="large" sx={{mr: 1}}/>
                    <Typography variant="body2" >
                        {session.user.displayName}
                    </Typography>
                </Button>
                <IconButton color="inherit" onClick={clearSession}>
                    <ExitToAppIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}


export default memo(AppToolbar)