import { useMutation, useQuery } from "@apollo/client"
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material"
import BackupTableIcon from '@mui/icons-material/BackupTable'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import { Button, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from "@mui/material"
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid"
import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AllProductsQuery, ProductType } from "../../../__generated__/graphql"
import { ALL_PRODUCTS_QUERY } from "../../../network/all-products-query"
import { CREATE_PRODUCT_MUTATION } from "../../../network/create-product-mutation"
import { TOGGLE_DELETE_PRODUCT_MUTATION } from "../../../network/delete-product-mutation"
import { UPDATE_PRODUCT_MUTATION } from "../../../network/update-product-mutation"
import ProductListSkeleton from "../../components/ProductListSkeleton"
import ProductForm, { ProductFormProps } from "../../components/admin/ProductForm"
import UploadCSVForm from "../../components/admin/UploadCSVForm"
import BDGDataGrid from "../../components/data-grid/BDGDataGrid"
import EmptyRows from "../../components/data-grid/empty-rows"
import { productTypeCell } from "../../components/data-grid/shared-cells"
import ErrorMessage from "../../components/error-message"

type Product = AllProductsQuery['rawProducts'][number]

type ProductFormState = {
    showSingle: boolean
    showCSV: boolean
    selectedProduct?: Product
}

interface ProductListProps {
    products: AllProductsQuery['rawProducts']
    onEditItemClick: (product: Product) => void
    onDeleteItemClick: (product: Product) => void
}


function ProductList({ products, onEditItemClick, onDeleteItemClick }: ProductListProps) {
    const columns = useMemo(() => ([
        {
            field: 'name',
            headerName: 'Nombre',
            flex: 1,
            renderCell: params => {
                if (!params)
                    return null

                const product = params.row
                return (
                    <span className={product.deleted ? 'line-through text-gray-400' : ''}>
                        {product.name}
                    </span>
                )
            }
        },
        {
            field: 'trays',
            flex: 1,
            headerName: 'Cubeta (sólo helados)',
            valueGetter: (_value, row) => row?.trays?.at(0)?.name ?? 'Ninguna',
        },
        productTypeCell,
        {
            field: 'returnable',
            headerName: 'Se puede devolver',
            flex: 1,
            type: 'boolean'
        },
        {
            field: 'deleted',
            headerName: 'Eliminado',
            flex: 1,
            type: 'boolean'
        },
        {
            flex: 1,
            headerName: 'Acciones',
            type: 'actions',
            field: 'actions',
            getActions: params =>
                params
                    ? ([
                        <GridActionsCellItem
                            label="Edit"
                            icon={<EditIcon />}
                            onClick={ev => {
                                ev.stopPropagation()
                                ev.preventDefault()
                                onEditItemClick(params.row)
                            }} />,
                        <GridActionsCellItem
                            label="Delete"
                            icon={
                                !params.row.deleted
                                    ? <DeleteIcon />
                                    : <RestoreFromTrashIcon />
                            }
                            onClick={ev => {
                                ev.stopPropagation()
                                ev.preventDefault()
                                onDeleteItemClick(params.row)
                            }} />,
                    ])
                    : []
        }
    ] satisfies Array<GridColDef>), [onDeleteItemClick, onEditItemClick])

    return (
        <BDGDataGrid
            rows={products}
            columns={columns}
            showCellVerticalBorder
            showColumnVerticalBorder
            getRowId={row => row.id}
        />
    )
}


function calculateInitialValues({ name, productType, returnable, trays, icecreamGroup }: Product): ProductFormProps['initialValues'] {
    const trayId = trays.at(0)?.id ?? null
    return { name, productType, returnable, trayId, icecreamGroup: icecreamGroup ?? null }
}


export interface ProductsPageProps { }

export default function ProductsPage(props: ProductsPageProps) {
    const navigate = useNavigate()
    const [productFormState, setProductFormState] = useState<ProductFormState>({ showSingle: false, showCSV: false })
    const createNew = productFormState.selectedProduct == null
    const closeDialog = () => setProductFormState(prev => ({ ...prev, showSingle: false, selectedProduct: undefined}))
    const { loading, data, error } = useQuery(ALL_PRODUCTS_QUERY)
    const [create, { loading: creating }] = useMutation(CREATE_PRODUCT_MUTATION, {
        refetchQueries: [ALL_PRODUCTS_QUERY],
        onCompleted: () => {
            closeDialog()
        }
    })
    const [update, { loading: updating }] = useMutation(UPDATE_PRODUCT_MUTATION, {
        refetchQueries: [ALL_PRODUCTS_QUERY],
        onCompleted: () => {
            closeDialog()
        }
    })

    const [setDeleteProduct] = useMutation(TOGGLE_DELETE_PRODUCT_MUTATION, {
        refetchQueries: [ALL_PRODUCTS_QUERY],
        onCompleted: () => {
            closeDialog()
        }
    })

    const products = useMemo(() => data?.rawProducts.map(p => p.name) ?? [], [data])

    if (!loading && data) {
        return (
            <div className="flex flex-col w-full h-full">
                {data.rawProducts.length === 0 && (
                    <EmptyRows labelText='No hay productos disponibles'>
                        <div className="flex flex-col gap-4">
                            <Typography className="pb-4">Prueba a dar alguno de alta</Typography>
                            <Button color="primary" variant='contained' onClick={() => setProductFormState(prev => ({ ...prev, showSingle: true }))}>Crea un producto</Button>
                            <Button color="secondary" variant='outlined' onClick={() => navigate(-1)}>Volver atrás</Button>
                        </div>
                    </EmptyRows>
                )}

                {data.rawProducts.length > 0 && (
                    <ProductList
                        products={data.rawProducts}
                        onEditItemClick={(selectedProduct) => setProductFormState(prev => ({ ...prev, showSingle: true, selectedProduct }))}
                        onDeleteItemClick={(selectedProduct) => setDeleteProduct({
                            variables: {
                                id: selectedProduct.id,
                                deleted: !selectedProduct.deleted
                            }
                        })}
                    />
                )}

                <ProductForm
                    products={products}
                    open={productFormState.showSingle}
                    create={createNew}
                    loading={creating || updating}
                    initialValues={productFormState.selectedProduct ? calculateInitialValues(productFormState.selectedProduct) : undefined}
                    onSubmit={(values) => {
                        const product = {
                            name: values.name,
                            productType: values.productType,
                            returnable: values.returnable,
                            icecreamGroup: values.productType === ProductType.Icecream ? values.icecreamGroup : null,
                            trayId: values.productType === ProductType.Icecream ? values.trayId : null
                        }
                        console.log('Submitting', {
                            product
                        })
                        if (createNew) {
                            create({
                                variables: { product }
                            })
                        } else {
                            update({
                                variables: {
                                    id: productFormState.selectedProduct!.id,
                                    product
                                }
                            })
                        }
                    }}
                    onClose={closeDialog} />

                <UploadCSVForm
                    open={productFormState.showCSV}
                    onClose={() => setProductFormState(prev => ({ ...prev, showCSV: false }))} />


                <SpeedDial
                    ariaLabel="Actions for product management"
                    color='primary'
                    icon={<SpeedDialIcon />}
                    sx={{ position: 'fixed', bottom: 56, right: 36 }}>
                    <SpeedDialAction
                        icon={<AddIcon />}
                        onClick={() => setProductFormState(prev => ({ ...prev, showSingle: true }))}
                        tooltipTitle="Crear" />
                    <SpeedDialAction
                        icon={<BackupTableIcon />}
                        onClick={() => setProductFormState(prev => ({ ...prev, showCSV: true }))}
                        tooltipTitle="Desde CSV" />
                </SpeedDial>
            </div>
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}