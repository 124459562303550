import { gql } from "../__generated__/gql";



export const SET_STOCKS_MUTATION = gql(/* GraphQL */`
    mutation SetStock($stock: StockChange!) {
        setStock(stockChange: $stock) {
            id 
        }
    }
`)