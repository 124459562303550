import { useMutation, useQuery } from "@apollo/client"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import { Button, Fab, IconButton } from "@mui/material"
import { GridColDef, GridDeleteIcon } from "@mui/x-data-grid"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ListUsersQuery } from "../../../__generated__/graphql"
import { CREATE_USER_MUTATION } from "../../../network/users/create-user-mutation"
import { DELETE_USER_MUTATION } from "../../../network/users/delete-user-mutation"
import { LIST_USERS_QUERY } from "../../../network/users/list-users-query"
import { SET_USER_WORKING_FOR_LOCATION_MUTATION } from "../../../network/users/set-user-working-for-location-mutation"
import { UPDATE_USER_MUTATION } from "../../../network/users/update-user-mutation"
import { AuthenticatedPageProps } from "../../../routes/AuthenticatedRoutes"
import ProductListSkeleton from "../../components/ProductListSkeleton"
import UserDeleteDialog from "../../components/admin/users/UserDeleteDialog"
import UserForm, { UserFormProps } from "../../components/admin/users/UserForm"
import UserLocationForm, { UserLocationFormProps } from "../../components/admin/users/UserLocationForm"
import BDGDataGrid from "../../components/data-grid/BDGDataGrid"
import EmptyRows from "../../components/data-grid/empty-rows"
import DepartmentChip from "../../components/departments"
import ErrorMessage from "../../components/error-message"


type User = ListUsersQuery['users'][number]
interface UserListProps {
    users: Array<User>;
    onEditItemClick: (selectedUser: User) => void;
    onEmailChange: (user: User) => void;
    onDeleteItemClick: (selectedUser: User) => void;
    onSetUserLocationClick: (selectedUser: User) => void;
    disabledActions: (userId: string) => boolean;
    slots?: {
        noRowsOverlay?: () => React.ReactNode;
    };
}

enum UserBottomSheetAction {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    SET_USER_LOCATION = 'set_user_location',
    NONE = 'none'
}

function UserList({
    users,
    onEditItemClick,
    onEmailChange,
    onDeleteItemClick,
    onSetUserLocationClick,
    disabledActions,
    slots
}: UserListProps) {
    const columns: Array<GridColDef> = [
        { field: 'displayName', headerName: 'Nombre completo', flex: 1 },
        { field: 'username', headerName: 'Usuario', flex: 1 },
        {
            field: 'contactEmail',
            headerName: 'Correo electrónico',
            flex: 1,
            type: 'string',
            editable: true,
            renderCell: (params) => (
                params
                    ? (
                        <span className={!params.row.contactEmail ? 'italic' : ''}>
                            {params.row.contactEmail ?? 'Sin correo electrónico'}
                        </span>
                    )
                    : null
            ),
        },
        {
            field: 'elevated',
            headerName: 'Administrador',
            type: "boolean",
        },
        {
            field: 'location',
            headerName: 'Lugar de trabajo',
            flex: 1,
            renderCell: (params: { row: User }) =>
                params
                    ? (<div
                        className="flex flex-row items-center gap-1 h-full w-full cursor-pointer"
                        onClick={() => onSetUserLocationClick(params.row)}>
                        {
                            params.row.worksAt
                                ? <p>{params.row.worksAt?.location?.name}</p>
                                : <span className="italic">Sin lugar de trabajo</span>
                        }
                    </div>)
                    : null
        },
        {
            field: 'departments',
            headerName: 'Departamentos',
            flex: 2,
            renderCell: (params: any) => {
                if (!params)
                    return null

                return (
                    <div
                        className="flex flex-row items-center gap-1 h-full w-full cursor-pointer"
                        onClick={() => onSetUserLocationClick(params.row)}>
                        {
                            params.row.worksAt
                                ? (
                                    <div
                                        className="flex flex-row flex-wrap gap-1"
                                        onClick={() => onSetUserLocationClick(params.row)}>
                                        {params.row.worksAt.departments.map((department: any) => <DepartmentChip key={department} department={department} small={true} />)}
                                    </div>
                                )
                                : <span className="italic">No pertenece a ningún departamento</span>
                        }
                    </div>
                )
            }
        },
        {
            field: 'actions',
            flex: 1,
            headerName: 'Acciones',
            renderCell: (params: { row: User }) =>
                params
                    ? (
                        <div className="flex gap-4 justify-start">
                            <IconButton disabled={disabledActions(params.row.id)} onClick={() => onEditItemClick(params.row)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton disabled={disabledActions(params.row.id)} onClick={() => onDeleteItemClick(params.row)}>
                                <GridDeleteIcon />
                            </IconButton>
                        </div>
                    )
                    : null
        },
    ];

    return (
        <BDGDataGrid
            getRowId={(row) => row.id}
            rows={users}
            processRowUpdate={(newRow: any, oldRow: any) => {
                // Write an email regex
                if (newRow.contactEmail && !newRow.contactEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
                    alert('Correo electrónico inválido')
                    return oldRow
                }

                onEmailChange(newRow)
                return newRow
            }}
            disableRowSelectionOnClick
            rowHeight={72}
            columns={columns}
            slots={slots}
        />
    );
}

function calculateInitialValues(user: User): UserFormProps['initialValues'] {
    return {
        displayName: user.displayName,
        username: user.username,
        password: "",
        confirmPassword: "",
        elevated: user.elevated,
    }
}

function calculateSetUserLocationInitialValues(user: User): UserLocationFormProps['initialValues'] {
    return {
        locationId: user.worksAt?.location?.id ?? null,
        departments: user.worksAt?.departments ?? [],
    }
}

interface UserBottomSheetState {
    action: UserBottomSheetAction
    selectedUser?: User
}

export default function UsersPage({ session }: AuthenticatedPageProps) {
    const [userBottomSheetState, setUserBottomSheetState] = useState<UserBottomSheetState>({ action: UserBottomSheetAction.NONE })
    const closeModal = () => setUserBottomSheetState({ action: UserBottomSheetAction.NONE })
    const createNew = userBottomSheetState.action === UserBottomSheetAction.CREATE
    const navigate = useNavigate()
    const { loading, data, error } = useQuery(LIST_USERS_QUERY)
    const [createUser, { loading: creating }] = useMutation(CREATE_USER_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_USERS_QUERY]
    })
    const [updateUser, { loading: updating }] = useMutation(UPDATE_USER_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_USERS_QUERY]
    })
    const [deleteUser, { loading: deleting }] = useMutation(DELETE_USER_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_USERS_QUERY]
    })

    const [setUserLocation, { loading: settingUserLocation }] = useMutation(SET_USER_WORKING_FOR_LOCATION_MUTATION, {
        onCompleted: data => closeModal(),
        refetchQueries: [LIST_USERS_QUERY]
    })

    if (!loading && data) {
        return (
            <div className="flex flex-col w-full h-full">
                <UserList
                    users={data.users}
                    onEmailChange={(user) => updateUser({
                        variables: {
                            updatedUser: {
                                id: user.id,
                                displayName: user.displayName,
                                elevated: user.elevated,
                                contactEmail: user.contactEmail ? user.contactEmail : null,
                                username: null,
                                password: null
                            }
                        }
                    })}
                    onEditItemClick={(selectedUser) => setUserBottomSheetState({ action: UserBottomSheetAction.EDIT, selectedUser: selectedUser })}
                    onDeleteItemClick={(selectedUser) => setUserBottomSheetState({ action: UserBottomSheetAction.DELETE, selectedUser: selectedUser })}
                    onSetUserLocationClick={(selectedUser) => setUserBottomSheetState({ action: UserBottomSheetAction.SET_USER_LOCATION, selectedUser: selectedUser })}
                    disabledActions={(userIdOfRow) => session?.user?.id === userIdOfRow}
                    slots={{
                        noRowsOverlay: () => (
                            <EmptyRows labelText='No hay usuarios disponibles'>
                                <div className="flex flex-col gap-4">
                                    <Button color="primary" variant='contained' onClick={() => setUserBottomSheetState({ action: UserBottomSheetAction.CREATE })}>Dar de alta a un usuario</Button>
                                    <Button color="secondary" variant='outlined' onClick={() => navigate(-1)}>Volver atrás</Button>
                                </div>
                            </EmptyRows>
                        )
                    }}
                />
                <UserForm
                    open={userBottomSheetState.action === UserBottomSheetAction.CREATE || userBottomSheetState.action === UserBottomSheetAction.EDIT}
                    create={createNew}
                    loading={creating || updating}
                    initialValues={userBottomSheetState.selectedUser ? calculateInitialValues(userBottomSheetState.selectedUser) : undefined}
                    onSubmit={values => {
                        const user = {
                            displayName: values.displayName,
                            elevated: values.elevated,
                            username: values.username,
                            password: values.password
                        }

                        if (createNew) {
                            createUser({
                                variables: { newUser: user }
                            })
                        } else {
                            updateUser({
                                variables: { updatedUser: { ...user, id: userBottomSheetState.selectedUser!.id } }
                            })
                        }
                    }}
                    onClose={closeModal} />


                {userBottomSheetState.selectedUser &&
                    <UserDeleteDialog
                        username={userBottomSheetState.selectedUser.username}
                        open={userBottomSheetState.action === UserBottomSheetAction.DELETE}
                        loading={deleting}
                        onClose={closeModal}
                        onDelete={() => {
                            deleteUser({
                                variables: { id: userBottomSheetState.selectedUser!.id }
                            })
                        }}
                    />}

                {userBottomSheetState.selectedUser &&
                    <UserLocationForm
                        open={userBottomSheetState.action === UserBottomSheetAction.SET_USER_LOCATION}
                        loading={settingUserLocation}
                        initialValues={userBottomSheetState.selectedUser ? calculateSetUserLocationInitialValues(userBottomSheetState.selectedUser) : undefined}
                        onSubmit={values => {
                            setUserLocation({
                                variables: {
                                    assoc: {
                                        userId: userBottomSheetState.selectedUser!.id,
                                        locationId: values.locationId,
                                        departments: values.departments
                                    }
                                }
                            })
                        }}
                        onClose={closeModal}
                    />}

                <Fab
                    size='large'
                    color='primary'
                    style={{ position: 'fixed', bottom: 56, right: 24 }}
                    onClick={() => setUserBottomSheetState({ action: UserBottomSheetAction.CREATE })}>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <ProductListSkeleton />
}