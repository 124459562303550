import { PropsOf } from '@emotion/react'
import { Divider, ListItem, ListItemText } from "@mui/material"
import React from 'react'
import { ProductType as ProductTypeGQL } from '../../__generated__/graphql'
import ProductType from './ProductType'
import Price from './price'
import WeightComponent from './WeightComponent'



export type ProductWithAmountListItemProps = PropsOf<typeof ListItemText> & {
    name: string
    amount: number
    grams?: number | null,
    isLast?: boolean
    productType?: ProductTypeGQL
    rightSlot?: React.ReactNode
}



export function ProductAmount({
    name,
    amount,
    productType,
    grams = null,
    isLast = false,
    rightSlot = null,
    ...rest
}: ProductWithAmountListItemProps) {

    if (!productType)
        return <ListItemText primary={name} secondary={`x ${amount}`} {...rest} />

    return <>
        <ListItem
            disableGutters
            sx={{ width: "100%" }}
            secondaryAction={
                <div className="flex flex-row justify-end gap-2">
                    <div className="flex flex-col items-end">
                        <span className="pl-2 text-lg">
                            {`x ${amount}`}
                        </span>
                        {grams != null && <WeightComponent className="pl-2 text-lg" grams={grams}/> }
                    </div>
                    {rightSlot}
                </div>
            }
        >
            <ListItemText
                primary={<span>{`${name}`}</span>}
                secondary={<ProductType productType={productType} small={true} />}
            />
        </ListItem>
        {!isLast && <Divider />}
    </>
}


export type ProductWithPriceListItemProps = PropsOf<typeof ListItemText> & {
    name: string
    price: number
}


export function ProductWithPriceListItem({ name, price, ...rest }: ProductWithPriceListItemProps) {
    return <ListItemText
        primary={name}
        secondary={<Price price={price} />} {...rest} />
}



