import { gql } from "../../__generated__/gql";


export const LIST_MY_ISSUES_QUERY = gql(/* GraphQL */ `
    query ListMyIssues {
        myIssues {
            id
            observations
            resolved
            createdAt
            updatedAt
            toBePickedUpOn
            pickedUpAt {
             	address
                name
            }
        }
    }
`)