import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import theme from './ui/theme';
import { RootRoutes } from './routes/RootRoutes';
import { ApolloProvider } from '@apollo/client';
import { graphqlClient } from './network/graphql-client';
import { SessionProvider } from './ui/context/current-session';
import { CurrentOrderProvider } from './ui/context/current-order';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es';
import dayjs from 'dayjs';
dayjs.locale('es')

export default function App() {
  return (
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
            <SessionProvider>
                <CurrentOrderProvider>
                    <ApolloProvider client={graphqlClient}>
                        <RootRoutes/>
                    </ApolloProvider>
                </CurrentOrderProvider>
            </SessionProvider>
        </LocalizationProvider>
    </ThemeProvider>
  );
}
