import { ListItem, ListItemText, Skeleton } from "@mui/material"
import React from "react"


function Skelement() {
    return (
        <ListItem>
            <ListItemText  
                primary={<Skeleton className="w-full" variant='text' />} 
                secondary={<Skeleton className="w-4/5" variant='text' />} />
            <span className='px-2'></span>
            <Skeleton variant="rounded" width={50} height={40} />
            <span className='px-1'></span>
            <Skeleton variant="rounded" width={50} height={40} />
        </ListItem>
    )
}


export interface ProductListSkeletonProps {
    length?: number
    Component?: React.FC
}

export default function ProductListSkeleton({
    length=11, 
    Component=Skelement
}: ProductListSkeletonProps) {
    return <>{Array.from({length}).map((_, idx) => <Component key={idx} />)}</>
}