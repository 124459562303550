import { ListItem, ListItemText, Skeleton } from "@mui/material"
import React from "react"

export function ProductListPadding() {
    const lorem = 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
    return (
        <ListItem className='opacity-0'>
            <ListItemText  
                primary={lorem}
                secondary={lorem} />
            <span className='px-2'></span>
            <Skeleton variant="rounded" width={50} height={40} />
            <span className='px-1'></span>
            <Skeleton variant="rounded" width={50} height={40} />
        </ListItem>
    )
}