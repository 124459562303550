import { gql } from "../../__generated__/gql";


export const LIST_ISSUES_QUERY = gql(/* GraphQL */`

    query ListIssues($date: DateTime, $resolved: Boolean) {
        issues(date: $date, resolved: $resolved) {
            id
            observations
            resolved
            createdAt
            updatedAt
            toBePickedUpOn
            pickedUpAt {
             	address
                name
            }
        }
    }

`)