import { gql } from "../../__generated__/gql";


export const CREATE_TRAY_MUTATION = gql(/* GraphQL */`

    mutation CreateTray($input: CreateTrayInput!) {
        createTray(input: $input) {
            id
            name
            grams
        }
    }

`)