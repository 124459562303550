import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import BottomSheetDialog from "../../bottom-sheet/BottomSheetDialog";
import LoadingButton from "../../loading-button";
import { validationSchema, zodSchema } from "./billing-info-form-helpers";

const defaultInitialValues = {
    CIF: "",
    companyName: "",
    registeredAddress: "",
    phoneNumber: "",
    contactEmail: ""
}


export interface BillingInfoFormProps {
    open?: boolean
    create?: boolean
    loading?: boolean
    initialValues?: {
        CIF: string
        companyName: string
        registeredAddress: string
        phoneNumber: string
        contactEmail: string
    },
    onSubmit?: (value: ReturnType<typeof zodSchema.parse>, resetForm: () => void) => void
    onClose: () => void
}

type FormWithDataProps = Pick<BillingInfoFormProps, 'onSubmit' | 'initialValues' | 'loading' | 'onClose' | 'create'>

function FormWithData({
    create,
    loading,
    initialValues = defaultInitialValues,
    onSubmit,
    onClose
}: FormWithDataProps) {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form, helpers) => {
            return onSubmit?.(zodSchema.parse(form), helpers.resetForm)
        }
    })


    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">
                <TextField
                    type="text"
                    name="CIF"
                    label="CIF"
                    placeholder="CIF de la empresa"
                    className="grow"
                    error={formik.touched.CIF && Boolean(formik.errors.CIF)}
                    helperText={formik.touched.CIF && formik.errors.CIF}
                    onBlur={formik.handleBlur}
                    value={formik.values.CIF}
                    onChange={formik.handleChange}
                />
                <TextField
                    type="text"
                    name="companyName"
                    label="Nombre de la empresa"
                    placeholder="Nombre de la empresa"
                    className="grow"
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                />
                <TextField
                    type="text"
                    name="registeredAddress"
                    label="Dirección"
                    placeholder="Dirección de la empresa"
                    className="grow"
                    error={formik.touched.registeredAddress && Boolean(formik.errors.registeredAddress)}
                    helperText={formik.touched.registeredAddress && formik.errors.registeredAddress}
                    onBlur={formik.handleBlur}
                    value={formik.values.registeredAddress}
                    onChange={formik.handleChange}
                />
                <TextField
                    type="text"
                    name="phoneNumber"
                    label="Número de contacto"
                    placeholder="Número de contacto de la empresa"
                    className="grow"
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                />
                <TextField
                    type="text"
                    name="contactEmail"
                    label="Correo electrónico de contacto"
                    placeholder="Correo electrónico"
                    className="grow"
                    error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
                    helperText={formik.touched.contactEmail && formik.errors.contactEmail}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactEmail}
                    onChange={formik.handleChange}
                />
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        isLoading={loading}
                        disabled={!formik.isValid}
                        type="submit"
                        variant="contained">
                        {create ? 'Añadir nueva información de facturación' : 'Guardar cambios'}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </form>
    )
}

export default function BillingInfoForm({
    open = false,
    create = true,
    loading = false,
    initialValues,
    onSubmit,
    onClose
}: BillingInfoFormProps) {
    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {create ? 'Añadir nueva información de facturación' : 'Modificar información de facturación'}
            </DialogTitle>
            <FormWithData
                loading={loading}
                create={create}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onClose={onClose} />
        </BottomSheetDialog>
    );
}
