import { gql } from "../__generated__/gql";


export const GET_PRODUCTS_FROM_ORDER_QUERY = gql(/* GraphQL */`
    query GetProductsFromOrder($orderNumber: Int!) {
        order(orderNumber: $orderNumber) {
            orderNumber
            status
            observations
            toBeDeliveredOn
            deliverToLocation {
                name
                address
            }
            products {
                product {
                    id
                    productType
                    icecreamGroup
                    name
                } 
                amount
            }

            pendingPreparationProducts {
                product {
                    id
                    productType
                    icecreamGroup
                    name
                }  
                amount
            }

            verifiedProducts {
                product {
                    id
                    productType
                    icecreamGroup
                    name
                }  
                amount
            }

            preparedProducts {
                id
                productType
                name
                amount
                grams
            }
        }
    }
`)