import { useCallback, useEffect, useState } from "react"
import { GetProductsFromOrderQuery, ProductType } from "../../../__generated__/graphql"

type ProductWithAmount = GetProductsFromOrderQuery['order']['pendingPreparationProducts']

const getKey = (orderNumber: string) => `prepared-products-${orderNumber}`
function readFromLocalStorage(orderNumber: string) {
    const diskItem = localStorage.getItem(getKey(orderNumber))
    const data = JSON.parse(
        diskItem ?? '{ "trays": [], "others": [] }',
        (key, value) => {
            if (key === 'trays') {
                return new Map(value as Array<[string, Array<string | undefined>]>)
            }
            return value
        }
    )
    return data as {
        trays: Map<string, Array<string | undefined>>,
        others: Array<{ productId: string, amount: number | undefined }>
    }
}

function writeToLocalStorage(orderNumber: string, data: ReturnType<typeof asInitialValues>) {
    localStorage.setItem(getKey(orderNumber), JSON.stringify({
        trays: Array.from(data.trays.entries()),
        others: data.others
    }))
}

function asInitialValues(orderNumber: string | undefined, products: ProductWithAmount) {
    if (orderNumber == null) {
        return {
            trays: new Map<string, Array<string | undefined>>(),
            others: [] as Array<{ productId: string, amount: number | undefined }>
        }
    }
    const groupedItems = Object.groupBy(products, it => (
        it.product.productType === ProductType.Icecream
            ? 'icecream'
            : 'others'
    ))
    const stateFromDisk = readFromLocalStorage(orderNumber)

    const val = {
        trays: new Map(
            (groupedItems.icecream ?? []).map((it) => {
                const diskTrays = stateFromDisk.trays.get(it.product.id) ?? [];
                const trays = Array.from({ length: it.amount }, (_, index) => {
                    return diskTrays.at(index) ?? ''
                })
                return [it.product.id, trays] as const
            })
        ),
        others: (groupedItems.others ?? []).map((it) => {
            const previousEntry = stateFromDisk.others.find(other => other.productId === it.product.id)
            return {
                productId: it.product.id,
                amount: previousEntry?.amount
            }
        })
    }
    return val
}

interface UsePreparedProductsOptions {
    orderNumber?: string,
    products: ProductWithAmount | undefined,
    onSubmit: (value: ReturnType<typeof asInitialValues>) => void
}


/**
 * Prepares a formik instance that keeps track of the products 
 * that have been prepared even if they were stored in localStorage
 */
export function usePreparedProductsForm({ orderNumber, products, onSubmit }: UsePreparedProductsOptions) {
    const [values, setState] = useState(asInitialValues(orderNumber, products ?? []))

    const handleSubmit = useCallback((event: any) => {
        event.preventDefault()
        event.stopPropagation()
        const purgedTrays = new Map(values.trays)
        for (const [key, value] of Array.from(purgedTrays.entries())) {
            const isEmpty = value.length === 0
            const allZeroesOrUndefineds = value.every(it => !it)
            if (isEmpty || allZeroesOrUndefineds) {
                purgedTrays.delete(key)
                continue
            }
            // Remove all undefined and zero values
            purgedTrays.set(key, value.filter(Boolean))
        }
        // Remove all undefined and zero values
        const purgedValues = values.others.filter(it => Boolean(it.amount))

        onSubmit({
            trays: purgedTrays,
            others: purgedValues
        })
    }, [values, onSubmit])

    // Persist data on change
    const setFieldValue = useCallback(
        (patch: (prev: typeof values) => typeof values) => {
            setState(prev => {
                const copy = patch(prev)
                if (orderNumber != null) {
                    writeToLocalStorage(orderNumber, copy)
                }
                return {...copy}
            })
        },
        [orderNumber, setState]
    )

    useEffect(() => {
        setState(asInitialValues(orderNumber, products ?? []))
    }, [products, orderNumber, setFieldValue])

    const addTray = (productId: string) => setFieldValue(({ trays, others }) => {
        const safeArray = trays.get(productId) ?? []
        trays.set(productId, [...safeArray, undefined])
        const copy = new Map(trays)
        return { others, trays: copy }
    })

    const removeTray = (productId: string, index: number) => setFieldValue(({trays, others}) => {
        const newTrays = (trays.get(productId) ?? []).filter((_, i) => i !== index)
        trays.set(productId, newTrays)
        const copy = new Map(trays)
        return { trays: copy, others }        
    })

    const setWeight = (productId: string, index: number, inputValue: string) => setFieldValue(({trays, others}) => {
        const newTrays = [...(trays.get(productId) ?? [])]
        if (index < newTrays.length) {
            newTrays[index] = inputValue
        }
        trays.set(productId, newTrays)
        const copy = new Map(trays)
        return { trays: copy, others }
    })

    const addOther = (productId: string) => setFieldValue(({trays, others}) => {
        return { 
            trays,
            others: [...others, { productId, amount: undefined }], 
        }  
    })

    const removeOther = (productId: string) => setFieldValue(({trays, others}) => {
        return {
            trays,
            others: others.filter(it => it.productId !== productId)
        }
    })

    const setAmount = (productId: string, amount: number) => setFieldValue(({trays, others}) => {
        const newOthers = [...others]
        const index = newOthers.findIndex(it => it.productId === productId)
        if (index === -1) {
            return { trays, others }
        }
        newOthers[index] = { productId, amount }
        return {
            trays,
            others: newOthers
        }
    })
    
    const reset = () => {
        if(!orderNumber) {
            return
        }

        console.log('Clearing local storage for order number', {
            orderNumber
        })
        writeToLocalStorage(orderNumber, {
            trays: new Map(),
            others: []
        })
    }

    return {
        handleSubmit,
        values,
        addTray,
        removeTray,
        setWeight,
        addOther,
        removeOther,
        setAmount,
        reset
    }
}
