import { Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";


interface AmountReadyCheckboxesProps {
    disabled?: boolean;
    readyAmount: number;
    requestedAmount: number;
    onChange: (checkedCount: number) => void;
}

export default function AmountReadyCheckboxes({ 
    disabled=false, 
    readyAmount, 
    requestedAmount, 
    onChange 
}: AmountReadyCheckboxesProps) {
    const [checkedCount, setCheckedCount] = useState(readyAmount);

    // In case props change
    useEffect(() => {
        setCheckedCount(readyAmount);
    }, [readyAmount]);

    const handleCheckboxChange = (_: number, checked: boolean) => {
        const newCheckedCount = checked ? checkedCount + 1 : checkedCount - 1;
        setCheckedCount(newCheckedCount);
        onChange(newCheckedCount);
    };

    const checkboxes = Array.from({ length: requestedAmount }, (_, i) => (
        <Checkbox
            key={i}
            checked={i < checkedCount}
            disabled={disabled}
            sx={{
                '&.Mui-checked.Mui-disabled': {
                    color: 'rgba(0, 128, 0, 0.65)' // half opaque green
                }
            }}
            onChange={(event) => handleCheckboxChange(i, event.target.checked)}
        />
    ));

    return (
        <div className="flex w-full h-full justify-center items-center gap-2">
            {checkboxes}
        </div>
    )
}