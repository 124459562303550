import { gql } from "../../__generated__/gql";

export const UPDATE_USER_MUTATION = gql(`
    mutation UpdateUser($updatedUser: UpdateUserParams!) {
        updateUser(updatedUser: $updatedUser) {
        id
        displayName
        elevated
        deleted
        }
    }
`)