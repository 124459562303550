import React from "react"
import { Autocomplete, FormControlProps, TextField } from "@mui/material"

export interface MultiSelectOption {
    label: string
    value: string
}

export interface MultiSelectProps {
    name?: string
    label: string
    selectedOptions: MultiSelectOption[]
    options: MultiSelectOption[]
    helperText?: string
    setOptions: (options: MultiSelectOption[]) => void
}

export default function MultiSelect({
    label,
    name,
    selectedOptions,
    options,
    setOptions,
    error,
    helperText,
    onBlur,
    sx,
    size
}: MultiSelectProps & FormControlProps) {
    const handleChange = (_event: React.ChangeEvent<{}>, newOptions: MultiSelectOption[]) => {
        setOptions(newOptions)
    }

    return (
        <Autocomplete
            multiple
            sx={sx}
            size={size}
            onBlur={onBlur}
            id={name}
            options={options}
            getOptionLabel={(option: MultiSelectOption) => option.label}
            value={selectedOptions}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    )
}
