import { useQuery } from '@apollo/client';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Fab } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseDate } from '../../../network/gql-links/scalars';
import { PLACED_ORDERS_BY_DATE_QUERY } from '../../../network/placed-orders-by-date';
import FilterSheet from '../../components/FilterSheet';
import BDGDataGrid from '../../components/data-grid/BDGDataGrid';
import EmptyRows from '../../components/data-grid/empty-rows';
import { createdAtCell, idCell, orderStatusCell, updatedAtCell } from '../../components/data-grid/shared-cells';
import { useSearchFilters } from '../../hooks/use-search-filters';


const columns: GridColDef[] = [
    idCell,
    orderStatusCell,
    createdAtCell,
    updatedAtCell
];


function paramsToFilters(params: URLSearchParams) {
    const [from, to] = [params.get('from'), params.get('to')].map(it => parseDate(it, undefined))
    if (from && to) return { from, to }
    if (from) return { from }
    return {}
}



export default function PlacedOrdersOverviewPage() {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [params, setParams] = useSearchFilters({})
    const navigate = useNavigate()
    const filters = useMemo(() => paramsToFilters(params), [params])
    const { loading, data } = useQuery(PLACED_ORDERS_BY_DATE_QUERY, {
        variables: { filters }
    })
    const rows = useMemo(() => data?.placedOrders ?? [], [data])
    return (
        <>
            <BDGDataGrid
                rows={rows}
                className="mt-1"
                columns={columns}
                loading={loading}
                slots={{
                    noRowsOverlay: () => (
                        <EmptyRows labelText='Parece que no hay pedidos…' >
                            <Button variant="outlined" color="warning" onClick={() => setParams(params => {
                                params.delete('from')
                                params.delete('to')
                                return params
                            })}>
                                Limpiar filtros
                                <span className="px-1"></span>
                                <DeleteSweepIcon />
                            </Button>
                        </EmptyRows>
                    )
                }}
                onRowClick={(rowParams) => navigate(`/prepare-products/${rowParams.id}?productType=${params.get('productType')}`)}
                disableRowSelectionOnClick
            />
            <Fab
                color="success"
                size='large'
                disabled={loading}
                style={{ position: 'fixed', bottom: 64, right: 32 }}
                onClick={() => setDialogOpen(true)}>
                <TuneIcon />
            </Fab>

            <FilterSheet
                open={dialogOpen}
                dateRange
                dateQuickActions
                onClose={() => setDialogOpen(false)} />
        </>
    );
}

