import { DialogTitle, Autocomplete, createFilterOptions, TextField, Button } from "@mui/material"
import React from "react"
import BottomSheetDialog from "../bottom-sheet/BottomSheetDialog"
import { ProductType } from "../../../__generated__/graphql"
import Product from "../Product"

type IProduct = {
    id: string
    name: string
    productType: ProductType
}

interface AddProductBottomSheetProps {
    open: boolean,
    products?: Array<{
        id: string,
        name: string,
        productType: ProductType
    }>,
    onSelectProduct: (product: IProduct) => void
    onClose: () => void
}

export default function AddProductBottomSheet({
    open,
    products,
    onSelectProduct,
    onClose,
}: AddProductBottomSheetProps) {

    return (
        <BottomSheetDialog
            open={open}
            keepMounted={false}
            onClose={onClose}>
            <DialogTitle className="text-center">
                ¿Qué producto quieres añadir?
            </DialogTitle>

            <Autocomplete
                className="p-5 pb-10"
                options={products ?? []}
                getOptionLabel={option => option.name}
                filterOptions={createFilterOptions({
                    ignoreCase: true,
                    ignoreAccents: true
                })}
                renderOption={(_props, value, _state, _ownerState) => (
                    <Product
                        key={value.id}
                        className="cursor-pointer"
                        onClick={() => {
                            onSelectProduct(value)
                            onClose()
                        }}
                        product={value} />
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        label="Selecciona un producto"
                    />
                )}
            />
            <div className="p-5 w-full">
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={onClose}>
                    Cancelar
                </Button>
            </div>

        </BottomSheetDialog>
    )
}