import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { GET_LOCATION_BY_ID } from "../../../network/locations/location-by-id-query";
import { LIST_RETURNED_PRODUCTS_QUERY } from "../../../network/return-product/returned-products-query";

interface UseRefundPageDataOptions {
    locationId: string
    filters?: {
        dateFilters?: {
            from: Date
            to: Date
        }
    }
}


function getMonthlyRange() {
    const now = dayjs()
    return {
        from: now.startOf('month').toDate(),
        to: now.endOf('month').toDate()
    }
}

export function useRefundPageData({
    locationId,
    filters = {
        dateFilters: getMonthlyRange()
    }
}: UseRefundPageDataOptions) {

    const {
        loading: returnedProductsLoading,
        data: returnedProductsData,
        error: returnedProductsError
    } = useQuery(LIST_RETURNED_PRODUCTS_QUERY, {
        variables: {
            filters: {
                locationId,
                dateFilters: filters.dateFilters
            }
        },
        skip: !Boolean(locationId)
    })

    const {
        loading: locationLoading,
        data: locationData,
        error: locationError
    } = useQuery(GET_LOCATION_BY_ID, {
        variables: { id: locationId },
        skip: !Boolean(locationId)
    })

    const loading = returnedProductsLoading || locationLoading
    const error = returnedProductsError || locationError
    const partial = !loading && error

    const data = (() => {
        if (loading) {
            return undefined
        }
        return {
            returnedProducts: returnedProductsData?.returnedProducts,
            location: locationData?.locationById
        }
    })()

    return { loading, error, data, partial }
}