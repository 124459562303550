import React from "react";
import { validationFrom } from "../forms/validation";
import { Avatar, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import LoadingButton from "./loading-button";
import { z } from "zod";
import ErrorMessage from "./error-message";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useSession } from "../context/current-session";
import { LOGIN_MUTATION } from "../../network/login-mutation";


const validationSchema = validationFrom(z.object({
    username: z.string({
        required_error: 'Éste campo es obligatorio'
    }),
    password: z.string({
        required_error: 'Éste campo es obligatorio'
    })
}))

export default function LoginForm() {
    const { setSession } = useSession()
    const navigate = useNavigate();

    const [mutate, { loading, error }] = useMutation(LOGIN_MUTATION, {
        onCompleted: ({ login }) => {
            console.log('Successfully logged in', login)
            setSession({ jwt: login.jwt, user: login.user })
            navigate('/')
        },
    })
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema,
        onSubmit: form => mutate({
            variables: {
                username: form.username,
                password: form.password
            }
        })
    })
    // #c5e1a5
    return (
        <form 
            className="rounded-xl flex flex-col gap-4 p-8 shadow-md w-full max-w-lg bg-[#f4f8e8]" 
            onSubmit={formik.handleSubmit}>
            {error && <ErrorMessage description={error.message} />}
            <div className="flex flex-row gap-3 items-center">
                <Avatar sizes="" src="favicon.png" />
                <Typography variant="h6">Iniciar sesión</Typography>
            </div>
            <TextField
                type="text"
                name="username"
                label="Nombre de usuario"
                placeholder="Nombre de usuario"
                className="w-full mb-4 p-2 rounded-md border border-gray-300"
                error={Boolean(formik.errors.username)}
                helperText={formik.errors.username}
                value={formik.values.username}
                onChange={formik.handleChange}
            />
            <TextField
                type="password"
                name="password"
                label="Contraseña"
                placeholder="Contraseña"
                className="w-full mb-4 p-2 rounded-md border border-gray-300"
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                onChange={formik.handleChange}
            />
            <LoadingButton
                isLoading={loading}
                disabled={!formik.isValid}
                type="submit"
                variant="contained"
                className="w-full">
                Iniciar sesión
            </LoadingButton>
        </form>
    );
}
