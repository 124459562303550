import { gql } from "../__generated__/gql";

export const MY_ORDERS_QUERY = gql(/* GraphQL */`
    query MyOrders {
        myOrders {
            observations
            orderNumber
            status
            toBeDeliveredOn
            orderer {
                displayName
            }
            createdAt
            updatedAt
            products {
                product {
                    id
                    name
                }
                amount
            }
        }
    }
`)