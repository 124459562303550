import { useMutation, useQuery } from '@apollo/client'
import { AppBar, LinearProgress, Typography } from '@mui/material'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { LIST_LOCATION_PRICES_QUERY } from '../../../network/prices/list-location-prices-query'
import { SET_SPECIFIC_PRICE_MUTATION } from '../../../network/prices/set-specific-price-mutation'
import BDGDataGrid from '../../components/data-grid/BDGDataGrid'
import { productTypeCell } from '../../components/data-grid/shared-cells'
import ErrorMessage from '../../components/error-message'
import Price, { formatEur } from '../../components/price'

const columns = [
    {
        field: 'productName',
        headerName: 'Producto',
        type: 'string',
        flex: 1,
        valueGetter: (_, row) => row.product.name,
        renderCell: (params: any) => {
            if (!params)
                return null

            return <span className="text-md font-medium">
                {params.row.product.name}
            </span>
        }
    },
    productTypeCell,
    {
        field: 'defaultPrice',
        headerName: 'Precio por defecto',
        flex: 1,
        type: 'number',
        valueGetter: (_, row) => row.defaultPrice,
        renderCell: (params) => (
            params
                ? <Price price={params.row.defaultPrice} />
                : null
        )
    },
    {
        field: 'specificPrice',
        headerName: 'Precio específico',
        flex: 1,
        type: 'number',
        valueSetter: (value: number, row: any) => {
            if (value) {
                return { ...row, specificPrice: Number(value.toFixed(2)) }
            } else {
                return { ...row, specificPrice: undefined }
            }
        },
        valueFormatter: (value: any) => {
            return value != null ? formatEur(value) : 'Sin precio específico'
        },


        // Prices come in cents from the backend therefore
        // Divide by 100
        valueGetter: (value: number, row: any) => {
            if (value == null)
                return undefined

            if (row.specificPrice) {
                return row.specificPrice / 100
            }
            return undefined
        },

        editable: true,
    },
    {
        field: 'precioFinalVenta',
        headerName: 'Precio final de venta',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        renderCell: (params) => {
            if (!params)
                return undefined

            const { defaultPrice, specificPrice } = params.row
            return <span className="font-medium text-lg">
                <Price price={specificPrice ?? defaultPrice} />
            </span>
        }
    }
] satisfies Array<GridColDef>


export default function PriceManagementPage() {
    const { locationId } = useParams()
    const { loading, error, data } = useQuery(LIST_LOCATION_PRICES_QUERY, {
        variables: locationId ? { locationId } : undefined,
        skip: !Boolean(locationId)
    })

    const [setPrice, { loading: settingPrice, }] = useMutation(SET_SPECIFIC_PRICE_MUTATION, {
        onCompleted: () => {
            // TODO: Show snackbar/toast
            console.log('Price was set!')
        },
        refetchQueries: [LIST_LOCATION_PRICES_QUERY]
    })

    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'productType', sort: 'asc' }])

    if (!loading && data) {

        const { locationById: location, pricesForLocation } = data

        return <>
            <AppBar position="static">
                <Typography className='py-2 px-4'>
                    Gestiona los precios para "{location.name}"
                </Typography>
            </AppBar>
            {settingPrice && <LinearProgress />}
            <BDGDataGrid
                loading={loading}
                editMode='cell'
                getRowId={(row) => row.product.id}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                rows={pricesForLocation.map(it => ({ ...it, productType: it.product.productType }))}
                processRowUpdate={(newRow, oldRow) => {
                    const { product, specificPrice } = newRow

                    // If both falsy values, do nothing
                    if (!newRow.specificPrice && !oldRow.specificPrice) {
                        return oldRow
                    }

                    const specificPriceAsCents = (() => {
                        if (specificPrice) {
                            //convert the thing to cents
                            return Math.floor(specificPrice * 100)
                        }
                        return 0
                    })()

                    setPrice({
                        variables: {
                            specificPrice: {
                                locationId: locationId!,
                                productId: product.id,
                                // left falsies intentionally as a 0 or NaN value should clear the input
                                price: specificPriceAsCents
                            }
                        }
                    })

                    return { ...newRow, specificPrice: specificPriceAsCents }
                }}
                columns={columns}
            />
        </>
    }


    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <LinearProgress />

}