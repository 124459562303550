import React from 'react'
import { useNavigate } from 'react-router-dom'
import SuppliedLocationList from '../../components/SuppliedLocationsList'

export default function ChooseLocationForPriceManagementPage() {
    const navigate = useNavigate()
    return <SuppliedLocationList
        onItemClick={loc => navigate(`${loc.id}`)}
        onNoItemsClick={() => navigate(-1)}
    />
}