import React from "react"
import { Autocomplete, FormControlProps, TextField, createFilterOptions } from "@mui/material"
import { SelectOption, SelectProps } from "./Select"

export default function SelectWithAutocomplete<T extends SelectOption>({
    disabled,
    label,
    name,
    selectedOption,
    values,
    setValue,
    error,
    helperText,
    variant = "outlined",
    className,
    fullWidth,
    sx
}: SelectProps<T> & FormControlProps) {
    const handleChange = (_event: React.ChangeEvent<{}>, newOption: SelectOption) => {
        const selectedValue = values.find(({ value }) => `${value}` === newOption?.value) ?? null
        return setValue(selectedValue)
    }
    return (
        <Autocomplete
            disabled={disabled}
            id={name}
            options={values}
            getOptionLabel={(option: SelectOption) => option.label}
            filterOptions={createFilterOptions({
                ignoreCase: true,
                ignoreAccents: true
            })}
            value={selectedOption}
            onChange={handleChange}
            fullWidth={fullWidth}
            sx={sx}
            isOptionEqualToValue={(option: SelectOption, value: SelectOption) => option.value === value.value}
            className={className}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    )
}