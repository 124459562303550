import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import BottomSheetDialog from "../../bottom-sheet/BottomSheetDialog";
import LoadingButton from "../../loading-button";
import { useQuery } from "@apollo/client";
import { LIST_LOCATIONS_QUERY } from "../../../../network/locations/list-locations-query";
import Select, { SelectOption } from "../../Select";
import { Location, distributionPointToLocation, supplierToLocation } from "../../../../models/Location";
import { validationSchema, zodSchema } from "./user-location-form-helpers";
import { Department } from "../../../../__generated__/graphql";
import MultiSelect from "../../MultiSelect";
import { departments } from "../../departments";

const defaultInitialValues = {
    locationId: null,
    departments: [],
}


export interface UserLocationFormProps {
    open?: boolean
    loading?: boolean
    initialValues?: {
        locationId: string | null
        departments: Array<Department>
    },
    onSubmit?: (value: ReturnType<typeof zodSchema.parse>, resetForm: () => void) => void
    onClose: () => void
}

type FormWithDataProps = Pick<UserLocationFormProps, 'onSubmit' | 'initialValues' | 'loading' | 'onClose'> & {
    locationOptions: Array<SelectOption>
}

function FormWithData({
    loading,
    initialValues = defaultInitialValues,
    onSubmit,
    onClose,
    locationOptions
}: FormWithDataProps) {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form, helpers) => onSubmit?.(zodSchema.parse(form), helpers.resetForm)
    })


    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">

                <MultiSelect
                    name="departments"
                    label="Departamentos"
                    sx={{ minWidth: 150 }}
                    selectedOptions={departments.filter(it => {
                        return formik.values.departments.includes(it.value)
                    })}
                    options={departments}
                    error={formik.touched.departments && Boolean(formik.errors.departments)}
                    helperText={formik.touched.departments ? formik.errors.departments?.at(0) : ''}
                    onBlur={formik.handleBlur}
                    setOptions={options => formik.setFieldValue('departments', options.map(it => it.value))}
                />
                <Select
                    name="locationId"
                    label="Lugar de trabajo"
                    sx={{ minWidth: 150 }}
                    selectedOption={locationOptions.find(it => it.value === formik.values.locationId) ?? null}
                    values={locationOptions}
                    error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                    helperText={formik.touched.locationId ? formik.errors.locationId : ''}
                    onBlur={formik.handleBlur}
                    setValue={option => formik.setFieldValue('locationId', option?.value ?? null)}
                />
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        isLoading={loading}
                        disabled={!formik.isValid}
                        type="submit"
                        variant="contained">
                        {"Asignar lugar de trabajo"}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </form>
    )
}


export default function UserLocationForm({
    open = false,
    loading = false,
    initialValues,
    onSubmit,
    onClose
}: UserLocationFormProps) {

    const { loading: locationsLoading, data: locationsData } = useQuery(LIST_LOCATIONS_QUERY)

    const locations: Array<SelectOption> = useMemo(() => {

        const suppliers = (locationsData?.suppliers ?? []).map(supplierToLocation)
        const distributionPoints = (locationsData?.distributionPoints ?? []).map(distributionPointToLocation)
        const availableLocations: Array<Location> = [...suppliers, ...distributionPoints]
        return ([
            {
                label: 'Seleccione lugar de trabajo',
                value: null
            },
            ...availableLocations.map(it => ({
                label: it.name,
                value: it.id
            }))
        ])
    }, [locationsData])

    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {"Asignar lugar de trabajo"}
            </DialogTitle>
            {locationsLoading && <CircularProgress className="m-auto" />}
            {!locationsLoading && <FormWithData
                loading={loading}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onClose={onClose}
                locationOptions={locations}
            />}
        </BottomSheetDialog>
    );
}
