import { gql } from "../../__generated__/gql";

export const CREATE_USER_MUTATION = gql(`
    mutation CreateUser($newUser: CreateUser!) {
        createUser(newUser: $newUser){
            id
            displayName
            elevated
            deleted
        }
    }
`)