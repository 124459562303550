import { useMutation, useQuery } from "@apollo/client";
import { ArrowRight } from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import { Button, CircularProgress, Fab, LinearProgress } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { OrderStatus } from "../../../__generated__/graphql";
import { distributionPointToLocation, supplierToLocation } from "../../../models/Location";
import { LIST_LOCATIONS_QUERY } from "../../../network/locations/list-locations-query";
import { LIST_ADMIN_ORDERS_QUERY } from "../../../network/orders-overview/admin-orders-query";
import DeliveryDate from "../../components/DeliveryDate";
import FilterSheet from "../../components/FilterSheet";
import Select, { SelectOption } from "../../components/Select";
import BDGDataGrid from "../../components/data-grid/BDGDataGrid";
import { createdAtCell, orderNumberCell, orderStatusCell, updatedAtCell } from "../../components/data-grid/shared-cells";
import { orderStatusSelectOptions } from "../../components/order-status";
import { LocationFilter } from "../../components/reusable-filters/LocationFilter";
import { useSearchFilters } from "../../hooks/use-search-filters";
import { UPDATE_ORDER_STATUS_MUTATION } from "../../../network/orders-overview/update-order-status-mutation";
import { useNavigate } from "react-router-dom";



const staticColumns = [
    orderNumberCell,
    {
        ...orderStatusCell,
        renderEditCell: (params) => {
            const selectedOption = (
                orderStatusSelectOptions
                    .find(it => it.value === params.value)
                    ?? null
            )

            return (
                <div className="flex justify-center items-center px-2 py-2">
                    <Select
                        name="orderStatus"
                        label=""
                        size="small"
                        variant="outlined"
                        selectedOption={selectedOption}
                        values={orderStatusSelectOptions}
                        setValue={(option) => params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: option?.value
                        })}
                    />
                </div>
            )
        },
        editable: true
    },
    {
        flex: 1,
        field: 'toBeDeliveredOn',
        type: 'date',
        headerName: 'Fecha de entrega',
        renderCell: params =>
            params
                ? <DeliveryDate date={params.value} />
                : null

    },
    createdAtCell,
    updatedAtCell,
] satisfies GridColDef[]



function Filters({
    dialogOpen,
    setDialogOpen,
    clearFilters
}: any) {

    const { loading: locationsLoading, data: locationsData } = useQuery(LIST_LOCATIONS_QUERY)

    const availableLocations = useMemo(() => {
        const suppliers = (locationsData?.suppliers ?? []).map(supplierToLocation)
        const distributionPoints = (locationsData?.distributionPoints ?? []).map(distributionPointToLocation)
        return [...suppliers, ...distributionPoints]
    }, [locationsData])

    const locationOptions: Array<SelectOption> = useMemo(() => {
        return ([
            {
                label: 'Seleccione lugar de trabajo',
                value: null
            },

            ...availableLocations.map(it => ({
                label: it.name,
                value: it.id
            }))
        ])
    }, [availableLocations])

    return (
        <FilterSheet
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            dateRange
            orderStatus
            extraFilters={({ state, stateSetter }) => (<>
                {locationsLoading && <CircularProgress />}
                {!locationsLoading &&
                    <LocationFilter
                        state={state}
                        stateSetter={stateSetter}
                        locationOptions={locationOptions} />
                }
                <Button
                    color="warning"
                    variant="outlined"
                    onClick={clearFilters}>
                    Limpiar filtros
                </Button>
            </>)}
        />
    )
}




export default function AdminOrdersPage() {

    const [dialogOpen, setDialogOpen] = useState(false)
    const navigate = useNavigate()
    const [gridSortModel, setGridSortModel] = useState<GridSortModel | undefined>(undefined)

    const [params, setParams] = useSearchFilters({
        from: dayjs().startOf('week').toISOString(),
        to: dayjs().endOf('week').toISOString(),
    })

    const clearFilters = () => setParams({})

    const filters = useMemo(() => {

        const getOrUndefined = (key: string) => params.get(key) ?? undefined
        const getDateOrUndefined = (key: string) => {
            const datish = params.get(key)
            if (!datish) {
                return undefined
            }
            const d = dayjs(datish)

            if (d.isValid()) {
                return d.toDate()
            }

            return undefined
        }

        return {
            orderStatus: getOrUndefined('status') as (OrderStatus | undefined),
            from: getDateOrUndefined('from'),
            to: getDateOrUndefined('to'),
            location: getOrUndefined('location')
        }

    }, [params])

    const { loading: fetching, data } = useQuery(LIST_ADMIN_ORDERS_QUERY, {
        variables: {
            filters: {
                status: filters.orderStatus,
                toBeDeliveredBefore: filters.to,
                toBeDeliveredAfter: filters.from,
                locationId: filters.location
            }
        }
    })

    const [mutate, {loading: updating}] = useMutation(UPDATE_ORDER_STATUS_MUTATION, {
        refetchQueries: [LIST_ADMIN_ORDERS_QUERY]
    })

    const loading = fetching || updating
    const rows = data?.adminOrders ?? []
    const columns = useMemo(() => [
        ...staticColumns,
        {
            field: 'actions',
            headerName: 'Acciones',
            type: 'actions',
            width: 100,
            getActions: (params: GridRowParams) =>
                params
                    ? ([
                        <GridActionsCellItem
                            icon={<ArrowRight />}
                            onClick={() => navigate(`./${params.row.orderNumber}`)}
                            label="Ver detalles del pedido" />,
                    ])
                    : []
        }
    ] satisfies Array<GridColDef>, [navigate])
    return (
        <>
            <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />

            <BDGDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                sortModel={gridSortModel}
                onSortModelChange={(mode) => setGridSortModel(mode)}
                processRowUpdate={async (newRow, oldRow) => {
                    const { status, orderNumber } = newRow
                    try {
                        await mutate({
                            variables: {
                                orderNumber,
                                status
                            }
                        })
                        return newRow
                    } catch(error) {
                        console.error('Error updating order status', error)
                        return oldRow
                    }
                }}
                getRowId={(row) => row.orderNumber}
            />

            <Fab
                color="success"
                size='large'
                disabled={loading}
                style={{ position: 'fixed', bottom: 64, right: 32 }}
                onClick={() => setDialogOpen(true)}>
                <TuneIcon />
            </Fab>

            <Filters
                dialogOpen={dialogOpen}
                clearFilters={clearFilters}
                setDialogOpen={setDialogOpen}
            />

        </>
    );
}


