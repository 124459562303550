import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Button, CircularProgress, DialogTitle, Fab, LinearProgress, Typography } from '@mui/material';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IcecreamGroup as IcecreamGroupEnum, ProductType } from '../../../__generated__/graphql';
import { ALL_PRODUCTS_QUERY } from '../../../network/all-products-query';
import { GET_PRODUCTS_FOR_SORTING_GROUP } from '../../../network/icecream-groups/get-sorting-products-for-group-query';
import IcecreamGroup from '../../components/IcecreamGroup';
import { SelectOption } from '../../components/Select';
import SelectWithAutocomplete from '../../components/SelectWithAutocomplete';
import BottomSheetDialog from '../../components/bottom-sheet/BottomSheetDialog';
import BDGDataGrid from '../../components/data-grid/BDGDataGrid';
import EmptyRows from '../../components/data-grid/empty-rows';
import { useIcecreamGroupOperations } from '../../hooks/use-icecream-group-operations';
import DeleteIcon from '@mui/icons-material/Delete'


function useSelectedGroup() {
    const navigate = useNavigate()
    const { group } = useParams()

    switch (group) {
        case IcecreamGroupEnum.Creams:
            return IcecreamGroupEnum.Creams
        case IcecreamGroupEnum.Fruits:
            return IcecreamGroupEnum.Fruits
        default:
            navigate(IcecreamGroupEnum.Fruits)
            return IcecreamGroupEnum.Fruits
    }
}

function Topbar({ group }: any) {
    return (
        <AppBar position="static">
            <Typography className='py-2 px-4'>
                Gestionando la ordenación de los productos pertenecientes a <IcecreamGroup group={group} />
            </Typography>
        </AppBar>
    )
}

function ChooseProductBottomSheet({ open, onClose, onSelect }: any) {
    const { loading, data } = useQuery(ALL_PRODUCTS_QUERY, {
        variables: {
            productType: ProductType.Icecream
        }
    })

    const [selectedProduct, setSelectedProduct] = useState<SelectOption | null>(null)
    const productsAsValues = useMemo(() => {
        return (data?.rawProducts ?? []).filter(it => !it.deleted).map((product) => ({
            label: product.name,
            value: product.id
        }))
    }, [data?.rawProducts])
    return (
        <BottomSheetDialog
            open={open}
            onClose={onClose}>

            <DialogTitle className="text-center">
                ¿Qué producto quieres añadir?
            </DialogTitle>

            {loading && !data && <CircularProgress />}

            <div className="flex flex-col w-full gap-4 px-6 pb-24">
                <SelectWithAutocomplete
                    label="Producto"
                    name="productId"
                    values={productsAsValues}
                    selectedOption={selectedProduct}
                    setValue={(option) => setSelectedProduct(option ?? null)}
                />
            </div>
            <div className="flex flex-row my-4 gap-2 px-6">
                <Button fullWidth variant="outlined" onClick={onClose}>
                    Cancelar
                </Button>
                <Button fullWidth variant="contained" onClick={() => {
                    if (!selectedProduct) {
                        return
                    }
                    setSelectedProduct(null)
                    onSelect(selectedProduct.value)
                }}>
                    Seleccionar
                </Button>
            </div>
        </BottomSheetDialog>
    )
}



export default function IcecreamGroupSortingPage() {
    const icecreamGroup = useSelectedGroup()
    const [showSelectProduct, setShowSelectProduct] = useState(false)
    const { loading, data } = useQuery(GET_PRODUCTS_FOR_SORTING_GROUP, {
        variables: { icecreamGroup }
    })

    const { setGroup, removeFromGroup, loading: mutating } = useIcecreamGroupOperations({
        refetchQueries: [GET_PRODUCTS_FOR_SORTING_GROUP]
    })

    const columns = [
        {
            flex: 1,
            headerName: 'Nombre del producto',
            headerAlign: 'left',
            align: 'left',
            field: 'productName',
            type: 'string',
        },
        {
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            headerName: 'Posición dentro del grupo',
            field: 'position',
            type: 'number',
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            getActions: (params: any) => ([
                <GridActionsCellItem
                    label="Delete"
                    icon={<DeleteIcon />}
                    onClick={ev => {
                        ev.stopPropagation()
                        ev.preventDefault()
                        
                        removeFromGroup({
                            variables: {
                                productID: params.row.productId,
                            }
                        })
                    }}/>,
            ])
        }
    ] satisfies Array<GridColDef>
    return <>
        <Topbar group={icecreamGroup} />

        <LinearProgress
            sx={{ opacity: (loading || mutating) ? 1 : 0 }} />

        <BDGDataGrid
            columns={columns}
            slots={{
                noRowsOverlay: () => (
                    <EmptyRows labelText='No hay productos en este grupo'>
                        <Button variant="contained" onClick={() => setShowSelectProduct(true)}>
                            <AddIcon />
                            Añadir producto
                        </Button>
                    </EmptyRows>
                )
            }}
            loading={loading}
            rows={data?.productsWithSorting ?? []}
            getRowId={row => row.productId}
            processRowUpdate={async (newRow, oldRow) => {
                try {
                    await setGroup({
                        variables: {
                            args: {
                                group: icecreamGroup,
                                productId: newRow.productId,
                                position: Math.max(
                                    Math.trunc(newRow.position),
                                    1
                                )
                            }
                        }
                    })
                    return newRow
                } catch(error) {
                    console.log('Error onprocessRowUpdate', { error })
                    return oldRow
                }
            }}
        />

        <ChooseProductBottomSheet
            open={showSelectProduct}
            onSelect={(productId: string) => {
                setGroup({
                    variables: {
                        args: {
                            group: icecreamGroup,
                            productId,
                        }
                    }
                })
                setShowSelectProduct(false)
            }}
            onClose={() => setShowSelectProduct(false)}
        />
        <Fab
            size='large'
            color='primary'
            style={{ position: 'fixed', bottom: 52, right: 52 }}
            onClick={() => setShowSelectProduct(true)}>
            <AddIcon />
        </Fab>
    </>
}