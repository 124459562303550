import { useMutation, useQuery } from '@apollo/client';
import { LocalShipping } from '@mui/icons-material';
import { Button, CircularProgress, DialogContent, DialogTitle, Fab, LinearProgress, List, Typography, } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { DELIVER_BATCH_MUTATION } from '../../../network/delivery-batches/deliver-batch-mutation';
import { GET_DELIVERY_BATCH } from '../../../network/delivery-batches/get-delivery-batch-query';
import { GET_PENDING_DELIVERY_BATCHES } from '../../../network/delivery-batches/get-pending-delivery-batches-query';
import BottomSheetDialog from '../../components/bottom-sheet/BottomSheetDialog';
import DeliveryFormComponent from '../../components/delivery/DeliveryFormComponent';
import { GetDeliveryBatchQuery } from '../../../__generated__/graphql';
import ErrorMessage from '../../components/error-message';
import { OrderMeta, OrderObservations } from '../../components/OrderDetails';
import { ProductListPadding } from '../ProductListPadding';
import { ProductAmount } from '../../components/ProductListItem';

type DeliveryBatch = GetDeliveryBatchQuery['deliveryBatch']

interface DeliveryBatchDetailsProps {
    loading?: boolean
    data?: DeliveryBatch
    error?: {
        message: string
    }
    children?: React.ReactNode
}

type DeliveryBatchDetailsContentOptions = {
    products: DeliveryBatch['products']
    order: DeliveryBatch['order']
}

function EmptyList() {
    return (
        <div className="flex flex-row justify-center py-16">
            <Typography variant="h5">
                Esta lista está vacía
            </Typography>
        </div>
    )
}

function DeliveryBatchProducts({ products }: { products: DeliveryBatch['products'] }) {
    const lastIndex = products.length - 1
    if (products.length === 0) {
        return <EmptyList />
    }
    return (
        <List component="div" role="list" sx={{ width: "100%" }} disablePadding>
            {products.map(({ id, name, productType, amount }, index) => {
                return (
                    <ProductAmount
                        key={id}
                        name={name}
                        isLast={index === lastIndex}
                        amount={amount}
                        productType={productType} />
                )
            })}
        </List>
    )
}


function DeliveryBatchDetailsContent({ products, order, }: DeliveryBatchDetailsContentOptions) {
    return (
        <div className="flex flex-col w-full gap-12 sm:gap-8 px-4 sm:px-2">
            <OrderMeta 
                prefix="Lote de entrega para pedido "
                order={order} />
            <OrderObservations observations={order.observations} />
            <section className="flex flex-col gap-2">
                <Typography variant="h5">
                    Productos
                </Typography>
                <DeliveryBatchProducts products={products} />
                {/* Quick workaround to get some padding  */}
                <ProductListPadding />
                <ProductListPadding />
            </section>
        </div>
    )
}

function DeliveryBatchDetails({
    loading,
    data,
    error,
    children,
}: DeliveryBatchDetailsProps) {
   
    return (
        <>
            <LinearProgress style={{ opacity: loading ? 1 : 0 }} />
            {loading && <div className="w-50 h-50 self-center">
                <CircularProgress className="self-center" />
            </div>}
            <section
                className="flex flex-col w-full items-center mt-8 ">
                <div className="flex flex-col w-full max-w-4xl gap-4">
                    {!loading && error && <ErrorMessage description={error.message}>
                        <Button fullWidth component={Link} to="../" >
                            Volver atrás
                        </Button>
                    </ErrorMessage>}
                    {!loading && data && (
                        <DeliveryBatchDetailsContent 
                            order={data.order} 
                            products={data.products}
                        />
                    )}
                    {children}
                </div>
            </section>
        </>
    )
}

export default function DeliverBatchPage() {
    const { batchId } = useParams()
    const navigate = useNavigate()
    const [showDeliveryForm, setShowDeliveryForm] = useState(false)
    const onClose = () => setShowDeliveryForm(false)

    const { loading, data, error } = useQuery(GET_DELIVERY_BATCH, {
        variables: {
            id: batchId!
        },
        skip: !batchId
    })

    const [mutate, { loading: updating }] = useMutation(DELIVER_BATCH_MUTATION, {
        onCompleted: () => {
            onClose()
            navigate(-1)
        },
        refetchQueries: [GET_PENDING_DELIVERY_BATCHES]
    })

    return (
        <>
            {updating && <LinearProgress />}
            <BottomSheetDialog
                open={showDeliveryForm && batchId !== undefined}
                keepMounted={false}
                onClose={onClose}>
                <DialogTitle className="text-center">
                    Información de entrega
                </DialogTitle>
                <DialogContent>
                    <DeliveryFormComponent
                        orderNumber={data?.deliveryBatch?.orderNumber?? Number.NaN}
                        onClose={onClose}
                        onSubmit={(values) => {
                            const id = batchId
                            if(!id) {
                                return
                            } 
                            mutate({
                                variables: {
                                    delivery: {
                                        id,
                                        markOrderAsDelivered: values.completeDelivery,
                                        receiverName: values.receiverName,
                                    }
                                }
                            })
                        }} />

                </DialogContent>
            </BottomSheetDialog>

            {/* We can skip checking the session because we're in the DeliverOrderPage */}
            <DeliveryBatchDetails 
                loading={loading} 
                data={data?.deliveryBatch} 
                error={error}>
                <Fab
                    color="success"
                    size='large'
                    disabled={updating}
                    style={{ position: 'fixed', bottom: 32, right: 32}}
                    onClick={() => setShowDeliveryForm(true)}
                    variant="extended">
                    <LocalShipping  />
                    <span className="px-1"></span>
                    Realizar entrega
                </Fab>
            </DeliveryBatchDetails>
        </>
    )
}