import { gql } from "../../__generated__/gql";

export const LIST_TRAYS_QUERY = gql(/* GraphQL */`
    query ListTrays {
        trays {
            id
            name
            grams
            createdAt
            updatedAt
        }    
    }
`)