import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    List,
    ListItem,
    Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentOrder } from "../../context/current-order";
import { useMutation, useQuery } from '@apollo/client';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from 'react-router-dom';
import { PLACE_ORDER_MUTATION } from '../../../network/place-order-mutation';
import { ProductAmount } from '../../components/ProductListItem';
import { ProductListPadding } from '../ProductListPadding';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { MY_ORDERS_QUERY } from '../../../network/my-orders-query';
import { APP_SETTINGS_QUERY } from '../../../network/app-settings-query';
import { useFormik } from 'formik';
import BottomSheetDialog from '../../components/bottom-sheet/BottomSheetDialog';
import { isValidDate } from '../../../network/gql-links/scalars';
import LoadingButton from '../../components/loading-button';

interface OnSubmitValues { observations: string, pickupDate: Date }

interface CheckoutFormProps {
    open?: boolean
    onSubmit: (values: OnSubmitValues) => void
    onClose: () => void
}


function getMinimumAllowedDate() {
  // If it's before 1:30 AM, we can still choose the current day
  // If it's any later, we fallback to next day (tomorrow)
  const todayAt130 = dayjs()
    .startOf('day')
    .add(1, 'hours')
    .add(30, 'minutes')
  const now = dayjs()
  return (
    now.isBefore(todayAt130) 
        // Use start of the day
        ? dayjs().startOf('day') 
        : now.add(1, 'day').startOf('day')
    )
}


function CheckoutForm({ open = false, onSubmit, onClose }: CheckoutFormProps) {

    const tomorrow = useMemo(() => dayjs().add(1, 'day').startOf('day'), [])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const formik = useFormik({
        initialValues: {
            pickupDate: getMinimumAllowedDate(),
            observations: ''
        },
        onSubmit: ({ pickupDate, observations }) => {
            setIsSubmitting(true)
            onSubmit({
                pickupDate: pickupDate.toDate(),
                observations
            })
        }
    })

    const handleDateChange = (date: Dayjs | null) => {
        if (!isValidDate(date)) {
            alert('Selecciona una fecha válida')
            return
        }
        if (!date || date.isBefore(tomorrow, "day")) {
            alert('Selecciona una fecha de entrega posterior a hoy');
            return;
        }
        formik.setFieldValue('pickupDate', date)
    }

    // Reset the form whenever we toggle the dialog
    useEffect(() => {
        setIsSubmitting(false)
        formik.resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <BottomSheetDialog open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                Finalizar pedido
            </DialogTitle>
            <form className='flex flex-col w-full px-4' onSubmit={formik.handleSubmit}>
                <DialogContent className="flex flex-col w-full">
                    <Typography className="b-2" variant='subtitle1'>Seleccionar fecha de entrega</Typography>
                    <DatePicker
                        className="!mt-4"
                        label="Día de entrega"
                        value={formik.values['pickupDate']}
                        onChange={handleDateChange}
                        shouldDisableDate={(date) => date.isBefore(getMinimumAllowedDate(), "day")}
                    />

                    <FormControl className="!mt-4">
                        <FormLabel>Observaciones</FormLabel>
                        <textarea
                            className='mt-2 p-2 rounded-lg border border-gray'
                            style={{ resize: 'none' }}
                            rows={6}
                            placeholder='Tu mensaje aquí…'
                            value={formik.values.observations}
                            onChange={(ev) => formik.setFieldValue('observations', ev.target.value)}
                        />
                        <FormHelperText error>{formik.errors.observations}</FormHelperText>
                    </FormControl>
                </DialogContent>


                <DialogActions className="flex flex-row w-full !my-4 ">
                    <Button fullWidth variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>
                    <LoadingButton fullWidth isLoading={isSubmitting} variant="contained" disabled={!formik.isValid} type="submit">
                        Confirmar
                    </LoadingButton>
                </DialogActions>
            </form>
        </BottomSheetDialog>
    )

}



export default function CheckoutPage() {
    const { order, removeFromOrder, clearOrder } = useCurrentOrder()
    const products = useMemo(() => Array.from(order.values()), [order])
    const navigate = useNavigate()

    const [showForm, setShowForm] = useState(false)

    const { data: settings } = useQuery(APP_SETTINGS_QUERY)
    const canMakeOrders = settings?.appSettings.canMakeOrders ?? true
    const outsideWorkingHours = !canMakeOrders


    const [mutate, { loading }] = useMutation(PLACE_ORDER_MUTATION, {
        onCompleted: (data) => {
            console.log('onCompleted', data)
            // Show toast
            alert(`¡Tu pedido está en camino!`)
            clearOrder()
            navigate('/')
        },
        onError: (err) => {
            // Show toast
            alert(err.message)
        },
        refetchQueries: [MY_ORDERS_QUERY]
    })
    const onSubmit = ({ pickupDate, observations }: OnSubmitValues) => {
        const trimmed = observations.trim()
        mutate({
            variables: {
                order: {
                    toBeDeliveredOn: pickupDate,
                    observations: trimmed ? trimmed : undefined,
                    products: products.map(it => ({
                        productId: it.product.id,
                        amount: it.amount
                    }))
                }
            }
        })
    }

    return (
        <div className="flex flex-col w-full h-full p-4">
            <Typography variant='h6'>
                Tu selección de productos
            </Typography>
            <List>
                {products.map(({ product, amount }) => (
                    <ListItem key={product.id} >
                        <ProductAmount name={product.name} amount={amount} />
                        <span className='px-2'></span>
                        <IconButton color="error" onClick={() => removeFromOrder(product.id, amount)}>
                            <RemoveCircleIcon />
                        </IconButton>
                    </ListItem>
                ))}
                <ProductListPadding />
            </List>
            <CheckoutForm
                open={showForm}
                onSubmit={onSubmit}
                onClose={() => setShowForm(false)} />
            <Fab
                color="success"
                size='large'
                disabled={loading || outsideWorkingHours}
                variant={loading ? 'circular' : 'extended'}
                style={{ position: 'fixed', bottom: 24, right: 24 }}
                onClick={() => setShowForm(true)}
            >
                {loading && <CircularProgress color="success" />}
                {!loading && outsideWorkingHours && <> {"No es posible realizar pedidos fuera del horario de trabajo"} </>}
                {!loading && !outsideWorkingHours && <><ShoppingCartCheckoutIcon className='mr-2' />
                    {"Confirmar y enviar"}
                </>}
            </Fab>
        </div>
    );
}