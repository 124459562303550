import { useMutation, useQuery } from '@apollo/client'
import { DoneAll } from '@mui/icons-material'
import { IconButton, LinearProgress } from '@mui/material'
import { GridCellParams, GridColDef, GridFilterModel, GridFooterContainer, GridRenderCellParams, GridRenderEditCellParams, GridTreeNode, useGridApiContext, useGridApiRef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { GetPreparationTableQuery } from '../../../__generated__/graphql'
import { GET_PREPARATION_TABLE_QUERY } from '../../../network/get-preparation-table-query'
import { UPDATE_PREPARATION_TABLE_MUTATION } from '../../../network/update-preparation-table-mutation'
import AppBarSearch from '../../components/AppBarSearch'
import SelectProductType from '../../components/SelectProductType'
import AmountReadyCheckboxes from '../../components/data-grid/AmountReadyCheckboxes'
import BDGDataGrid from '../../components/data-grid/BDGDataGrid'
import { usePreferredProductType } from '../../hooks/chamber/use-preferred-productype'


interface CellState {
    productId: string
    locationId: string
    readyAmount: number
    requestedAmount: number
}

type UUID = ReturnType<typeof crypto.randomUUID>
// Beware that some locationIds MIGHT not have a cell state and therefore should be undefined
type LocationIdCellStateAssoc = Record<UUID, CellState>

type Row = LocationIdCellStateAssoc & {
    productName: string
    productId: string
    stock: number
}

function mapRows(
    rows: GetPreparationTableQuery['preparatorTable']['rows'] = []
) {
    return rows.map(row => ({
        productName: row.product.name,
        productId: row.product.id,
        stock: row.product.stock,
        // Add the the corresponding mappings of the cell state for each location id
        // [location1]: { readyAmount: 1, requestedAmount: 2 }
        // [location2]: undefined
        ...row.cells.reduce((acc, cell) => {
            return {
                ...acc,
                [cell.locationId]: {
                    productId: row.product.id,
                    locationId: cell.locationId,
                    readyAmount: cell.readyAmount,
                    requestedAmount: cell.requestedAmount
                } satisfies CellState
            } as const satisfies LocationIdCellStateAssoc
        }, {} satisfies LocationIdCellStateAssoc)
    } satisfies Row))
}


function getCellState({ params, locationId }: {
    params: GridCellParams<any, any, any, GridTreeNode>,
    locationId: string
}) {
    const cellStateOrUndefined = params.row[locationId];
    const hasValue = cellStateOrUndefined != null

    if (hasValue && 'readyAmount' in cellStateOrUndefined && 'requestedAmount' in cellStateOrUndefined) {
        return cellStateOrUndefined as CellState
    }
    return undefined
}

interface TableCellComponentProps {
    params: GridRenderEditCellParams
    locationId: string
    edit?: boolean
}

function TableCellComponent({
    edit = false,
    params,
    locationId
}: TableCellComponentProps) {
    const apiRef = useGridApiContext();

    const cellStateOrUndefined = getCellState({ params, locationId })
    if (cellStateOrUndefined == null) {
        return null
    }
    const { readyAmount, requestedAmount, } = cellStateOrUndefined
    return (
        <AmountReadyCheckboxes
            disabled={!edit}
            readyAmount={readyAmount}
            requestedAmount={requestedAmount}
            onChange={(checkedAmount) => {
                apiRef.current?.setEditCellValue({
                    id: params.id,
                    field: locationId,
                    value: { ...cellStateOrUndefined, readyAmount: checkedAmount },
                    debounceMs: 1000
                })
            }}
        />
    )
}



function asColumns(
    headers: GetPreparationTableQuery['preparatorTable']['headers'] = []
) {
    const locationHeaders = headers.map(header => ({
        field: header.locationId,
        headerName: header.locationName,
        type: 'custom',
        headerAlign: 'center',
        editable: true,
        cellClassName: (params) => {
            const cellStateOrUndefined = getCellState({ params, locationId: header.locationId })
            if (cellStateOrUndefined != null) {
                const { readyAmount, requestedAmount } = cellStateOrUndefined
                if (readyAmount === requestedAmount) {
                    return 'text-white bg-primary'
                }
            }

            return 'text-gray-500'
        },
        // Only draw checkboxes to those boxes that have a value.
        // Backend does not send a full matrix, only the cells that have a "ready"
        renderEditCell: (params: GridRenderEditCellParams) => (
            <TableCellComponent
                edit
                params={params}
                locationId={header.locationId}
            />
        ),
        renderCell: (params: GridRenderCellParams) => {
            return <TableCellComponent
                params={params}
                locationId={header.locationId}
            />
        }
        ,
    } satisfies GridColDef))

    return [
        {
            field: 'productName',
            headerName: 'Producto',
            type: 'string',
            renderCell: params => {
                if (!params) {
                    return null
                }
                const completeEverything = () => {
                    // Hacky way to get the all locations
                    const uuidLength = crypto.randomUUID().length
                    const locations = Object
                        .keys(params.row)
                        .filter(it => it.length === uuidLength)
                        .map(key => params.row[key]) as Array<CellState>


                    // Commplete them all
                    // Pushes an update to the row, 
                    // but it doesn't trigger the processRowUpdate callback...
                    params.api.updateRows([
                        {
                            ...params.row,
                            ...locations.reduce((acc, curr) => {
                                return {
                                    ...acc,
                                    [curr.locationId]: { ...curr, readyAmount: curr.requestedAmount }
                                }
                            }, {} as Record<string, CellState>)
                        }
                    ])

                    if (locations.length === 0) {
                        return
                    }

                    // So we fake user edition
                    const singleCell = {
                        id: params.id,
                        // Note that we checked for locations.length > 0
                        field: locations[0].locationId
                    }
                    try {
                        params.api.startCellEditMode(singleCell)
                        queueMicrotask(() => {
                            try {
                                params.api.stopCellEditMode(singleCell)
                            } catch (error) {
                                console.error('Failed to stop cell edit mode', { error })
                            }
                        })
                    } catch (error) {
                        console.error('Failed to start cell edit mode', { error })
                    }
                }

                return (
                    <div className="flex flex-row items-center gap-2">
                        <IconButton
                            color="success"
                            onClick={completeEverything}
                            title='Marcar todo como listo'>
                            <DoneAll />
                        </IconButton>
                        <span className="max-h-[24px] rounded-xl text-sm text-white px-2 py-[0.1rem] bg-blue-600">
                            {params.row.stock}
                        </span>
                        {params.row.productName}
                    </div>
                )

            }
        },
        ...locationHeaders
    ] satisfies Array<GridColDef>

}


function asGridFilterModel(search: string): GridFilterModel {
    return {
        items: [
            {
                field: 'productName',
                value: search,
                operator: 'contains'
            }
        ]
    }
}

export default function PreparationTablePage() {
    const [search, setSearch] = useState('')
    const size = useWindowSize()
    const filterModel = asGridFilterModel(search)
    // Consider using different keys on localStorage
    const [productType, setProductType] = usePreferredProductType()
    const apiRef = useGridApiRef();
    const today = useMemo(() => dayjs().startOf('day').toDate(), [])
    const { loading: fetching, data } = useQuery(GET_PREPARATION_TABLE_QUERY, {
        variables: {
            productType: productType?.value ?? undefined,
            date: today
        }
    })
    // Autosize can only be done after a render has happenned so we can measure how much each column
    // should really occupy.
    // Therefore after each successful data fetch we should autosize the columns.
    useEffect(() => {
        const current = apiRef.current
        if (data && current) {
            const handle = setTimeout(() => current.autosizeColumns({
                expand: true,
                includeOutliers: true,
                includeHeaders: true,
            }), 0)
            return () => {
                clearTimeout(handle)
            }
        }
        return () => { }
    }, [data, apiRef, size])

    const [mutation, { loading: updating }] = useMutation(UPDATE_PREPARATION_TABLE_MUTATION, {
        refetchQueries: [GET_PREPARATION_TABLE_QUERY]
    })
    const loading = fetching || updating
    const columns = asColumns(data?.preparatorTable?.headers)
    const rows = useMemo(() => mapRows(data?.preparatorTable?.rows),[data?.preparatorTable?.rows])
    return (
        <>
            <AppBarSearch onSearchChange={setSearch} />
            <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
            <BDGDataGrid
                apiRef={apiRef}
                filterModel={filterModel}
                loading={loading}
                showCellVerticalBorder
                showColumnVerticalBorder
                rows={rows}
                getRowId={(row) => row.productId}
                columns={columns}
                autosizeOptions={{
                    expand: true,
                    includeOutliers: true,
                    includeHeaders: true,
                }}
                slots={{
                    footer: () => {
                        return <GridFooterContainer sx={{
                            justifyContent: 'end',
                            gap: 2,
                            px: 2,
                        }}>
                            <div className="flex flex-row gap-1 items-center">

                                <span className="h-[12px] w-[12px] rounded-full text-sm text-white bg-blue-600">
                                    &nbsp;
                                </span>
                                Cantidad disponible en cámara
                            </div>

                            <SelectProductType
                                includeAll
                                label='Tipo producto'
                                selectedOption={productType}
                                onChange={(option) => setProductType(option)}
                            />
                        </GridFooterContainer>
                    }
                }}
                processRowUpdate={async (newRow, oldRow) => {
                    console.log('processRowUpdate', {
                        entries: Object.entries(newRow),
                    })
                    const changes = Object
                        .entries(newRow)
                        .filter((entry: any): entry is [string, CellState] => {
                            const [key, value] = entry as [string, any]
                            // Entries contain "productId" and "productName" keys; which we ought to filter out
                            // Entries we want have the following shape (Cell row values):
                            // [
                            //     "d6d436d4-e52e-4178-93cb-009e81686732",
                            //     {
                            //       "productId": "4aeeccb5-e132-47f7-a33b-04b7554a89a4",
                            //       "locationId": "d6d436d4-e52e-4178-93cb-009e81686732",
                            //       "readyAmount": 0,
                            //       "requestedAmount": 3
                            //     }
                            // ]
                            const hasPropOfType = (value: any, prop: string, type: 'string' | 'number') => (
                                prop in value && typeof value[prop] === type
                            )
                            const isEditableCell = (value: any): value is CellState => {
                                return typeof value === 'object' && (
                                    hasPropOfType(value, 'productId', 'string') &&
                                    hasPropOfType(value, 'locationId', 'string') &&
                                    hasPropOfType(value, 'readyAmount', 'number') &&
                                    hasPropOfType(value, 'requestedAmount', 'number')
                                )
                            }
                            return typeof key === 'string' && isEditableCell(value)
                        })
                        .reduce((acc, [_locationId, cellState]) => {
                            return [...acc, {
                                productId: cellState.productId,
                                locationId: cellState.locationId,
                                readyAmount: cellState.readyAmount,
                            }]
                        }, [] satisfies CellState[])

                    try {
                        const ok = await mutation({
                            variables: {
                                updates: changes,
                                date: today
                            }
                        })

                        return ok ? newRow : oldRow
                    } catch (error: any) {
                        console.error("UPDATE_PREPARATION_TABLE_MUTATION failed", {
                            error
                        })
                        return oldRow
                    }

                }}
            />
        </>
    )
}