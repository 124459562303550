import React from "react"
import { useNavigate } from "react-router-dom"
import SuppliedLocationList from '../../components/SuppliedLocationsList'

// Choose a store and redirect
export default function ChooseLocationForRefunds() {
    const navigate = useNavigate()
    return <SuppliedLocationList
        onItemClick={loc => navigate(`${loc.id}`)}
        onNoItemsClick={() => navigate(-1)}
    />
}