import { gql } from "../__generated__/gql";


export const PLACED_ORDERS_BY_DATE_QUERY = gql(/* GraphQL */`
    query PlacedOrdersByDate($filters: DateFilters!) {
        placedOrders(filters: $filters) {
            orderNumber
            observations
            createdAt
            updatedAt
            status
        }
    }
`)