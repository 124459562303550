import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { FilterSlotProps } from '../FilterSheet';

export function QuickFilters({ stateSetter }: FilterSlotProps) {
    return (
        <section>
            <Typography className="pb-1" variant='subtitle1'>Acciones rápidas</Typography>
            <div className='flex flex-row flex-wrap justify-around gap-x-1'>
                <Button onClick={() => {
                    stateSetter.set('from', dayjs().startOf('day').toISOString())
                    const endOfToday = dayjs().endOf('day')
                    stateSetter.set('to', endOfToday.toISOString())
                }}>
                    Hoy
                </Button>
                <Button onClick={() => {
                    const today = dayjs().startOf('day')
                    const tomorrow = today.add(1, 'day').endOf('day')
                    stateSetter.set('from', today.toISOString())
                    stateSetter.set('to', tomorrow.toISOString())
                }}>
                    Hoy y mañana
                </Button>

                <Button
                    color="primary"
                    onClick={() => {
                        const today = dayjs().startOf('day')
                        const nextWeek = today.add(7, 'day').endOf('day')
                        stateSetter.set('from', today.toISOString())
                        stateSetter.set('to', nextWeek.toISOString())
                    }}
                >
                    Próximos 7 días
                </Button>

                <Button color="warning" onClick={() => stateSetter.reset()}>
                    Limpiar filtros
                    <span className="px-1"></span>
                    <DeleteSweepIcon />
                </Button>
            </div>
        </section>
    )
}