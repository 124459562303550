import { gql } from "../../__generated__/gql";

export const CREATE_LOCATION_MUTATION = gql(`
    mutation CreateLocation($newLocation: NewLocation!) {
        createLocation(newLocation: $newLocation) {
            ...CommonLocation
        }
    }
`)

export const UPDATE_LOCATION_MUTATION = gql(`
    mutation UpdateLocation($update: LocationUpdate!) {
        updateLocation(update: $update) {
            ...CommonLocation
        }
    }
`)

export const DELETE_LOCATION_MUTATION = gql(`
    mutation DeleteLocation($id: ID!) {
        deleteLocation(id: $id) {
            ...CommonLocation
        }
    }
`)