import { z } from "zod"
import { ProductType } from "../../../__generated__/graphql"
import { validationFrom } from "../../forms/validation"
import { IcecreamGroups } from "../IcecreamGroup"

export const productTypes = [
    ProductType.Accessories,
    ProductType.Bakery,
    ProductType.BeveragesAndSlushies,
    ProductType.Icecream,
    ProductType.Other,
    ProductType.Pastry,
    ProductType.RawMaterials,
    ProductType.VariousFood
] as const

export const icecreamGroups = [
    IcecreamGroups.Creams,
    IcecreamGroups.Fruits,
] as const

export function formatProductType(type:ProductType|null) {
    if(!type)  {
        return 'Ninguno'
    }

    const map = new Map([
        [ProductType.Accessories, 'Accesorios'],
        [ProductType.Bakery, 'Panadería'],
        [ProductType.BeveragesAndSlushies, 'Bebidas y granizados'],
        [ProductType.Icecream, 'Helado'],
        [ProductType.Pastry, 'Pastelería'],
        [ProductType.RawMaterials, 'Materia prima'],
        [ProductType.VariousFood, 'Varios (comida)'],
        [ProductType.Other, 'Otros'],
    ])
    return map.get(type) ?? `Desconocido (${type})`
}

export const productTypeSelectOptions = [null, ...productTypes].map(value => ({
    label: formatProductType(value),
    value: value
}))



export const zodSchema = z.object({
    name: z.string({ required_error: 'Éste campo es obligatorio' }),
    returnable: z.boolean(),
    trayId: z.string().nullable(),
    icecreamGroup: z.enum(icecreamGroups, {
        errorMap: () => ({message: 'Selecciona un grupo de helado'})
    }).nullable(),
    productType: z.enum(productTypes, {
        errorMap: () => ({message: 'Selecciona un tipo de producto'})
    }),
})

export const validationSchema = validationFrom(zodSchema)
