import React, { ReactNode } from "react"
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export interface NotFoundPageProps {
    children?: ReactNode
}
export default function NotFoundPage({children}: NotFoundPageProps) {
    return (
        <div className="flex flex-col w-full items-center justify-center">
            <div className="text-center my-8">
            <h1 className="text-7xl font-bold text-gray-700">404</h1>
            <p className="text-2xl text-gray-600">Not Found</p>
            </div>
            {
                children ?? (
                    <Button component={Link} to="/" variant="contained" color="primary">
                        Ir a la página de inicio
                    </Button>
                )
            }
        </div>
    );
}