import React, { useMemo } from "react"
import Select from "./Select"
import { productTypeSelectOptions } from "./admin/product-form-helpers"


export type ProductTypeOption = typeof productTypeSelectOptions[number]

export default function SelectProductType(props: {
    includeAll?: boolean
    label?: string
    selectedOption: ProductTypeOption|null
    onChange: (option: ProductTypeOption|null) => void
}) {
    const values = useMemo(() => {
        if(!props.includeAll) {
            return productTypeSelectOptions
        }

        return productTypeSelectOptions.map(it => {
            if(it.value === null) {
                return { value: null, label: 'Todos' }
            }
            return it
        })
    }, [props.includeAll])

    return (
        <Select
            name="orderStatus"
            label={props.label ?? ''}
            size="small"
            variant="outlined"
            selectedOption={props.selectedOption}
            values={values}
            setValue={(option) => props.onChange(option) }
        />
    )
}