import { useMemo } from "react"


interface LevenshteinOptions {
    term: string,
    length: number
    wordSet: string[]
}

export function levenshteinDistance(_a: string, _b: string): number {
    const a = _a.toLowerCase()
    const b = _b.toLowerCase() 
    const distanceMatrix = Array(a.length + 1).fill(null).map(() => Array(b.length + 1).fill(null))

    for (let i = 0; i <= a.length; i++) {
        distanceMatrix[i][0] = i
    }

    for (let j = 0; j <= b.length; j++) {
        distanceMatrix[0][j] = j
    }

    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            const indicator = a[i - 1] === b[j - 1] ? 0 : 1
            distanceMatrix[i][j] = Math.min(
                distanceMatrix[i - 1][j] + 1,
                distanceMatrix[i][j - 1] + 1,
                distanceMatrix[i - 1][j - 1] + indicator
            )
        }
    }

    return distanceMatrix[a.length][b.length]
}

interface FilterByLevenshteinOptions<T> {
    term: string
    acceptableDistance: number
    items: T[]
    getComparable: (item: T) => string
}
export function filterByLevenshtein<T>({
    term,
    acceptableDistance,
    items,
    getComparable
}: FilterByLevenshteinOptions<T>) {

    const getWordSet = (item: T) => {
        const field = getComparable(item)
        return field.toLocaleLowerCase().split(' ')
    }

    return items
        .map(it => {
            const wordSet = getWordSet(it)
            const distances = wordSet.map(word => levenshteinDistance(term, word))
            const minDistance = Math.min(...distances)
            return { item: it, distance: minDistance }
        })
        .filter(({ distance }) => distance <= acceptableDistance)
        .sort((a, b) => a.distance - b.distance)
        .map(({ item }) => item)
}


export function useLevenshtein({term, length, wordSet}: LevenshteinOptions) {
    return useMemo(() => {
        console.log(term, length, wordSet)
        if(!term) return []
        const result = wordSet.filter(word => {
            const distance = levenshteinDistance(term, word)
            return distance <= length
        }).toSorted((a, b) => {
            const aD = levenshteinDistance(term, a)
            const bD = levenshteinDistance(term, b)
            return aD - bD
        })
        return result
    }, [term, length, wordSet])
}