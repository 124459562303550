import { gql } from "../../__generated__/gql";



export const GET_PRODUCTS_FOR_SORTING_GROUP = gql(/* GraphQL */ `

    query GetProductsForSortingGroup($icecreamGroup: IcecreamGroup!) {
        productsWithSorting(group: $icecreamGroup) {
            icecreamGroup
            productId
            productName
            position
        }
    }    

`)