import { gql } from "../__generated__/gql";


export const PLACE_ORDER_MUTATION = gql(/* GraphQL */`
    mutation PlaceOrder($order: OrderRequest!) {
        placeOrder(order: $order)  {
            orderNumber
            status
        }
    }
`)