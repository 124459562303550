import { gql } from "../__generated__/gql";


export const UPDATE_PRODUCT_MUTATION = gql(`
    mutation UpdateProduct($id: ID!, $product: UpdateProduct!) {
        updateProduct(id: $id, update: $product) {
            id
            name
        }
    }
`)