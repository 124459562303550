import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
// If using HTTP header
import { setContext } from '@apollo/client/link/context';
import { JWT_KEY } from '../ui/context/current-session';
import { scalarsLink } from './gql-links/scalars';
import { retryLink } from './gql-links/retry';
import { errorLink } from './gql-links/error';

const httpLink = createHttpLink({
    // Hardcode backend if we're in debug mode. Otherwise just go with the same origin's backend
    uri: (
        process.env.REACT_APP_DEBUG 
            ? 'http://localhost:2308/graphql' 
            : undefined
    )
});

// Sets a token in the Authorization header
const authLink = setContext((_request, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(JWT_KEY);
    if(!token) {
        return { headers }
    }
    // useLocalStorage always "JSON.stringifies" the string
    // therefore we require parsing
    const parsedString = JSON.parse(token)
    if(typeof parsedString !== 'string') {
        console.warn('Invalid token type inside localStorage', {parsedString, token})
        return { headers }
    }
    return {
        headers: { ...headers, authorization: `Bearer ${parsedString}` }
    }
});



export const graphqlClient = new ApolloClient({
    link: (
        authLink
            .concat(scalarsLink)
            .concat(errorLink)
            .concat(retryLink)
            .concat(httpLink)
    ),
    // Disable cache as per Issue #97 on github. 
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        },
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        },
    },
    cache: new InMemoryCache(),
});