import { gql } from "../__generated__/gql";



export const APP_SETTINGS_QUERY = gql(/* GraphQL */`
    query AppSettings {
        appSettings {
           canMakeOrders
        }
    }
`)