import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import BottomSheetDialog from "../../bottom-sheet/BottomSheetDialog";
import LoadingButton from "../../loading-button";
import { validationSchema, zodSchema } from "./location-form-helpers";
import { useQuery } from "@apollo/client";
import { LIST_BILLING_INFOS_QUERY } from "../../../../network/billing-info/list-billing-infos-query";
import { LIST_LOCATIONS_QUERY } from "../../../../network/locations/list-locations-query";
import Select, { SelectOption } from "../../Select";
import { supplierToLocation } from "../../../../models/Location";
import { billingInfoFragmentToBillingInfo } from "../../../../models/BillingInfo";

const defaultInitialValues = {
    name: "",
    address: "",
    isSupplier: false,
    billingInfoCIF: null,
    suppliedById: null
}


export interface LocationFormProps {
    open?: boolean
    create?: boolean
    loading?: boolean
    initialValues?: {
        id?: string
        name: string
        address: string
        isSupplier: boolean
        billingInfoCIF: string | null
        suppliedById: string | null
    },
    onSubmit?: (value: ReturnType<typeof zodSchema.parse>, resetForm: () => void) => void
    onClose: () => void
}

type FormWithDataProps = Pick<LocationFormProps, 'onSubmit' | 'initialValues' | 'loading' | 'onClose' | 'create'> & {
    billingInfoOptions: Array<SelectOption>,
    supplierOptions: Array<SelectOption>
}

function FormWithData({
    create,
    loading,
    initialValues = defaultInitialValues,
    onSubmit,
    onClose,
    billingInfoOptions,
    supplierOptions
}: FormWithDataProps) {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form, helpers) => onSubmit?.(zodSchema.parse(form), helpers.resetForm)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">
                <TextField
                    type="text"
                    name="name"
                    label="Nombre del proveedor o distribuidor"
                    placeholder="Nombre del proveedor o distribuidor"
                    className="grow"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                />
                <TextField
                    type="text"
                    name="address"
                    label="Dirección"
                    placeholder="Dirección"
                    className="grow"
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                />
                <FormControlLabel
                    disabled={!create}
                    hidden={!create}
                    checked={formik.values.isSupplier}
                    control={<Checkbox
                        inputProps={{
                            'aria-label': 'controlled'
                        }} />}
                    name="isSupplier"
                    className="grow"
                    onBlur={formik.handleBlur}
                    value={formik.values.isSupplier}
                    onChange={formik.handleChange}
                    label="Es Proveedor" />

                <Select
                    name="billingInfoCIF"
                    label="Información de facturación"
                    sx={{ minWidth: 150 }}
                    selectedOption={billingInfoOptions.find(it => { return it.value === formik.values.billingInfoCIF }) ?? null}
                    values={billingInfoOptions}
                    error={formik.touched.billingInfoCIF && Boolean(formik.errors.billingInfoCIF)}
                    helperText={formik.touched.billingInfoCIF ? formik.errors.billingInfoCIF : ''}
                    onBlur={formik.handleBlur}
                    setValue={option => formik.setFieldValue('billingInfoCIF', option?.value ?? null)}
                />
                <Select
                    name="suppliedById"
                    label="Proveedor"
                    sx={{ minWidth: 150 }}
                    selectedOption={supplierOptions.find(it => it.value === formik.values.suppliedById) ?? null}
                    values={supplierOptions}
                    error={formik.touched.suppliedById && Boolean(formik.errors.suppliedById)}
                    helperText={formik.touched.suppliedById ? formik.errors.suppliedById : ''}
                    onBlur={formik.handleBlur}
                    setValue={option => formik.setFieldValue('suppliedById', option?.value ?? null)}
                />
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        isLoading={loading}
                        disabled={!formik.isValid}
                        type="submit"
                        variant="contained">
                        {create ? 'Dar de alta' : 'Modificar'}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </form>
    )
}


export default function LocationForm({
    open = false,
    create = true,
    loading = false,
    initialValues,
    onSubmit,
    onClose
}: LocationFormProps) {

    const { loading: billingInfoLoading, data: billingInfoData } = useQuery(LIST_BILLING_INFOS_QUERY)
    const { loading: locationsLoading, data: locationsData } = useQuery(LIST_LOCATIONS_QUERY)

    const billingInfos: Array<SelectOption> = useMemo(() => ([
        {
            label: 'Seleccione información de facturación',
            value: null
        },
        ...(billingInfoData?.billingInfos ?? []).map(billingInfoFragmentToBillingInfo).map(it => ({
            label: `${it.companyName} - ${it.CIF}`,
            value: it.CIF
        }))
    ]), [billingInfoData?.billingInfos])

    const suppliers: Array<SelectOption> = useMemo(() => {return ([
        {
            label: 'Seleccione un proveedor',
            value: null
        },
        ...(locationsData?.suppliers ?? []).map(supplierToLocation).map(it => ({
            label: it.name,
            value: it.id,
            disabled: it.id === initialValues?.id
        }))
    ])}, [locationsData?.suppliers, initialValues?.id])


    const loadingData: Boolean = useMemo(() => billingInfoLoading || locationsLoading, [billingInfoLoading,
        locationsLoading
    ])

    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {create ? 'Dar de alta a un proveedor o distribuidor' : 'Modificar entidad proveedora o distribuidora'}
            </DialogTitle>
            {loadingData && <CircularProgress className="m-auto" />}
            {!loadingData && <FormWithData
                loading={loading}
                create={create}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onClose={onClose}
                billingInfoOptions={billingInfos}
                supplierOptions={suppliers}
            />}
        </BottomSheetDialog>
    );
}
