import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { memo, useEffect } from 'react';
import { useMap } from 'usehooks-ts';
import { OrderStatus } from '../../__generated__/graphql';
import { useSearchFilters } from '../hooks/use-search-filters';
import { DateRangeFilters } from './reusable-filters/DateRangeFilters';
import { OrderStatusFilter } from './reusable-filters/OrderStatusFilter';
import { QuickFilters } from './reusable-filters/QuickFilters';

const BottomSheetPaperProps = {
    sx: {
        m: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        mx: 'auto',
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0
    }
}

const SlideUpTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export interface OrderFilters {
    from: Date | null
    to: Date | null
    orderStatus: OrderStatus | null
    location: string | null
}
interface FilterSheetProps {
    open?: boolean
    title?: string
    onClose: () => void
    dateQuickActions?: boolean
    dateRange?: boolean
    orderStatus?: boolean
    /**
     * Need a custom component? Provide your own.
     * Plenty of examples within the reusable-filters folder
     */
    extraFilters?: (props: FilterSlotProps) => React.ReactNode | null
}

/**
 * Interface that provides state handles to render a component destined to have a filter
 * that is gonna be backed up by search params
 */
export interface FilterSlotProps {
    state: ReturnType<typeof useMap<string, string>>[0]
    stateSetter: ReturnType<typeof useMap<string, string>>[1]
}


function FilterSheet({
    open = false,
    title="Tus filtros",
    onClose,
    dateQuickActions = false,
    dateRange = false,
    orderStatus = false,
    extraFilters
}: FilterSheetProps) {
    const [localParams, localParamsActions] = useMap<string, string>()
    const [params, setParams] = useSearchFilters()
    // Use search params as the source of truth, don't allow default values by props
    useEffect(() => {
        // Confusing as fuck. 
        // But read the docs and the value comes before the key

        // If the map had any values from previous render, clear them
        localParamsActions.reset()
        params.forEach((value, key) => {
            localParamsActions.set(key, value)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, params])

    return (
        <Dialog
            open={open}
            TransitionComponent={SlideUpTransition}
            PaperProps={BottomSheetPaperProps}
            keepMounted={false}
            fullWidth
            onClose={onClose}
        >
            <DialogTitle className="text-center">{title}</DialogTitle>
            <DialogContent className="flex flex-col gap-y-2">
                {dateQuickActions && <QuickFilters state={localParams} stateSetter={localParamsActions} />}
                {dateRange && <DateRangeFilters state={localParams} stateSetter={localParamsActions} />}
                {orderStatus && <OrderStatusFilter state={localParams} stateSetter={localParamsActions} />}
                {extraFilters && extraFilters({ state: localParams, stateSetter: localParamsActions })}
            </DialogContent>
            <DialogActions>
                <section className="w-full flex flex-col gap-y-2 px-4 pb-4">
                    <Button
                        variant='contained'
                        onClick={() => {
                            setParams(
                                Object.fromEntries(
                                    localParams.entries()
                                )
                            )
                            onClose()
                        }}>
                        Confirmar
                    </Button>
                    <Button variant='outlined' onClick={onClose} >Cancelar</Button>
                </section>
            </DialogActions>
        </Dialog>
    )
}

export default memo(FilterSheet)