import { Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { z } from "zod"
import { validationFrom } from "../../forms/validation"


const zSchema = z.object({
    receiverName: z.string(),
    completeDelivery: z.boolean()
})


const validationSchema = validationFrom(zSchema)

interface DeliveryFormComponentProps {
    orderNumber: number
    onSubmit: (values: typeof zSchema._type) => void
    onClose: () => void
}

export default function DeliveryFormComponent({ 
    orderNumber,
    onSubmit, 
    onClose 
}: DeliveryFormComponentProps) {
    const formik = useFormik({
        initialValues: {
            receiverName: "",
            completeDelivery: false
        } as typeof zSchema._type,
        validationSchema,
        onSubmit: values => {
            formik.resetForm()
            onSubmit(values)
        }
    })

    return (
        <form
            className="flex flex-col w-full py-4 gap-4"
            onSubmit={formik.handleSubmit}>
            <Typography variant="body1">
                Datos de la persona que recibe el pedido
            </Typography>
            <TextField
                type="text"
                name="receiverName"
                label="Nombre del receptor"
                placeholder="Nombre del receptor"
                className="grow"
                error={formik.touched.receiverName && Boolean(formik.errors.receiverName)}
                helperText={formik.touched.receiverName && formik.errors.receiverName}
                onBlur={formik.handleBlur}
                value={formik.values.receiverName}
                onChange={formik.handleChange}
            />

            <div className="class flex flex-row justify-end">

                <FormControlLabel
                    label={`Marcar pedido ${orderNumber} como completado`}
                    name="completeDelivery"
                    onBlur={formik.handleBlur}
                    value={formik.values.completeDelivery}
                    onChange={formik.handleChange}
                    control={<Checkbox />} />
            </div>

            <Button
                type="submit"
                disabled={!formik.isValid}
                variant="contained">
                Finalizar entrega
            </Button>
            <Button
                type="button"
                disabled={!formik.isValid}
                onClick={onClose}
                variant="outlined">
                Cancelar
            </Button>
            <div className="py-1">
            </div>
        </form>
    )
}