import React, { memo } from "react"
import { Department } from "../../__generated__/graphql"

export const departments = Object.entries(Department).map(([key, value]) => ({ label: formatDepartment(value), value: value }))

export function formatDepartment(department: Department) {
    switch (department) {
        case 'bakery': return 'Panadería'
        case 'delivery': return 'Reparto'
        case 'icecream': return 'Heladería'
        case 'management': return 'Gestión'
        case 'pastry': return 'Pastelería'
        case 'preparator': return 'Preparador'
        case 'shopkeep': return 'Dependiente'
        default: return department.toString()
    }
}

interface DeparmentChipProps {
    department: Department
    onClick?: (val: Department) => void
    small?: boolean
}


const deparmentColorMap = new Map([
    [Department.Bakery, 'bg-yellow-500'],
    [Department.Delivery, 'bg-green-500'],
    [Department.Icecream, 'bg-pink-500'],
    [Department.Management, 'bg-blue-500'],
    [Department.Pastry, 'bg-red-500'],
    [Department.Preparator, 'bg-purple-500'],
    [Department.Shopkeep, 'bg-indigo-500'],
])


const getClassName = (department: Department, small: boolean | undefined) => {
    const sizeClasses = small ? 'px-1 py-[0.05rem] text-xs' : 'px-2 py-[0.1rem]';
    return `${sizeClasses} rounded-xl text-white ${deparmentColorMap.get(department) ?? ''}`;
}


const DepartmentChip: React.FC<DeparmentChipProps> = memo(({ department, onClick, small }) => {
    return (
        <span key={department} className={getClassName(department, small)} onClick={() => onClick?.(department)}>
            {formatDepartment(department)}
        </span>
    )
});


export default DepartmentChip;