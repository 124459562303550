import { Button } from "@mui/material";
import React, { ReactNode, memo } from "react";
import { Link } from "react-router-dom";

export interface EmptyRowsProps {
    labelText?: string
    buttonText?: string
    children?: ReactNode
}

function EmptyRows({
    labelText='Parece que aquí no hay nada todavía...',
    buttonText='Ir a la página de inicio',
    children
}: EmptyRowsProps) {
    return (
        <div className="flex w-full items-center justify-center">
            <div className="text-center">
                <p className="text-2xl text-gray-600 my-6">{labelText}</p>
                {
                    children ?? (
                        <Button component={Link} to="/" variant="contained" color="primary">
                            {buttonText}
                        </Button>
                    )
                }
                
            </div>
        </div>
    )
}

export default memo(EmptyRows)