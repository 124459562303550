import { Button, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { z } from 'zod';
import WeightInput, { parseKiloString, zodWeightSchema } from '../../components/WeightInput';
import BottomSheetDialog from '../../components/bottom-sheet/BottomSheetDialog';
import LoadingButton from '../../components/loading-button';
import { validationFrom } from '../../forms/validation';
import { useMutation } from '@apollo/client';
import { CREATE_TRAY_MUTATION } from '../../../network/trays/create-tray-mutation';
import { LIST_TRAYS_QUERY } from '../../../network/trays/list-trays-query';

interface NewTrayFormDialogProps {
    open?: boolean
    onClose: () => void
}

const NewTraySchema = z.object({
    name: z.string(),
    kilos: zodWeightSchema()
});

const validationSchema = validationFrom(NewTraySchema)

export default function NewTrayFormDialog({
    open,
    onClose,
}: NewTrayFormDialogProps) {
    const [mutate, { loading: isSubmitting }] = useMutation(CREATE_TRAY_MUTATION, {
        refetchQueries: [LIST_TRAYS_QUERY],
        onCompleted: () => {
            onClose()
        }
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            kilos: ''
        },
        onSubmit: async (values, helpers) => {
            const res = NewTraySchema.safeParse(values)
            if (!res.success) {
                return
            }

            const grams = parseKiloString(res.data.kilos)
            if (Number.isNaN(grams)) {
                return
            }

            try {
                await mutate({
                    variables: {
                        input: {
                            name: res.data.name,
                            grams
                        }
                    }
                })

                helpers.resetForm()
            } catch (error: any) {
                alert(error.message)
            }
        },
        validationSchema
    })

    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle className="text-center">
                    Dar de alta una cubeta
                </DialogTitle>

                <DialogContent className="flex flex-col gap-2 ">
                    <span className="py-1"></span>
                    <TextField
                        type="text"
                        name="name"
                        label="Breve descripción de la cubeta"
                        placeholder="Cubeta acero 5L"
                        className="grow"
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <span className="py-1"></span>
                    <WeightInput
                        name="kilos"
                        label="Peso de la cubeta"
                        value={formik.values.kilos}
                        error={formik.touched.kilos && Boolean(formik.errors.kilos)}
                        helperText={formik.touched.kilos && formik.errors.kilos}
                        onChange={event => formik.setFieldValue('kilos', event.target.value)} />

                </DialogContent>

                <div className="flex flex-row my-4 gap-2 px-6">

                    <Button fullWidth variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>
                    <LoadingButton fullWidth isLoading={isSubmitting} variant="contained" disabled={!formik.isValid} type="submit">
                        Confirmar
                    </LoadingButton>
                </div>
            </form>
        </BottomSheetDialog>
    );
}