import { Chip } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

interface IssueProps {
    className?: string
    createdAt: Date
    updatedAt: Date
    toBePickedUpOn: Date
    observations: string
    resolved: boolean
    pickedUpAt?: {
        name: string
        address: string
    }

    children?: React.ReactNode
}


const fmt = (d: Date) => dayjs(d).format('DD/MM/YYYY')

export default function Issue({
    createdAt,
    updatedAt,
    className="",
    observations,
    toBePickedUpOn,
    resolved,
    pickedUpAt,
    children
}: IssueProps) {

    const rootClasses = [
        "flex flex-col gap-2 w-full lg:w-1/2",
        "lg:max-w-sm h-fit p-6 bg-white border",
        "border-gray-200 rounded-xl shadow hover:bg-gray-100 ",
        className
    ]

    return (
        <div className={rootClasses.join(' ')}>
            <div className="flex flex-col ">
                <div className="flex flex-row items-center justify-between">
                    <h5 className="font-semibold text-lg">Observaciones</h5>
                    {resolved &&
                        <Chip
                            icon={<DoneIcon />}
                            className="w-fit"
                            size="small"
                            color="success"
                            label="Resuelto"
                        />}
                    {!resolved &&
                        <Chip
                            icon={<CloseIcon />}
                            className="w-fit"
                            size="small"
                            color="warning"
                            label="Sin resolver"
                        />}
                </div>
                <p className="italic font-normal text-gray-500 pl-2 pt-2">{observations}</p>
            </div>

            <div className="flex flex-row items-center justify-end space-x-2 ">
                Fecha recogida el <span className="ml-1 font-semibold">{fmt(toBePickedUpOn)}</span>
            </div>

            <div className="flex flex-col gap-2">

                <div className="flex flex-row gap-2 justify-end">
                    <Chip
                        size="small"
                        label={`Solicitado el ${fmt(toBePickedUpOn)}`} />
                    <Chip
                        size="small"
                        label={`Actualizado ${fmt(updatedAt ?? createdAt)}`} />
                </div>

            </div>

            {children}
        </div>
    )
}
