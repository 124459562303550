import React, { memo } from 'react'
import { ProductType } from "../../__generated__/graphql"
import { formatProductType } from "./admin/product-form-helpers"


export const sortByProductType = <T extends { productType: ProductType }>(a: T, b: T) => {
    return productTypeSortOrder[a.productType] - productTypeSortOrder[b.productType]
}
const productTypeSortOrder = {
    [ProductType.Accessories]: 1,
    [ProductType.Bakery]: 2,
    [ProductType.BeveragesAndSlushies]: 3,
    [ProductType.Icecream]: 4,
    [ProductType.Pastry]: 5,
    [ProductType.RawMaterials]: 6,
    [ProductType.VariousFood]: 7,
    [ProductType.Other]: 8,
}
interface ProductTypeProps {
    productType: ProductType
    onClick?: (val: ProductType) => void
    small?: boolean
}


const map = new Map([
    [ProductType.Accessories, 'bg-blue-500'],
    [ProductType.Bakery, 'bg-yellow-500'],
    [ProductType.BeveragesAndSlushies, 'bg-green-500'],
    [ProductType.Icecream, 'bg-pink-500'],
    [ProductType.Pastry, 'bg-red-500'],
    [ProductType.RawMaterials, 'bg-purple-500'],
    [ProductType.VariousFood, 'bg-indigo-500'],
    [ProductType.Other, 'bg-gray-500'],
])

const getClassName = (productType: ProductType, small: boolean | undefined) => {
    const sizeClasses = small ? 'px-1 py-[0.05rem] text-xs' : 'px-2 py-[0.1rem]';
    return `${sizeClasses} rounded-xl text-white ${map.get(productType) ?? ''}`;
}

function ProductTypeComponent({ productType, onClick, small }: ProductTypeProps) {
    return (
        <span key={productType} className={getClassName(productType, small)} onClick={() => onClick?.(productType)}>
            {formatProductType(productType)}
        </span>
    )
}

export default memo(ProductTypeComponent)