import { Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { FilterSlotProps } from '../FilterSheet'
import { orderStatusSelectOptions } from '../order-status'
import Select from '../Select'

export function OrderStatusFilter({ 
    title="Estado del pedido",
    state, stateSetter }: FilterSlotProps & { title?: string }) {

    const selectValues = useMemo(() => (
        [{ label: "Todos", value: null }, ...orderStatusSelectOptions]
    ), [])

    type Option = typeof selectValues[number]
    const setValue = useCallback((option: Option | null) => {
        const finalValue = option?.value ?? null
        if (finalValue != null) {
            return stateSetter.set('status', finalValue)
        }
        return stateSetter.remove('status')
    }, [stateSetter])

    const selectedOption = useMemo(
        () => (
            orderStatusSelectOptions
                .find(it => it.value === state.get('status'))
            ?? null
        ),
        [state]
    )

    return (
        <section>
            <Typography className="pb-2" variant='subtitle1'>{title}</Typography>
            <div className='flex flex-col gap-2 sm:flex-col'>
                <Select
                    name="orderStatus"
                    label="Estado del pedido"
                    variant="outlined"
                    helperText="El estado del pedido"
                    sx={{ minWidth: 150 }}
                    selectedOption={selectedOption}
                    values={selectValues}
                    setValue={setValue}
                />
            </div>
        </section>
    )
}