import { Chip } from "@mui/material";
import { OrderStatus } from "../../__generated__/graphql";
import React from "react";
import { Check, DoneAll, Inventory, LocalShipping, Schedule, Verified } from "@mui/icons-material";

export const orderStatusSelectOptions = Object.values(OrderStatus).map(status => ({ value: status, label: formatOrderStatus(status) }))
// TODO: confirm this is the format we want to use for the order status
export function formatOrderStatus(status: OrderStatus) {
    switch (status) {
        case 'received': return 'Sin preparar'
        case 'corrected': return 'Verificado'
        case 'readyForPickup': return 'Listo para reparto'
        case 'delivering': return 'En entrega'
        case 'delivered': return 'Entregado'
        case 'fullyDispatched': return 'Completamente entregado'
        default: return status
    }
}

function orderStatusColor(status: OrderStatus) {
    switch (status) {
        case 'received': return 'info'
        case 'corrected': return 'secondary'
        case 'readyForPickup': return 'success'
        case 'delivering': return 'warning'
        case 'delivered': return 'success'
        case 'fullyDispatched': return 'success'
        default: return 'default'
    }
}
export function OrderStatusChip({ status }: { status: OrderStatus }) {
    return <Chip
        className="w-fit"
        size="small"
        color={orderStatusColor(status)}
        icon={orderStatusReactIcons[status]}
        label={formatOrderStatus(status)} />
}

// React Icons for Order Status, using MATERIAL icons and without Chip
export const orderStatusReactIcons = {
    received: <Schedule />,
    corrected: <Verified />,
    readyForPickup: <Inventory />,
    delivering: <LocalShipping />,
    delivered: <Check />,
    fullyDispatched: <DoneAll />
}



