import { TextField, InputAdornment, TextFieldProps } from "@mui/material";
import React from "react";
import { z } from "zod";

export function parsePrice(value: string): number {
    return parseFloat(value.replace(',', '.'))
}

type PriceInputProps = Pick<TextFieldProps, 'name' | 'label' | 'value' | 'onBlur' | 'onChange' | 'error' | 'helperText'>

export const zodPriceSchema = (zeroInclusive = false) => (
    z.string({
        invalid_type_error: 'Éste campo es obligatorio',
        required_error: 'El precio es obligatorio'
    })
    .regex(/^[0-9]+([,.][0-9][0-9]?)?$/, 'Formato de precio incorrecto')
    .refine(
        (value) => {
            const number = parsePrice(value)
            const numberIsInRange = zeroInclusive ? number >= 0 : number > 0

            return Number.isNaN(number) === false && numberIsInRange
        }, {
            message: `El precio tiene que ser mayor${zeroInclusive ? " o igual": ""} que 0`,
        }
    )
    .transform(str => str.replaceAll(',', '.'))
)

/**
 * Usage notes
 * <PriceInput
 *   value={formik.values.price}
 *   onBlur={formik.handleBlur}
 *   onChange={formik.handleChange}
 *   error={formik.touched.price && Boolean(formik.errors.price)}
 *   helperText={formik.touched.price ? formik.errors.price : ''} />
 */

export default function PriceInput({
    name = "price",
    label = "Precio",
    value,
    onBlur, onChange,
    error, helperText
}: PriceInputProps) {
    return (
        <TextField
            type="text"
            name={name}
            label={label}
            inputMode="numeric"
            placeholder="00,00"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            error={error}
            helperText={helperText} />
    )
}