import { useFormik } from "formik"
import { validationSchema, zodSchema } from "./assign-product-to-supplier-form-helpers"
import React, { useMemo } from "react"
import { useQuery } from "@apollo/client"
import { DialogContent, DialogActions, Button, DialogTitle, CircularProgress } from "@mui/material"
import BottomSheetDialog from "../../../bottom-sheet/BottomSheetDialog"
import LoadingButton from "../../../loading-button"
import { ALL_PRODUCTS_QUERY } from "../../../../../network/all-products-query"
import SelectWithAutocomplete from "../../../SelectWithAutocomplete"
import { SelectOption } from "../../../Select"
import PriceInput from "../../../PriceInput"
import { LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY } from "../../../../../network/locations/suppliers/list-products-of-supplier-query"


const defaultInitialValues = {
    productId: "",
    defaultPrice: "",
    productionCost: "0"
}


export interface AssignProductToSupplierFormProps {
    open?: boolean
    create?: boolean
    loading?: boolean
    supplierId: string | undefined
    initialValues?: {
        supplierId?: string
        productId: string
        defaultPrice: string
        stock?: number
        productionCost: string
    },
    onSubmit?: (value: ReturnType<typeof zodSchema.parse>, resetForm: () => void) => void
    onClose: () => void
}

type FormWithDataProps = Pick<AssignProductToSupplierFormProps, 'onSubmit' | 'initialValues' | 'loading' | 'onClose' | 'create'> & {
    productOptions: Array<SelectOption>,
    currentlySuppliedProductIds: Array<string>
}

function FormWithData({
    create,
    loading,
    initialValues = defaultInitialValues,
    onSubmit,
    onClose,
    productOptions,
    currentlySuppliedProductIds
}: FormWithDataProps) {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form, helpers) => onSubmit?.(zodSchema.parse(form), helpers.resetForm)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">

                <SelectWithAutocomplete
                    disabled={!create}
                    name="productId"
                    label="Producto"
                    sx={{ minWidth: 150 }}
                    selectedOption={productOptions.find(it => { return it.value === formik.values.productId }) ?? null}
                    values={productOptions.filter(it => it.value == null || !currentlySuppliedProductIds.includes(it.value))}
                    error={formik.touched.productId && Boolean(formik.errors.productId)}
                    helperText={formik.touched.productId ? formik.errors.productId : ''}
                    onBlur={formik.handleBlur}
                    setValue={option => formik.setFieldValue('productId', option?.value ?? null)}
                />

                <PriceInput
                    name="defaultPrice"
                    label="Precio de venta por defecto"
                    error={formik.touched.defaultPrice && Boolean(formik.errors.defaultPrice)}
                    helperText={formik.touched.defaultPrice && formik.errors.defaultPrice}
                    onBlur={formik.handleBlur}
                    value={formik.values.defaultPrice}
                    onChange={formik.handleChange}
                />
                <PriceInput
                    name="productionCost"
                    label="Coste de producción"
                    error={formik.touched.productionCost && Boolean(formik.errors.productionCost)}
                    helperText={formik.touched.productionCost && formik.errors.productionCost}
                    onBlur={formik.handleBlur}
                    value={formik.values.productionCost}
                    onChange={formik.handleChange}
                />
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        isLoading={loading}
                        disabled={!formik.isValid}
                        type="submit"
                        variant="contained">
                        {create ? 'Crear asignación' : 'Modificar asignación'}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </form>
    )
}


export default function AssignProductToSupplierForm({
    open = false,
    create = true,
    loading = false,
    initialValues,
    onSubmit,
    onClose,
    supplierId
}: AssignProductToSupplierFormProps) {

    const { loading: loadingProducts, data: productsData } = useQuery(ALL_PRODUCTS_QUERY)

    const productOptions: Array<SelectOption> = useMemo(() => ([
        {
            label: 'Seleccione un producto',
            value: null
        },
        ...(productsData?.rawProducts ?? []).map(it => ({
            label: `${it.name} - ${it.productType}`,
            value: it.id
        }))
    ]), [productsData?.rawProducts])

    const { loading: loadingLocationWithSuppliedProducts, data: locationWithSuppliedProducts } = useQuery(LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY, {
        variables: { id: supplierId!! },
        skip: !supplierId
    });

    const loadingData = useMemo(() => { return loadingLocationWithSuppliedProducts || loadingProducts }, [loadingLocationWithSuppliedProducts, loadingProducts])

    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {create ? 'Crear asignación de producto' : 'Modificar asignación de producto'}
            </DialogTitle>
            {loadingData && <CircularProgress className="m-auto" />}
            {!loadingData && <FormWithData
                loading={loading}
                create={create}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onClose={onClose}
                productOptions={productOptions}
                currentlySuppliedProductIds={locationWithSuppliedProducts?.locationById.suppliedProducts.map(it => it.id) ?? []}
            />}
        </BottomSheetDialog>
    );
}
