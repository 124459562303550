import { useState } from "react"
import { ProductType } from "../../../__generated__/graphql"
import { ProductTypeOption } from "../../components/SelectProductType"
import { productTypes, productTypeSelectOptions } from "../../components/admin/product-form-helpers"

const chamberStatusPreferred = 'chamber-status-preferred-product-type'
export function usePreferredProductType() {

    const [productType, _setProductType] = useState<ProductType|null>(() => {
        const savedType = localStorage.getItem(chamberStatusPreferred)
        if(!savedType || !productTypes.includes(savedType as ProductType)) {
            return null
        }

        return savedType as ProductType
    })


    const setProductType = (option: ProductTypeOption | null) => {
        const value = option?.value
        if(!value) {
            localStorage.removeItem(chamberStatusPreferred)
            _setProductType(null)
            return
        }
        
        localStorage.setItem(chamberStatusPreferred, value)
        _setProductType(value)
    }

    const productTypeOption = productTypeSelectOptions.find(it => it.value === productType) ?? null

    return [productTypeOption, setProductType] as const
}