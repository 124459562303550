import { gql } from "../../__generated__/gql";

export const GET_MISSING_PRODUCTS_QUERY = gql(/* GraphQL */ `

    query GetMissingProducts($filters: MissingProductFilters!) {

        missingProducts(filters: $filters) {
            product {
                id
                name
                productType
                icecreamGroup
            }
            chamberAmount
            orderedAmount
            deliveredAmount
            remainingAmountToCover
            stock
        }
    }
    
`)