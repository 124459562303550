import { useQuery } from "@apollo/client";
import { ArrowRight } from "@mui/icons-material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MY_ORDERS_QUERY } from "../../../network/my-orders-query";
import BDGDataGrid from "../../components/data-grid/BDGDataGrid";
import { createdAtCell, orderNumberCell, orderStatusCell, updatedAtCell } from "../../components/data-grid/shared-cells";
import DeliveryDate from "../../components/DeliveryDate";

function getLinkForBill(orderNumber: string) {
    if (process.env.REACT_APP_DEBUG) {
        return `http://localhost:2308/bills/${orderNumber}/pdf`
    }
    return `/bills/${orderNumber}/pdf`
}

export default function OrderingRecordPage() {
    const navigate = useNavigate()
    const [_gridSortModel, setGridSortModel] = useState<GridSortModel | undefined>(undefined)
    const gridSortModel = useMemo(() => _gridSortModel ?? [
        {
            field: 'toBeDeliveredOn',
            sort: 'desc',
        } as const,
        {
            field: 'status',
            sort: 'desc'
        } as const
    ], [_gridSortModel])
    const onDownloadBill = (orderNumber: string) => {
        const link = document.createElement('a');
        link.href = getLinkForBill(orderNumber)
        link.download = `albaran_pedido_${orderNumber}.pdf`
        link.click()
        link.remove()
    }
    const onSeeDetails = (orderNumber: string) => navigate(`./${orderNumber}`)
    const { loading, data } = useQuery(MY_ORDERS_QUERY)
    const rows = useMemo(() => data?.myOrders ?? [], [data])
    const columns: GridColDef[] = [
        orderNumberCell,
        orderStatusCell,
        {
            flex: 1,
            field: 'toBeDeliveredOn',
            type: 'date',
            headerName: 'Fecha de entrega',
            renderCell: params =>
                params
                    ? <DeliveryDate date={params.value} />
                    : null

        },
        createdAtCell,
        updatedAtCell,
        {
            field: 'actions',
            headerName: 'Acciones',
            type: 'actions',
            width: 100,
            getActions: (params: GridRowParams) =>
                params
                    ? ([
                        <GridActionsCellItem
                            icon={<InsertDriveFileIcon />}
                            onClick={() => onDownloadBill(params.row.orderNumber.toString())}
                            label="Descargar recibo" />,
                        <GridActionsCellItem
                            icon={<ArrowRight />}
                            onClick={() => onSeeDetails(params.row.orderNumber.toString())}
                            label="Ver detalles del pedido" />,
                    ])
                    : []
        }
    ]
    return (
        <BDGDataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            sortModel={gridSortModel}
            onSortModelChange={(mode) => setGridSortModel(mode)}
            getRowId={(row) => row.orderNumber}
        />
    );
}

