import { onError } from "@apollo/client/link/error";
import { clearSession } from '../../ui/context/current-session';

export const errorLink = onError(({graphQLErrors, networkError}) =>{
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
    if (graphQLErrors) {
        for (const {message, locations, path} of graphQLErrors) {
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        }
        const sessionTimedOut = graphQLErrors.find(it => it.message === 'session-timed-out')
        const invalidToken = graphQLErrors.find(it => it.message === 'invalid-token')
        if(sessionTimedOut) {
            alert('Session has timed out, clearing old token and user')
            console.log('Session has timed out, clearing old token and user')
            clearSession()
        }
        if(invalidToken) {
            alert('Invalid token, clearing old token and user')
            console.log('Invalid token, clearing old token and user')
            clearSession()
        }
    }
    
})