import { gql } from "../../__generated__/gql";


export const LIST_USERS_QUERY = gql(/* GraphQL */`
    query ListUsers {
        users {
            id
            displayName
            username
            contactEmail
            elevated
            deleted
            worksAt { 
                location {
                    id
                    name
                }
                departments
            }
        }
    }
`)