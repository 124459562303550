import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import React, { useState } from 'react';
import { ProductType } from '../../../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { CREATE_VARIOUS_PRODUCTS_MUTATION } from '../../../network/create-various-products-mutation';
import { ALL_PRODUCTS_QUERY } from '../../../network/all-products-query';


const CSV_CATEGORIA_TO_PRODUCT_TYPE = new Map<string, string>([
    ['HELADO', 'icecream'],
    ['ACCESORIOS', 'accessories'],
    ['PASTELERIA', 'pastry'],
    ['PANADERIA', 'bakery'],
    ['BEBIDAS Y GRANIZADOS', 'beveragesAndSlushies'],
    ['VARIOS COMIDA', 'variousFood'],
    ['MATERIA PRIMA', 'rawMaterials'],
    ['OTROS', 'other']
])

interface CSVProduct {
    name: string
    productType: ProductType
}

function isValidProductType(productType: string): productType is ProductType {
    const values = new Set(Array.from(CSV_CATEGORIA_TO_PRODUCT_TYPE.values()))
    return values.has(productType)
}

export function parseCSV(contents: string, separator = ';') {
    const [lineWithKeys, ...linesWithData] = contents.replaceAll('\r', '').split('\n')
    const keys = lineWithKeys.split(separator)
    if (keys.length !== 3) {
        return { success: false, message: 'Invalid CSV format' } as const
    }
    const parsedEntries: Array<CSVProduct> = []

    for (const data of linesWithData) {
        const values = data.split(separator)
        const object: any = keys.reduce((acc, current, index) => ({ ...acc, [current]: values[index] }), {})

        const productType = CSV_CATEGORIA_TO_PRODUCT_TYPE.get(object.categoria)

        if (!productType || !isValidProductType(productType)) {
            return { success: false, message: `El tipo de producto ${object.categoria} no es válido` } as const
        }

        parsedEntries.push({ name: object.producto as string, productType })
    }

    return { success: true, data: parsedEntries } as const
}



interface UploadCSVFormProps {
    open: boolean
    onClose: () => void
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface UploadCSVFormState {
    loading: boolean
    data?: Array<CSVProduct>
    error?: string
}
export default function UploadCSVForm({
    open = false,
    onClose: _onClose = () => { } }: Partial<UploadCSVFormProps>
) {
    const [{ loading, data, error }, setState] = useState<UploadCSVFormState>({ loading: false })
    
    const onClose = () => {
        setState({ loading: false })
        _onClose()
    }

    const [mutate] = useMutation(CREATE_VARIOUS_PRODUCTS_MUTATION, {
        refetchQueries: [ALL_PRODUCTS_QUERY],
        onCompleted: () => {
            setState({ loading: false })
            onClose()
        }
    })

    const handleUpload = (data: Array<CSVProduct>) => {
        mutate({
            variables: {
                products: data
            }
        })
    }

    return (

        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Dar de alta productos a partir de un archivo <code>.csv</code>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {!data && !error &&
                    <Alert severity="info" className="!text-white">
                        <Typography gutterBottom>
                            Los archivos CSV válidos deben tener las
                            columnas <code>producto</code> y <code>categoria</code>, donde
                            el separador sea el carácter <code>;</code>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Puedes configurar el separador en Microsoft Excel al exportar tu archivo como CSV
                        </Typography>
                    </Alert>
                }
                {error && <Alert severity="error">{error}</Alert>}
                <div className="py-6 flex flex-row justify-center items-center">
                    {loading && <CircularProgress />}
                    {!data &&
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Seleccionar fichero
                            <VisuallyHiddenInput
                                accept=".csv"
                                type="file"
                                onChange={(ev: any) => {
                                    setState({ loading: true })
                                    const file = ev.target.files?.[0]
                                    if (!file) return
                                    const reader = new FileReader()
                                    reader.onload = (e) => {
                                        const contents = e.target?.result as string
                                        const result = parseCSV(contents)
                                        if (result.success) {
                                            console.log(result.data)
                                            setState(prev => ({ ...prev, loading: false, data: result.data}))
                                        } else {
                                            console.error(result.message)
                                            setState(prev => ({ ...prev, loading: false, error: result.message}))
                                        }
                                    }
                                    reader.readAsText(file)
                                }} />
                        </Button>
                    }
                    {data &&
                        <Alert severity="success" >
                            Tu fichero contiene {data?.length} entradas
                        </Alert>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cerrar
                </Button>
                <Button disabled={!data?.length} variant="contained" onClick={() => data && handleUpload(data)}>
                    Dar de alta productos
                </Button>
            </DialogActions>
        </Dialog>
    )
}