import { gql } from "../../__generated__/gql";

export const LIST_LOCATIONS_QUERY = gql(/* GraphQL */`
    query ListLocations {
        distributionPoints {
            ...CommonDistributionPoint
        }
        suppliers {
            ...CommonSupplier
        }
    }
`)