import React from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import NavButton from '../components/nav-button';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useCurrentOrder } from '../context/current-order';

function PlaceOrEditOrder({create=false}: {create: boolean}) {
    const content = (
        create 
            ? (<>
                <AddIcon fontSize='large'/>
                Nuevo pedido
            </>)
            : (<>
                <ModeEditIcon fontSize='large'/>
                Modificar pedido
            </>)
    )
    return (
        <NavButton className="w-1/2 h-40 px-1" to="/ordering-from-category">
            {content}
        </NavButton>
    )
}

export default function ShopHomePage() {
    const {hasOrder} = useCurrentOrder()
    return (
        <section className="flex flex-wrap justify-between w-full p-4 gap-y-4">
            <PlaceOrEditOrder create={!hasOrder}/>
            <NavButton className="w-1/2 h-40 px-1" to="/my-orders">
                <LocalShippingIcon fontSize='large'/>
                Mis pedidos
            </NavButton>
            <NavButton className="w-1/2 h-40 px-1" to="/my-issues">
                <ReportProblemIcon fontSize='large'/>
                Notificar incidencia
            </NavButton>
        </section>
    )
}