import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, FormControl, IconButton, InputLabel, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AvailableProductsQuery } from "../../__generated__/graphql";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ProductType from "./ProductType";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'


type Product = AvailableProductsQuery['availableProducts'][number]
type SelectProductQuantityProps = {
    quantity: number
    setQuantity: (value: number) => void
    maxQuantity?: number
}

const unique = (sth: Array<number>) => Array.from(new Set(sth)).toSorted((a, b) => a - b)

export function SelectProductQuantity({ quantity, setQuantity, maxQuantity }: SelectProductQuantityProps) {
    const label = "Cantidad"
    // hack to make it work and still display the right quantity
    const quantities = useMemo(() => {
        const defaultQuantities = [0, 1, 2, 5, 10, 15, 30]
        return unique([...defaultQuantities, quantity, maxQuantity || 0]).filter(it => !maxQuantity || it <= maxQuantity).sort((a, b) => a - b)
    }, [quantity, maxQuantity])
    const handleChange = (event: SelectChangeEvent) => {
        const val = event.target.value
        if (val === "0") {
            console.log('val === "0"')
            setQuantity(0)
            return
        }
        const integer = parseInt(val)
        if (Number.isNaN(integer) || !Number.isInteger(integer)) {
            console.log('Number.isNaN(integer) || !Number.isInteger(integer)', integer)
            setQuantity(0)
            return
        }

        if (integer >= 0) {
            console.log("integer >= 0", integer)
            const clampedQuantity = maxQuantity ? Math.min(maxQuantity, integer) : integer
            setQuantity(clampedQuantity)
        }
    }
    return (
        <div className="flex flex-row w-fit justify-center items-center">
            <IconButton color="primary" className="h-fit w-fit" disabled={quantity <= 0} onClick={() => setQuantity(Math.max(0, quantity - 1))}>
                <RemoveIcon />
            </IconButton>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                <InputLabel id="quantity-select-label">{label}</InputLabel>
                <Select
                    labelId="quantity-select-label"
                    id="quantity-select"
                    value={`${quantity}`}
                    label={label}
                    onChange={handleChange}
                >
                    {quantities.map(quantity => {
                        if (quantity === 0) {
                            return (
                                <MenuItem key="0" value="0">
                                    <em>–</em>
                                </MenuItem>
                            )
                        }
                        return (
                            <MenuItem key={`${quantity}`} value={`${quantity}`}>
                                {quantity}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <IconButton color="primary" className="h-fit w-fit" disabled={maxQuantity ? maxQuantity <= quantity : false} onClick={() => setQuantity(quantity + 1)}>
                <AddIcon />
            </IconButton>
        </div>
    );
}

export type ProductSelectorProps = SelectProductQuantityProps & {
    product: Product
    isFavorite: boolean
    onFavoriteClick: (productId: string, isFavorite: boolean) => void
}

type LoadingFavoriteButtonProps = {
    isLoading: boolean
    isFavorite: boolean
}

function LoadingFavoriteButton({ isLoading, isFavorite }: LoadingFavoriteButtonProps) {
    if (isLoading) {
        return <CircularProgress color="error" size={24}/>
    }

    return isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />
}


export default function ProductSelector({
    product,
    quantity,
    setQuantity,
    maxQuantity,
    onFavoriteClick,
    isFavorite,
}: ProductSelectorProps) {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(false)

    }, [isFavorite, setIsLoading])
    const handleFavoriteClick = useCallback(
        () => {
            setIsLoading(true)
            onFavoriteClick(product.id, !product.isFavorite)
        },
        [onFavoriteClick, product.id, product.isFavorite, setIsLoading]
    )

    return (
        <ListItem>
            <IconButton color="error" onClick={handleFavoriteClick}>
                <LoadingFavoriteButton isFavorite={isFavorite} isLoading={isLoading} />
            </IconButton>
            <ListItemText
                className="px-2"
                primary={product.name}
                secondary={<ProductType productType={product.productType} />} />
            <span className='px-2'></span>
            <SelectProductQuantity quantity={quantity} setQuantity={setQuantity} maxQuantity={maxQuantity} />
        </ListItem>
    )
}