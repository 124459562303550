import React, { memo } from "react";


export const formatGrams = (grams: number) => {
    const kgs = Math.trunc(grams / 1000)
    const remainder = grams % 1000
    if(remainder === 0) {
        return `${kgs}`
    }

    return `${kgs},${remainder.toString().padStart(3, '0')}`.replace(/0+$/, '')
}

function WeightComponent({ grams, ...props }: React.HTMLAttributes<HTMLSpanElement> & { grams: number }) {
    return (
        <span {...props}>
            {formatGrams(grams)} kg
        </span>
    )
}

export default memo(WeightComponent)