import { useQuery } from "@apollo/client"
import { DialogTitle, CircularProgress, Typography, Button } from "@mui/material"
import { DateCalendar } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import React, { useState } from "react"
import { MY_SUPPLIED_LOCATIONS_QUERY } from "../../../network/locations/my-supplied-locations-query"
import { SelectOption } from "../Select"
import SelectWithAutocomplete from "../SelectWithAutocomplete"
import BottomSheetDialog from "../bottom-sheet/BottomSheetDialog"

function getLinkForMonthlyBill(locationId: string, date: Date) {
    const startOfMonthDate = dayjs(date)
        .startOf('month')
        .toISOString()

    const endOfMonthDate = dayjs(date)
        .endOf('month')
        .toISOString()

    const startDateQueryParam = encodeURIComponent(startOfMonthDate)
    const endDateQueryParam = encodeURIComponent(endOfMonthDate)
    const query = `?startDate=${startDateQueryParam}&endDate=${endDateQueryParam}`

    if (process.env.REACT_APP_DEBUG) {
        return `http://localhost:2308/monthly-bills/${locationId}/pdf` + query
    }
    return `/monthly-bills/${locationId}/pdf` + query
}

interface MonthlyReceiptFormProps {
    open: boolean
    onClose: () => void
}

export default function MonthlyReceiptForm({
    open,
    onClose
}: MonthlyReceiptFormProps) {
    const { data, loading } = useQuery(MY_SUPPLIED_LOCATIONS_QUERY)
    const [selectedStore, setSelectedStore] = useState<SelectOption | null>(null)
    const [month, setMonth] = useState<Date | null>(null)
    const invalidStore = selectedStore == null
    const invalidDate = month == null || Number.isNaN(month.getTime())

    return (
        <BottomSheetDialog open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                Generar factura del mes
            </DialogTitle>
            {loading && (
                <div className="w-full flex flex-row justify-center items-center">
                    <CircularProgress size={24} />
                </div>
            )}
            <div className="flex flex-col p-4 gap-2 items-center">
                <SelectWithAutocomplete
                    fullWidth
                    sx={{ maxWidth: 500}}
                    label="Seleccione la tienda"
                    selectedOption={selectedStore}
                    values={(data?.mySuppliedLocations ?? []).map(it => ({ label: it.name, value: it.id }))}
                    setValue={setSelectedStore} />

                <Typography className="text-center" variant="body1">
                    Seleccione el mes para el cual desea generar la factura
                </Typography>
                <DateCalendar
                    defaultValue={dayjs()}
                    value={!invalidDate ? dayjs(month) : undefined}
                    onChange={(value, selectionState, _selectedView) => {
                        if (selectionState === 'finish') {
                            value
                                ? setMonth(value.toDate())
                                : setMonth(null)
                        }
                    }}
                    openTo='month'
                    views={['year', 'month']} />
            </div>

            <div className='flex flex-col gap-2 p-4'>
                <Button
                    variant='contained'
                    disabled={invalidStore || invalidDate}
                    onClick={() => {
                        console.log()
                        const {label: name, value: id} = selectedStore!
                        const a = document.createElement('a')
                        a.target = '_blank'
                        a.download = `factura_${name.replaceAll(' ','-')}_${dayjs(month).format('YYYY-MM')}.pdf`
                        a.href = getLinkForMonthlyBill(id!, month!)
                        a.click()
                        a.remove()
                        onClose()
                    }}>
                    Generar
                </Button>
                <Button variant='outlined' onClick={onClose}>Cancelar</Button>
            </div>
        </BottomSheetDialog>
    )
}