import { gql } from "../../__generated__/gql";

export const UPDATE_BILLING_INFO_MUTATION = gql(`
    mutation UpdateBillingInfo($CIF: String!, $update: BillingInfoUpdate!) {
        updateBillingInfo(CIF: $CIF, update: $update) {
            CIF
            companyName
            registeredAddress
            phoneNumber
            contactEmail
        }
    }
`)