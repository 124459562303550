import { useMutation, useQuery } from "@apollo/client"
import { LinearProgress, Typography } from "@mui/material"
import { GridColDef, GridFilterModel, GridFooterContainer, GridSortModel } from "@mui/x-data-grid"
import React, { useMemo, useState } from "react"
import { GET_MISSING_PRODUCTS_QUERY } from "../../../network/chamber-status/get-missing-products-query"
import { SET_STOCKS_MUTATION } from "../../../network/set-stocks-mutation"
import AppBarSearch from "../../components/AppBarSearch"
import SelectProductType from "../../components/SelectProductType"
import BDGDataGrid from "../../components/data-grid/BDGDataGrid"
import { productTypeCell } from "../../components/data-grid/shared-cells"
import { usePreferredProductType } from "../../hooks/chamber/use-preferred-productype"
import dayjs from "dayjs"
import ProductType from "../../components/ProductType"


const colors = {
    'green': 'bg-green-600',
    'blue': 'bg-blue-600',
    'red': 'bg-red-600',
}

function getClassName({ color }: {
    color: keyof typeof colors
}) {
    const sizeClasses = 'px-2 py-[0.1rem]'
    const bgColor = colors[color]
    return ['text-sm', 'max-h-[24px]', bgColor, sizeClasses, 'rounded-xl', 'text-white'].join(' ')
}


function asGridFilterModel(search: string): GridFilterModel {
    return {
        items: [
            {
                field: 'name',
                value: search,
                operator: 'contains'
            }
        ]
    }
}


function getClassNameForRemainingProduct(params: any) {
    const { chamberAmount, remainingAmountToCover, orderedAmount } = params.row

    if (orderedAmount === 0) {
        return undefined
    }

    if (remainingAmountToCover > chamberAmount) {
        return 'bg-red-200'
    }

    return 'bg-green-200'
}


const defaultSortModel = [
    {
        field: 'remainingAmountToCover',
        sort: 'desc'
    },
    {
        field: 'orderedAmount',
        sort: 'desc'
    }
] satisfies GridSortModel;

export default function ChamberStatusPage() {
    const [search, setSearch] = useState('')
    const [sortModel, _setSortModel] = useState<GridSortModel>(defaultSortModel)

    const setSortModel = (sortModel: GridSortModel) => {
        if(sortModel.length === 0) {
            return _setSortModel(defaultSortModel)
        }

        return _setSortModel(sortModel)
    }


    const [productType, setProductType] = usePreferredProductType()

    const filterModel = asGridFilterModel(search)

    const { loading: fetching, data } = useQuery(GET_MISSING_PRODUCTS_QUERY, {
        variables: {
            filters: {
                productType: productType?.value ?? undefined,
                dates: {
                    from: dayjs().startOf('day').toDate(),
                    to: dayjs().endOf('day').toDate(),
                }
            }
        }
    })

    const [mutate, { loading: updating }] = useMutation(SET_STOCKS_MUTATION, {
        refetchQueries: [GET_MISSING_PRODUCTS_QUERY]
    })
    const loading = fetching || updating
    const rows = data?.missingProducts ?? []

    const stockSum = useMemo(() => (
        data
            ?.missingProducts
            ?.map(it => it.stock)
            ?.reduce((acc, curr) => acc + curr, 0)
        ?? 0
    ), [data?.missingProducts])

    const columns = useMemo(() => {
        const columns = [
            {
                flex: .5,
                field: 'name',
                valueGetter: (_, row) => row.product.name,
                headerName: 'Producto',
            },
            {
                ...productTypeCell,
                flex: .5,
                renderCell: params => {
                    const ptype = params?.row?.product?.productType
                    if (!ptype) {
                        return null
                    }
                    return <ProductType productType={ptype} />
                }
            },
            {
                flex: 1,
                field: 'stock',
                align: 'left',
                headerAlign: 'left',
                headerName: `Cantidad en cámara; en total ${stockSum}`,
                type: 'number',
                renderCell: (params) => {
                    const { chamberAmount } = params.row
                    return (
                        <div className="flex flex-row items-center h-full gap-5">
                            <span className={getClassName({ color: 'blue' })}>
                                {chamberAmount}
                            </span>
                        </div>
                    )
                },
                editable: true,
            },
            {
                flex: 1,
                field: 'remainingAmountToCover',
                headerName: 'Pendiente de preparar (pedidos del día)',
                type: 'number',
                headerAlign: 'left',
                renderCell: (params) => {
                    const { remainingAmountToCover } = params.row
                    return (
                        <div className="flex flex-row items-center h-full gap-5">
                            <span className={getClassName({ color: 'red' })}>
                                {remainingAmountToCover}
                            </span>
                        </div>
                    )
                }
            },
            {
                flex: 1,
                field: 'remainderAmount',
                headerName: 'Excedente en cámara',
                headerAlign: 'left',
                type: 'number',
                renderCell: params => {
                    const { chamberAmount, remainingAmountToCover } = params.row
                    return (
                        <div className="flex flex-row items-center h-full gap-5">
                            <span className={getClassName({ color: 'green' })}>
                                {Math.max(0, chamberAmount - remainingAmountToCover)}
                            </span>
                        </div>
                    )
                }
            }
        ] as Array<GridColDef>
        return columns
    }, [stockSum])

    return (
        <>
            <AppBarSearch onSearchChange={setSearch} />
            <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
            <BDGDataGrid
                getRowId={(row) => row.product.id}
                loading={loading}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                getRowClassName={params => getClassNameForRemainingProduct(params) ?? ''}
                slots={{
                    footer: () => {
                        return <GridFooterContainer sx={{
                            justifyContent: 'end',
                            gap: 2,
                            px: 2,
                        }}>
                            <div className="flex flex-row gap-4 justify-center items-center">
                                <div className="flex flex-row gap-1 items-center">
                                    <span className={"w-3 h-3 rounded-full " + colors.blue}>
                                    </span>
                                    Cantidad en cámara
                                </div>

                                <div className="flex flex-row gap-1 items-center">
                                    <span className={"w-3 h-3 rounded-full " + colors.red}>
                                    </span>
                                    Cantidad pendiente de preparar (pedidos del día)
                                </div>

                                <div className="flex flex-row gap-1 items-center">
                                    <span className={"w-3 h-3 rounded-full " + colors.green}>
                                    </span>
                                    Excedente en cámara
                                </div>
                            </div>
                            <Typography variant="body1">
                                En total:
                                <span className="font-medium"> {stockSum} </span>
                            </Typography>
                            <SelectProductType
                                includeAll
                                label='Tipo producto'
                                selectedOption={productType}
                                onChange={(option) => setProductType(option)}
                            />
                        </GridFooterContainer>
                    }
                }}
                filterModel={filterModel}
                processRowUpdate={(newRow, oldRow) => {
                    const valid = (
                        newRow.stock >= 0 &&
                        newRow.stock !== oldRow.stock &&
                        Number.isInteger(newRow.stock)
                    )
                    if (!valid) {
                        return oldRow
                    }
                    mutate({
                        variables: {
                            stock: {
                                productId: newRow.product.id,
                                stock: newRow.stock
                            }
                        }
                    })
                    return newRow
                }}
                rows={rows} />
        </>
    )
}