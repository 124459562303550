import { gql } from "../../__generated__/gql";


export const LIST_LOCATION_PRICES_QUERY = gql(/* GraphQL */`
    query ListPricesForLocation($locationId: ID!) {

        locationById(id: $locationId) {
            name
        }
        
        pricesForLocation(id: $locationId){
            defaultPrice
            specificPrice
            product {
                id
                name
                productType
                icecreamGroup
            }
        }
    }
`)