import { z } from "zod"
import { validationFrom } from "../../../forms/validation"
import { Department } from "../../../../__generated__/graphql"

export const zodSchema = z.object({
    locationId: z.string({ required_error: 'Éste campo es obligatorio' }),
    departments: z.array(z.nativeEnum(Department, {}), { required_error: 'Éste campo es obligatorio' }),
})

export const validationSchema = validationFrom(zodSchema)
