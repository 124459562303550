import { useMutation } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import { AppBar, Button, Fab, LinearProgress, Typography } from '@mui/material'
import { GridActionsCellItem, GridColDef, GridDeleteIcon } from '@mui/x-data-grid'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ListReturnedProductsQuery } from '../../../__generated__/graphql'
import { REMOVE_RETURNED_PRODUCT_MUTATION } from '../../../network/return-product/remove-returned-product-mutation'
import { LIST_RETURNED_PRODUCTS_QUERY } from '../../../network/return-product/returned-products-query'
import BDGDataGrid from '../../components/data-grid/BDGDataGrid'
import { createdAtCell } from '../../components/data-grid/shared-cells'
import RefundDialog from '../../components/delivery/RefundDialog'
import ErrorMessage from '../../components/error-message'
import { useRefundPageData } from './use-refund-page-data'
import WeightComponent from '../../components/WeightComponent'
import dayjs from 'dayjs'
import EmptyRows from '../../components/data-grid/empty-rows'

function Header({ locationName }: any) {
    return <AppBar position="static">
        <Typography className='py-2 px-4'>
            Devoluciones de {locationName} durante {dayjs().format('MMMM')}
        </Typography>
    </AppBar>
}

type ReturnedProduct = ListReturnedProductsQuery['returnedProducts'][number]


export default function RefundsPage() {
    const { locationId } = useParams()
    const [showDialog, setShowDialog] = useState(false)
    const [removeReturnedProduct, { loading: removing }] = useMutation(REMOVE_RETURNED_PRODUCT_MUTATION, {
        refetchQueries: [LIST_RETURNED_PRODUCTS_QUERY]
    })
    const { loading, error, data } = useRefundPageData({
        locationId: locationId!
    })
    const columns = useMemo(() => ([
        {
            field: 'productName',
            flex: 1,
            headerName: 'Producto',
            valueGetter: (_, row) => row.product.name
        },
        {
            field: 'grams',
            headerName: 'Peso',
            renderCell: (params) => (
                params.row.grams
                    ? <WeightComponent grams={params.row.grams} />
                    : 'Este producto no tiene peso registrado'
            ),
            flex: 1,
        },
        { ...createdAtCell, flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            getActions: params => ([
                <GridActionsCellItem
                    icon={<GridDeleteIcon />}
                    onClick={() => removeReturnedProduct({
                        variables: {
                            id: params.row.id,
                            forLocationId: locationId!
                        }
                    })}
                    label="Eliminar" />
            ])
        }
    ] satisfies Array<GridColDef<ReturnedProduct>>), [removeReturnedProduct, locationId])
    if (!loading && !error && data) {
        const { location, returnedProducts } = data
        return <>
            <Header locationName={location?.name} />
            <LinearProgress sx={{ opacity: removing ? 1 : 0 }} />
            <BDGDataGrid
                columns={columns}
                getRowId={(row) => row.id}
                sortModel={[{ field: 'createdAt', sort: 'desc' }]}
                slots={{
                    noRowsOverlay: () => (
                        <EmptyRows labelText={`No se han devuelto productos para ${location?.name}`}>
                            <Button
                                variant='contained'
                                onClick={() => setShowDialog(true)}>
                                <AddIcon />
                                Añadir abono
                            </Button>
                        </EmptyRows>
                    )
                }}
                rows={returnedProducts} />
            <RefundDialog
                open={showDialog}
                onClose={() => setShowDialog(false)} />

            <Fab
                size='large'
                color='primary'
                style={{ position: 'fixed', bottom: 56, right: 56 }}
                onClick={() => setShowDialog(true)}>
                <AddIcon />
            </Fab>
        </>
    }

    if (!loading && error) {
        return <ErrorMessage description={error.message} />
    }

    return <LinearProgress />
}