import { CommonDistributionPointFragment, CommonSupplierFragment, ListLocationsQuery } from "../__generated__/graphql"
import { BillingInfo, billingInfoFragmentToBillingInfo } from "./BillingInfo"

type DistributionPoint = CommonDistributionPointFragment
type Supplier = CommonSupplierFragment

type BaseLocation = {
    id: string
    name: string
    address: string
    billingInfoCIF: string | null | undefined
    billingInfo: BillingInfo | null | undefined
    supplierId: string | null | undefined
}

export type DistributionPointLocation = BaseLocation & {
    type: 'distributionPoint'
    supplier: SupplierLocation | null | undefined
}
export type SupplierLocation = BaseLocation & {
    type: 'supplier'
    supplier: ParentSupplier | null | undefined
}

export type Location = DistributionPointLocation | SupplierLocation
export type ParentSupplier = {
    id: string
    name: string
}

export function mapListLocationQueryToLocations(query: ListLocationsQuery): Array<Location> {
    return [
        ...query.distributionPoints.map(distributionPointToLocation),
        ...query.suppliers.map(supplierToLocation)
    ]

}

export function distributionPointToLocation(distributionPoint: DistributionPoint): DistributionPointLocation {
    const commonBillingFragment = distributionPoint.billingInfo
    const billingInfo = commonBillingFragment ? billingInfoFragmentToBillingInfo(commonBillingFragment) : null
    const supplierFragment = distributionPoint.suppliedByLocation
    const supplier = supplierFragment ? supplierToLocation(supplierFragment) : null
    return {
        id: distributionPoint.id,
        type: 'distributionPoint',
        name: distributionPoint.name,
        address: distributionPoint.address,
        billingInfoCIF: distributionPoint.billingInfoCIF,
        billingInfo: billingInfo,
        supplierId: distributionPoint.suppliedByLocationId,
        supplier: supplier,
    }
}
export function supplierToLocation(supplier: Supplier): SupplierLocation {
    const commonBillingFragment = supplier.billingInfo
    const billingInfo = commonBillingFragment ? billingInfoFragmentToBillingInfo(commonBillingFragment) : null
    const parentSupplier = supplier.suppliedByLocation ? {
        id: supplier.suppliedByLocation.id,
        name: supplier.suppliedByLocation.name
    } : null

    return {
        id: supplier.id,
        type: 'supplier',
        name: supplier.name,
        address: supplier.address,
        billingInfoCIF: supplier.billingInfoCIF,
        billingInfo: billingInfo,
        supplierId: supplier.suppliedByLocationId,
        supplier: parentSupplier
    }
}