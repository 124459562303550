import { gql } from "../__generated__/gql";



export const GET_PREPARATION_TABLE_QUERY =gql(/* GraphQL */`
    query GetPreparationTable($date: DateTime!, $productType: ProductType) {
        preparatorTable(date: $date, productType: $productType) {
    
            date
            rows {
                product {
                    id
                    name
                    stock
                }
                cells {
                    locationId
                    productId
                    readyAmount
                    requestedAmount
                }
            }

            headers {
                locationId
                locationName
            }
        }
    }

`)