import { gql } from "../../../__generated__/gql";


export const ASSIGN_PRODUCT_TO_SUPPLIER_MUTATION = gql(/* GraphQL */`
    mutation AssignProductToSupplier($input: AssignProductToSupplierInput!) {
      assignProductToSupplier(input: $input) {
            success
        }
    }
`)

export const UPDATE_PRICES_OF_SUPPLIED_PRODUCT_MUTATION = gql(/* GraphQL */`
    mutation UpdatePricesOfSuppliedProduct($input: UpdatePricesOfSuppliedProductInput!) {
        updatePricesOfSuppliedProduct(input: $input) {
            ...CommonSupplierProduct
        }
    }
`)

export const DELETE_SUPPLIED_PRODUCT_MUTATION = gql(/* GraphQL */`
    mutation DeleteSuppliedProduct($input: DeleteSuppliedProductInput!) {
        deleteSuppliedProduct(input: $input) {
            ...CommonSupplierProduct
        }
    }
`)