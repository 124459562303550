import { gql } from "../../__generated__/gql";


export const GET_DELIVERY_BATCH = gql(/* GraphQL */`
    
    query GetDeliveryBatch($id: ID!) {
        deliveryBatch(id: $id) {
            id
            orderNumber

            order {
                orderNumber
                observations
                status
                toBeDeliveredOn
                deliverToLocation {
                    name
                    address
                }
            }

            products {
                id
                name
                productType
                icecreamGroup
                amount
                grams
            }
        }
    }
`)