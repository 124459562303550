import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { clearSession, useSession } from '../ui/context/current-session';
import BaseLayout from '../ui/layouts/BaseLayout';
import ToolbarLayout from '../ui/layouts/ToolbarLayout';
import LoginPage from '../ui/pages/LoginPage';
import AuthenticatedRoutes from './AuthenticatedRoutes';


function UnauthenticatedRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="*" element={<Navigate to="/login"/>}/>
        </Routes>
    )
}

export function RootRoutes() {
    const {session, isAdmin } = useSession()
    return (
        <BaseLayout>
            <Router>
                {
                    session != null 
                        ? (
                            <ToolbarLayout session={session} clearSession={clearSession}>
                                <AuthenticatedRoutes session={session} isAdmin={isAdmin}/>
                            </ToolbarLayout>
                        )
                        : <UnauthenticatedRoutes />
                }
            </Router>
        </BaseLayout>
    )
}
