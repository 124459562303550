import { Button, Checkbox, CircularProgress, Collapse, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { ProductType } from "../../../__generated__/graphql";
import Select from "../Select";
import BottomSheetDialog from "../bottom-sheet/BottomSheetDialog";
import LoadingButton from "../loading-button";
import { productTypeSelectOptions, validationSchema, zodSchema } from "./product-form-helpers";
import { useLevenshtein } from "../../hooks/use-levenshtein";
import { useDebounce } from "usehooks-ts";
import { useQuery } from "@apollo/client";
import { LIST_TRAYS_QUERY } from "../../../network/trays/list-trays-query";
import { GroupsAsSelectOptions } from "../IcecreamGroup";


export interface ProductFormProps {
    products?: string[]
    open?: boolean
    create?: boolean
    loading?: boolean
    initialValues?: {
        name: string | null
        productType: ProductType | null
        trayId: string | null
        returnable: boolean
        icecreamGroup: string | null
    },
    onSubmit?: (value: ReturnType<typeof zodSchema.parse>, resetForm: () => void) => void
    onClose: () => void
}


const defaultInitialValues = {
    name: null,
    productType: null,
    returnable: false,
    trayId: null,
    icecreamGroup: null
} satisfies ProductFormProps['initialValues']



type FormWithDataProps = Pick<ProductFormProps, 'products' | 'onSubmit' | 'initialValues' | 'loading' | 'onClose' | 'create'> & {
    trays: Array<{ value: string, label: string }>
    groups: Array<{ value: string, label: string }>
}

function FormWithData({
    products = [],
    create,
    trays = [],
    groups = [],
    loading,
    initialValues = defaultInitialValues,
    onSubmit,
    onClose
}: FormWithDataProps) {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form, helpers) => onSubmit?.(zodSchema.parse(form), helpers.resetForm)
    })

    const debouncedName = useDebounce(formik.values.name, 500)

    const similarNames = useLevenshtein({
        term: debouncedName ?? '',
        length: 4,
        wordSet: products,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="flex flex-col gap-4 p-4 w-full">
                <div className="flex flex-col sm:flex-row gap-4 w-full">
                    <TextField
                        type="text"
                        name="name"
                        label="Nombre de producto"
                        placeholder="Nombre de producto"
                        className="grow"
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <Select
                        name="productType"
                        label="Tipo de producto"
                        sx={{ minWidth: 150 }}
                        selectedOption={productTypeSelectOptions.find(it => it.value === formik.values.productType) ?? null}
                        values={productTypeSelectOptions}
                        error={formik.touched.productType && Boolean(formik.errors.productType)}
                        helperText={formik.touched.productType ? formik.errors.productType : ''}
                        onBlur={formik.handleBlur}
                        setValue={value => formik.setFieldValue('productType', value?.value ?? null)}
                    />
                </div>
                {!formik.isSubmitting && <div className="flex flex-row justify-center gap-4 w-full">
                    <Collapse in={(formik.values.name ?? '').length > 5 && similarNames.length > 0 && formik.touched.name}>
                        <Typography variant="body1" className="text-center text-yellow-500">
                            ¿Es posible que estés buscando alguno de estos productos?
                        </Typography>
                        <div className="flex flex-row w-full justify-center">
                            <Typography variant="body1" >
                                {similarNames.join(', ')}
                            </Typography>
                        </div>
                    </Collapse>
                </div>}
                <Collapse in={formik.values.productType === 'icecream'}>
                    <div className="flex flex-col sm:flex-row gap-4 w-full">
                        <Select
                            name="trayId"
                            label="Tipo de la cubeta"
                            sx={{ minWidth: 150 }}
                            error={formik.touched.trayId && Boolean(formik.errors.trayId)}
                            helperText={Boolean(formik.errors.trayId) ? formik.errors.trayId : undefined}
                            onBlur={formik.handleBlur}
                            selectedOption={trays.find(it => it.value === formik.values.trayId) ?? null}
                            values={[{ value: null, label: 'Selecciona una cubeta' }, ...trays]}
                            setValue={value => formik.setFieldValue('trayId', value?.value ?? null)}
                        />

                        <Select
                            name="icecreamGroup"
                            label="Grupo de helado"
                            sx={{ minWidth: 150 }}
                            error={formik.touched.icecreamGroup && Boolean(formik.errors.icecreamGroup)}
                            helperText={Boolean(formik.errors.icecreamGroup) ? formik.errors.icecreamGroup : undefined}
                            onBlur={formik.handleBlur}
                            selectedOption={groups.find(it => it.value === formik.values.icecreamGroup) ?? null}
                            values={[{ value: null, label: 'Sin grupo' }, ...groups]}
                            setValue={value => formik.setFieldValue('icecreamGroup', value?.value ?? null)}
                        />
                    </div>
                </Collapse>
                <div className="flex flex-col sm:flex-row gap-4 w-full">
                    <FormControlLabel
                        label="Se puede devolver?"
                        name="returnable"
                        className="grow"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.returnable}
                        checked={formik.values.returnable}
                        control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} />}
                    />
                </div>
            </DialogContent>

            <DialogActions className="mx-4">
                <section className="w-full flex flex-col gap-y-2 pb-4">
                    <LoadingButton
                        isLoading={loading}
                        disabled={!formik.isValid}
                        type="submit"
                        variant="contained">
                        {create ? 'Crear producto' : 'Guardar cambios'}
                    </LoadingButton>
                    <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                </section>
            </DialogActions>
        </form>
    )
}


export default function ProductForm({
    products = [],
    open = false,
    create = true,
    loading = false,
    initialValues,
    onSubmit,
    onClose
}: ProductFormProps) {
    const { loading: loadingTrays, data } = useQuery(LIST_TRAYS_QUERY)
    const trays = useMemo(() => (
        data?.trays?.map(it => ({ value: it.id, label: it.name })) ?? []
    ), [data])
    const groups = GroupsAsSelectOptions
    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                {create ? 'Dar de alta un producto' : 'Modificar un producto'}
            </DialogTitle>

            {
                loadingTrays
                    ? <CircularProgress />
                    : <FormWithData
                        products={products}
                        loading={loading}
                        create={create}
                        initialValues={initialValues}
                        trays={trays}
                        groups={groups}
                        onSubmit={onSubmit}
                        onClose={onClose} />
            }


        </BottomSheetDialog>
    );
}
