import React from 'react';
import FactoryIcon from '@mui/icons-material/Factory';
import InventoryIcon from '@mui/icons-material/Inventory';
import NavButton from '../components/nav-button';
import GroupIcon from '@mui/icons-material/Group'
import SettingsIcon from '@mui/icons-material/Settings';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { Receipt } from '@mui/icons-material';;

export default function AdminHomePage() {

    return (
        <section className="flex flex-wrap justify-between w-full p-4 gap-y-4">
            <NavButton className="w-1/2 h-40 px-1" to="/factories">
                <FactoryIcon fontSize='large' />
                Gestión de Proveedores y Distribuidores
            </NavButton>
            
            <NavButton className="w-1/2 h-40 px-1" to="/products">
                <InventoryIcon fontSize='large' />
                Gestión de productos
            </NavButton>
            
            <NavButton className="w-1/2 h-40 px-1" to="/users">
                <GroupIcon fontSize='large' />
                Gestión de Usuarios
            </NavButton>

            <NavButton className="w-1/2 h-40 px-1" to="/billing-infos">
                <Receipt fontSize='large' />
                Facturación
            </NavButton>

            <NavButton className="w-1/2 h-40 px-1" to="/admin-orders">
                <Receipt fontSize='large' />
                Administrar Pedidos
            </NavButton>
            
            <NavButton className="w-1/2 h-40 px-1" to="/tray-management">
                <AllInboxIcon fontSize='large' />
                Gestión de cubetas
            </NavButton>

            <NavButton className="w-1/2 h-40 px-1" to="/icecream-groups">
                <GroupWorkIcon fontSize='large' />
                Ordenación grupos de helado
            </NavButton>

            <NavButton className="w-1/2 h-40 px-1" to="/sysconfig">
                <SettingsIcon fontSize='large' />
                Configuración del sistema
            </NavButton >
        </section >
    )

}