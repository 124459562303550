import { gql } from "../__generated__/gql";


export const CREATE_PRODUCT_MUTATION = gql(`
    mutation CreateProduct($product: CreateProduct!) {
        createProduct(newProduct: $product) {
            id
            name
        }
    }
`)