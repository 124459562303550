import { Add, Check, Delete, SwapHoriz } from '@mui/icons-material'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { AppBar, Badge, Button, ButtonGroup, CircularProgress, Fab, LinearProgress, Tab, Tabs, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { GetProductsFromOrderQuery } from '../../../__generated__/graphql'
import { OrderMeta, OrderObservations } from '../../components/OrderDetails'
import { ProductAmount } from '../../components/ProductListItem'
import AddProductBottomSheet from '../../components/add-product/AddProductBottomSheet'
import { useCheckOrderPageState } from '../../hooks/factory/use-check-order-page-state'
import NotFoundPage from '../NotFoundPage'
import { ProductListPadding } from '../ProductListPadding'

function TabbedContent({
    firstSlot,
    secondSlot
}: any) {
    const { formState } = useCheckOrderPageState()
    const [tab, setTab] = useState(0)
    return <>
        <Tabs
            variant="fullWidth"
            indicatorColor="secondary"
            value={tab}
            textColor="primary"
        >
            <Tab
                label={
                    <Badge 
                        color="secondary" 
                        badgeContent={formState.pendingProducts.length}>
                        Productos solicitados
                    </Badge>
                }
                onClick={() => setTab(0)} />
            <Tab
                label={
                    <Badge 
                        color="secondary" 
                        badgeContent={formState.checkedProducts.length}>
                        Productos verificados
                    </Badge>
                }
                onClick={() => setTab(1)} />
        </Tabs>
        {tab === 0 && firstSlot}
        {tab === 1 && secondSlot}
    </>
}

function PendingProducts() {
    const {
        formState,
        checkProduct,
        removeProduct,
        setSwappingState,
        setShowProductPicker
    } = useCheckOrderPageState()
    return useMemo(
        () => (<>
            {formState.pendingProducts.map(prod => (
                <ProductAmount
                    key={prod.product.id}
                    name={prod.product.name}
                    amount={prod.amount}
                    productType={prod.product.productType}
                    rightSlot={
                        <ButtonGroup className="pl-2" variant="outlined" >
                            <Button color="success" onClick={() => checkProduct(prod.product.id)}>
                                <Check />
                            </Button>
                            <Button color="warning" onClick={() => {
                                setSwappingState({ productId: prod.product.id, amount: prod.amount })
                                setShowProductPicker(true)
                            }}>
                                <SwapHoriz />
                            </Button>
                            <Button color="error" onClick={() => removeProduct(prod.product.id)}>
                                <Delete />
                            </Button>
                        </ButtonGroup>
                    }
                />
            ))}
            < ProductListPadding />
        </>),
        [formState.pendingProducts, checkProduct, removeProduct, setSwappingState, setShowProductPicker]
    )
}

function AlreadyCheckedProducts() {
    const { formState } = useCheckOrderPageState()
    return useMemo(
        () => (<>
            {formState.checkedProducts.map(prod => (
                <ProductAmount
                    key={prod.product.id}
                    name={prod.product.name}
                    amount={prod.amount}
                    productType={prod.product.productType}
                />
            ))}
            < ProductListPadding />
        </>),
        [formState.checkedProducts]
    )
}


interface PageBodyProps {
    order: GetProductsFromOrderQuery['order']
}

function PageBody({ order }: PageBodyProps) {
    const { 
        orderNumber,
        formState, 
        submitting, 
        submitCorrections 
    } = useCheckOrderPageState()
    const canSubmit = useMemo(() => (
        formState.pendingProducts.length === 0 && !submitting
    ), [formState.pendingProducts,submitting])

    return (
        <div className="flex grow flex-col pt-10 px-5 gap-5 w-full max-w-4xl self-center">
            <OrderMeta order={order} />
            <OrderObservations observations={order.observations} />
            <form 
                className="flex flex-col w-full"
                onSubmit={ev => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    submitCorrections({
                        variables: {
                            corrections: {
                                orderNumber,
                                products: formState.checkedProducts.map(prod => ({
                                    productId: prod.product.id,
                                    amount: prod.amount
                                }))
                            }
                        }
                    })
                }}>
                <TabbedContent
                    firstSlot={<PendingProducts />}
                    secondSlot={<AlreadyCheckedProducts/>}
                />
                <Fab
                    type="submit"
                    disabled={!canSubmit}
                    color="success"
                    size='large'
                    variant="extended"
                    className="py-12"
                    sx={{
                        width: 'auto',
                        position: 'fixed',
                        bottom: 16,
                        right: '50%',
                        transform: 'translateX(50%)'
                    }}
                >
                    {submitting ? <CircularProgress /> : <DoneAllIcon />}
                    <span className="px-1" />
                    Finalizar verificación
                </Fab>
            </form>
        </div>
    )
}


export default function CheckOrderPage() {
    const {
        orderNumber,
        remoteData: { loading, error, data },
        showProductPicker,
        setShowProductPicker,
        setSwappingState,

        addProduct,
        swapProduct
    } = useCheckOrderPageState()

    if ((!loading && error?.message === 'not-found') || !orderNumber) {
        return (
            <NotFoundPage>
                <Button component={Link} to="../" variant="contained" color="primary">
                    Volver atrás
                </Button>
            </NotFoundPage >
        )
    }

    return (<>
        <AppBar position="static">
            <div className="py-2 px-4 flex flex-row justify-between">
                <Typography className='py-2 px-4'>
                    Verificando pedido #{orderNumber}
                </Typography>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => setShowProductPicker(true)}
                    className="!text-white !border-white">
                    <Add />
                    Añadir producto
                </Button>
            </div>
        </AppBar>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <div className="flex flex-col grow overflow-y-auto">
            {!loading && data && <PageBody order={data.order} />}
        </div>
        <AddProductBottomSheet
            open={showProductPicker}
            products={data?.suppliedProducts ?? []}
            onSelectProduct={(prod) => {
                setSwappingState(prevSwappingState => {
                    if(prevSwappingState == null) {
                        addProduct({
                            amount: 1,
                            product: prod,
                        })
                    } else {
                        swapProduct({
                            swappedProductId: prevSwappingState.productId,
                            product: prod
                        })
                    }
                    return null
                })
                setShowProductPicker(false)
            }}
            onClose={() => {
                setSwappingState(null)
                setShowProductPicker(false)
            }}
        />
    </>)
}