import { gql } from "../../__generated__/gql";

export const LIST_RETURNED_PRODUCTS_QUERY = gql(/* GraphQL */`
    query ListReturnedProducts($filters: ReturnProductFilters!) {
        returnedProducts(filters: $filters) {
            id
            product {
                id
                name
            }
            grams
            createdAt
        }
    }
`)