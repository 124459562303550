import { gql } from "../__generated__/gql";


export const CREATE_VARIOUS_PRODUCTS_MUTATION = gql(/* GraphQL */`

    mutation CreateVariousProducts($products: [CSVProductEntry!]!) {
        createProducts(entries: $products) {
            id
            name
        }
    }

`)