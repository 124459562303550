import { z } from "zod";

export class ValidationError extends Error {
  public name = "ValidationError";

  public inner: Array<{ path: string; message: string }> = [];

  public constructor(message: string) {
    super(message);
  }
}


export function validateDNI(dni: string) {
    // Expresión regular para validar el formato del DNI (8 dígitos seguidos de una letra)
    const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;

    if (!dniRegex.test(dni)) {
        return false; // El formato del DNI es incorrecto
    }

    // Extraer el número y la letra del DNI
    const numero = parseInt(dni.slice(0, dni.length - 1));
    const letra = dni.slice(-1).toUpperCase();

    // Calcular la letra esperada para el número dado
    const letras = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const indice = numero % 23;

    // Verificar si la letra del DNI coincide con la letra esperada
    return letras.charAt(indice) === letra;
}

export function validateCIF(CIF: string): boolean {
    // Check if the CIF has the correct format: one letter followed by seven digits followed by one digit or letter
    const cifPattern = /^[ABCDEFGHJNPQRSUVW]{1}[0-9]{7}[0-9A-J]{1}$/i;
    if (!cifPattern.test(CIF)) {
        return false;
    }

    // Extract the control digit, number part, and letter part from the CIF
    const controlDigit = CIF.slice(-1);
    const numberPart = CIF.slice(1, -1);
    const letterPart = CIF.charAt(0);

    // Calculate the check sum for the number part
    const checkSum = Array.from(numberPart)
        .map((digit, index) => {
            const value = parseInt(digit, 10);
            // For even-indexed digits, use a lookup table to get the value
            // For odd-indexed digits, use the digit itself
            return index % 2 === 0 ? [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][value] : value;
        })
        // Sum all the values to get the check sum
        .reduce((a, b) => a + b, 0);

    // Calculate the control digit from the check sum
    const calculatedControlDigit =
        checkSum % 10 === 0 ? 0 : 10 - (checkSum % 10);

    // If the letter part is one of the specified letters, check if the control digit matches the calculated control digit
    if (/[ABCDEFGHJNPQRSUVW]/.test(letterPart)) {
        return (
            calculatedControlDigit === parseInt(controlDigit, 10) ||
            calculatedControlDigit === 'JABCDEFGHI'.indexOf(controlDigit)
        );
    }

    // If the CIF doesn't pass the checks, return false
    return false;
}

function createValidationError(e: z.ZodError) {
  const error = new ValidationError(e.message);
  error.inner = e.errors.map((err: any) => ({
    message: err.message,
    path: err.path.join("."),
  }));

  return error;
}

/**
 * Wrap your zod schema in this function when providing it to Formik's validation schema prop
 * @param schema The zod schema
 * @returns An object containing the `validate` method expected by Formik
 */
export function validationFrom<T>(
  schema: z.ZodSchema<T>,
  params?: Partial<z.ParseParams>,
): { validate: (obj: T) => Promise<void> } {
  return {
    async validate(obj: T) {
      try {
        await schema.parseAsync(obj, params);
      } catch (err: unknown) {
        throw createValidationError(err as z.ZodError<T>);
      }
    },
  };
}

function createValidationResult(error: z.ZodError) {
  const result: Record<string, string> = {};

  for (const x of error.errors) {
    result[x.path.filter(Boolean).join(".")] = x.message;
  }

  return result;
}

/**
 * Wrap your zod schema in this function when providing it to Formik's validate prop
 * @param schema The zod schema
 * @returns An validate function as expected by Formik
 */
export function toFormikValidate<T>(
  schema: z.ZodSchema<T>,
  params?: Partial<z.ParseParams>
) {
  return async (values: T) => {
    const result = await schema.safeParseAsync(values, params);
    if (!result.success) {
      return createValidationResult(result.error);
    }
    return undefined
  };
}