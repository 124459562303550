import { Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { isValidDate } from '../../../network/gql-links/scalars'
import { FilterSlotProps } from '../FilterSheet'

export function DateRangeFilters({ 
    title='Filtrar por fechas',
    state, stateSetter }: FilterSlotProps & { title?: string}) {
    const [getDate, setDate] = useMemo(() => {
        const getDate = (key: string) => {
            const localDate = state.get(key)
            const date = dayjs(localDate)
            // This check is absolutely necessary as dayjs(undefined) 
            // whenever a key is not present available in dictionary
            // Results in a "today" dayjs instance
            if (localDate !== undefined && isValidDate(date)) {
                return date
            }
            return null
        }
        const setDate = (key: string, value: dayjs.Dayjs | null) => {
            if (isValidDate(value)) {
                return stateSetter.set(key, value.toISOString())
            }
            return stateSetter.remove(key)
        }
        return [getDate, setDate] as const
    }, [state, stateSetter])

    return (
        <section>
            <Typography className="pb-2" variant='subtitle1'>{title}</Typography>
            <div className='flex flex-col gap-2 sm:flex-col'>
                <DatePicker
                    label="Desde"
                    slotProps={{
                        field: {
                            clearable: true,
                            onClear: () => setDate('from', null)
                        },
                        textField: {
                            helperText: `Para ver un solo un día es basta con rellenar "Desde"`
                        }
                    }}
                    value={getDate('from')}
                    onChange={(date) => setDate('from', date?.startOf('day') ?? null)} />
                <DatePicker
                    label="Hasta"
                    slotProps={{
                        field: {
                            clearable: true,
                            onClear: () => setDate('to', null)
                        }
                    }}
                    value={getDate('to')}
                    onChange={(date) => setDate('to', date?.endOf('day') ?? null)} />
            </div>
        </section>
    )
}