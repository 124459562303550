import React, { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useDebounce, useMap } from 'usehooks-ts';
import { ProductType } from '../../__generated__/graphql';
import { useSession } from './current-session';

export type ReadonlyMap<K, V> = Omit<Map<K, V>, 'set' | 'clear' | 'delete'>

export interface ProductAndAmount {
    product: {
        id: string
        name: string
        productType: ProductType
    }
    amount: number
}

export interface CurrentOrderState {
    hasOrder: boolean
    totalProducts: number
    order: ReadonlyMap<string, ProductAndAmount>
    addToOrder: (productObject: ProductAndAmount | ProductAndAmount[]) => void
    removeFromOrder: (productId: string, amount: number) => void
    clearOrder: () => void
}

// Create the context
const OrderContext = createContext<CurrentOrderState>({
    hasOrder: false,
    totalProducts: 0,
    order: new Map(),
    addToOrder: () => { },
    removeFromOrder: () => { },
    clearOrder: () => { }
});


export const GET_CURRENT_ORDER_KEY = (userId = 'anon') => `order-entries-${userId}`

function readOrderEntriesFromLocalStorage(storageKey: string): Array<[string, ProductAndAmount]> {
    const entries = localStorage.getItem(storageKey)
    return entries ? JSON.parse(entries) : []
}

export const CurrentOrderProvider = ({ children }: { children: ReactNode }) => {
    const { session } = useSession()

    // Issue: 162
    // This is done for people changing sessions in the same browser
    const CURRENT_ORDER_KEY = useMemo(() => GET_CURRENT_ORDER_KEY(session?.user?.id), [session])
    const localStorageEntries: Array<[string, ProductAndAmount]> = useMemo(
        () => readOrderEntriesFromLocalStorage(CURRENT_ORDER_KEY),
        [CURRENT_ORDER_KEY]
    )

    const [order, { set, remove, reset, setAll }] = useMap<string, ProductAndAmount>(localStorageEntries ?? [])
    // Issue: 162
    // Refresh the current order by re-reading from disk once the session changes
    useEffect(() => {
        setAll(
            readOrderEntriesFromLocalStorage(CURRENT_ORDER_KEY)
        )
    }, [CURRENT_ORDER_KEY, setAll])
    const debouncedOrder = useDebounce(order, 1_000)

    // JSON.stringify of a Map always returns "{}" which is not fit for what we want
    useEffect(() => {
        const newEntries = Array.from(debouncedOrder.entries())
        if (newEntries.length === 0) {
            localStorage.removeItem(CURRENT_ORDER_KEY)
        } else {
            localStorage.setItem(CURRENT_ORDER_KEY, JSON.stringify(newEntries))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedOrder])

    const addToOrder = (productObject: ProductAndAmount | ProductAndAmount[]) => {
        const arrayishProductObjects =
            Array.isArray(productObject) ? productObject : [productObject]

        for (const productObject of arrayishProductObjects) {
            if (productObject.amount <= 0) {
                // Don't do anything with 0 and negative numbers
                continue
            }
            const productId = productObject.product.id
            const oldAmount = order.get(productId)?.amount ?? 0
            set(productId, { ...productObject, amount: oldAmount + productObject.amount })
        }
    };

    const removeFromOrder = (productId: string, amount: number) => {
        const currentProduct = order.get(productId)
        if (!currentProduct) {
            return
        }

        const newAmount = Math.max(0, currentProduct.amount - amount)
        newAmount <= 0
            ? remove(productId)
            : set(productId, { ...currentProduct, amount: newAmount })
    };

    const totalProducts = useMemo(() => (
        Array
            .from(order.entries())
            .reduce((acc, [_key, { amount }]) => acc + amount, 0)
    ), [order])

    return (
        <OrderContext.Provider value={{
            hasOrder: order.size > 0,
            totalProducts,
            order,
            addToOrder,
            removeFromOrder,
            clearOrder: reset
        }}>
            {children}
        </OrderContext.Provider>
    );
};

// Custom hook to use the order context
export const useCurrentOrder = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error('useCurrentOrder must be used within an CurrentOrderProvider');
    }
    return context;
};