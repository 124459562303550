import { useMutation, useQuery } from '@apollo/client'
import { Add } from '@mui/icons-material'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Alert, Backdrop, Badge, Button, CircularProgress, Collapse, Fab, IconButton, LinearProgress, List, Snackbar, Tab, Tabs, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GetProductsFromOrderQuery, GetSuppliedProductsQuery, ProductType, WeightedProductAndAmount } from '../../../__generated__/graphql'
import { CREATE_DELIVERY_BATCH_MUTATION } from '../../../network/delivery-batches/create-delivery-batch-mutation'
import { GET_CURRENT_STOCKS_QUERY } from '../../../network/get-supplied-products-query'
import { ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY } from '../../../network/location-orders/orders-for-delivery-of-supplier-query'
import { GET_PRODUCTS_FROM_ORDER_QUERY } from '../../../network/products-from-order-query'
import { OrderMeta, OrderObservations } from '../../components/OrderDetails'
import Product from '../../components/Product'
import { ProductAmount } from '../../components/ProductListItem'
import { SelectProductQuantity } from '../../components/ProductSelector'
import WeightInput, { parseWeight, zodParseWeight } from '../../components/WeightInput'
import AddProductBottomSheet from '../../components/add-product/AddProductBottomSheet'
import { usePreparedProductsForm } from '../../hooks/factory/use-prepare-products'
import NotFoundPage from '../NotFoundPage'
import { ProductListPadding } from '../ProductListPadding'
import CompleteOrderDialog from './CompleteOrderDialog'


type SuppliedProduct = GetSuppliedProductsQuery['suppliedProducts'][number]

type PrepareProductsFormProps =
    ReturnType<typeof usePreparedProductsForm> & {
        order: GetProductsFromOrderQuery['order']
        onAddOrder: () => void
        getProductById: (id: string) => SuppliedProduct | undefined | null
    }

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function PrepareProductsTabs({
    preparedProductsLength,
    prepareProductsForm,
    preparedProductsList
}: any) {
    const [tab, setTab] = useState(0)

    return (
        <>
            <Tabs
                variant="fullWidth"
                indicatorColor="secondary"
                value={tab}
                textColor="primary"
            >
                <Tab
                    {...a11yProps(0)}
                    label="Productos por preparar"
                    onClick={() => setTab(0)} />
                <Tab
                    {...a11yProps(1)}
                    label={
                        <Badge
                            color="secondary"
                            badgeContent={Number(preparedProductsLength)}>
                            Productos ya preparados
                        </Badge>
                    }
                    onClick={() => setTab(1)} />
            </Tabs>
            {tab === 0 &&
                <div
                    id="tabpanel-delivered"
                    aria-labelledby="tab-delivered"
                    role="tabpanel" className="w-full h-full flex flex-col">
                    {prepareProductsForm}
                </div>
            }
            {tab === 1 &&
                <div
                    id="tabpanel-delivered"
                    aria-labelledby="tab-delivered"
                    role="tabpanel" className="w-full h-full flex flex-col">
                    {preparedProductsList}
                </div>
            }
        </>
    )
}

function EmptyList() {
    return (
        <div className="flex flex-row justify-center py-16">
            <Typography variant="h5">
                Esta lista está vacía
            </Typography>
        </div>
    )
}

interface ProductsToDeliverListProps {
    productsWithAmount: GetProductsFromOrderQuery['order']['preparedProducts']
}

function AlreadyPreparedProducts({ productsWithAmount }: ProductsToDeliverListProps) {
    const lastIndex = productsWithAmount.length - 1
    if (productsWithAmount.length === 0) {
        return <EmptyList />
    }
    return (
        <List component="div" role="list" sx={{ width: "100%" }} disablePadding>
            {productsWithAmount
                .map(({ id, name, productType, amount, grams }, index) => {
                    return (
                        <ProductAmount
                            key={`${id}-${index}`}
                            name={name}
                            isLast={index === lastIndex}
                            amount={amount}
                            grams={grams}
                            productType={productType} />
                    )
                })}
        </List>
    )
}



function PrepareProductsForm({
    order,
    handleSubmit,
    values,
    setWeight,
    removeTray,
    setAmount,
    removeOther,
    onAddOrder,
    getProductById
}: PrepareProductsFormProps) {

    const IceCreamFormControls = Array
        .from(values.trays.entries())
        .flatMap(([productId, trays]) => {
            const product = getProductById(productId)
            if (!product) {
                console.warn('Product was ordered but is no longer provided by supplier. Removing it', {
                    productId,
                    trays
                })
                return []
            }

            return (trays.map((grams, index) => (
                <Product
                    disablePadding
                    className="py-2"
                    product={product}
                    key={`${productId}-${index}`}>
                    <WeightInput
                        name={`trays.${productId}.${index}`}
                        label="Peso de la cubeta"
                        value={grams?.replace(',', '.')}
                        // eslint-disable-next-line eqeqeq
                        error={Boolean(grams) && zodParseWeight(grams ?? '').success === false}
                        helperText={(() => {
                            const res = zodParseWeight(grams ?? '')
                            if (!res.success && Boolean(grams)) {
                                const [firstIssue] = res.error.issues
                                return firstIssue?.message
                            }
                            return undefined
                        })()}
                        onChange={event => {
                            setWeight(productId, index, event.target.value)
                        }} />
                    <IconButton
                        color="error"
                        tabIndex={-1}
                        onClick={() => removeTray(productId, index)}>
                        <RemoveCircleIcon />
                    </IconButton>
                </Product>
            )))
        })

    const OtherProductsFormControls = values.others.map((it, index) => {
        const product = getProductById(it.productId)
        if (!product) {
            console.warn('Product was ordered but is no longer provided by supplier. Removing it', {
                productId: it.productId,
                product: it
            })
            return null
        }

        // Hotfixed
        const orderedProduct = order.verifiedProducts.find(p => p.product.id === it.productId)
        const primaryText = (
            orderedProduct != null
                ? `${product.name} (${orderedProduct.amount} unidades)`
                : `${product.name} (añadido manualmente)`
        )
        return (
            <Product
                key={`${it.productId}-${index}`}
                className="py-2"
                primaryText={primaryText}
                product={product}
                disablePadding >
                <SelectProductQuantity
                    quantity={it.amount ?? 0}
                    setQuantity={amount => setAmount(it.productId, amount)} />
                <IconButton
                    color="error"
                    tabIndex={-1}
                    onClick={() => removeOther(it.productId)}>
                    <RemoveCircleIcon />
                </IconButton>
            </Product>
        )
    })

    const areWeDone = order.pendingPreparationProducts.length === 0 || (
        IceCreamFormControls.filter(Boolean).length === 0 && OtherProductsFormControls.filter(Boolean).length === 0
    )
    return (
        <div className="flex flex-col pt-10 px-5 gap-5 w-full max-w-4xl self-center">
            <OrderMeta order={order} />
            <OrderObservations observations={order.observations} />
            <Collapse sx={{ width: '100%' }} in={areWeDone}>
                <Alert
                    variant='outlined'
                    sx={{ width: '100%' }}
                    severity='success'>
                    Parece que has acabado de preparar el pedido!
                    <br></br>
                    Puedes completarlo o añadir más productos con el botón de abajo
                </Alert>
            </Collapse>
            <div className="py-2">
                {/* Kept for spacing purposes */}
            </div>
            <PrepareProductsTabs
                preparedProductsLength={order.preparedProducts.length}
                prepareProductsForm={
                    <form
                        className="flex flex-col w-full"
                        onSubmit={handleSubmit}>

                        {IceCreamFormControls}

                        {OtherProductsFormControls}

                        <Button
                            type="button"
                            variant="outlined"
                            size='large'
                            onClick={onAddOrder}>
                            <Add />
                            <span className="px-1" />
                            Añadir producto
                        </Button>
                        <ProductListPadding />
                        <Fab
                            type="submit"
                            color="success"
                            size='large'
                            variant="extended"
                            className="py-12"
                            sx={{
                                width: 'auto',
                                position: 'fixed',
                                bottom: 16,
                                right: '50%',
                                transform: 'translateX(50%)'
                            }}
                        >
                            <DoneAllIcon />
                            <span className="px-1" />
                            ¡Lote listo!
                        </Fab>
                    </form>
                }
                preparedProductsList={
                    <AlreadyPreparedProducts productsWithAmount={order.preparedProducts} />
                }
            />
        </div>
    )
}

function usePrepareProductsData(orderNumber: string | undefined) {
    const { data, loading: fetchingProducts } = useQuery(GET_CURRENT_STOCKS_QUERY)
    const productLookup = useMemo(() => {
        return new Map(
            data?.suppliedProducts?.map(it => ([it.id, it])) ?? []
        )
    }, [data])

    const { loading: gettingOrderProducts, ...rest } = useQuery(GET_PRODUCTS_FROM_ORDER_QUERY, {
        variables: { orderNumber: orderNumber ? parseInt(orderNumber) : Number.NaN },
        skip: !orderNumber
    })

    return {
        suppliedProducts: data?.suppliedProducts,
        loading: fetchingProducts || gettingOrderProducts,
        ...rest,
        getProductById: (id: string) => productLookup.get(id)
    }
}


function getProductIdsWithInvalidTrays(trays: Map<string, Array<string | undefined>>) {
    return Array
        .from(trays.entries())
        .filter(([_productId, trays]) => {
            const weightsInGrams = trays.map(tray => tray ? (parseWeight(tray) * 1000) : 0)

            return weightsInGrams.some(weight => weight >= 20_000)
        })
        .map(([productId, _trays]) => productId)
}


export default function PrepareProductsPage() {
    const { orderNumber } = useParams()
    const navigate = useNavigate()
    const [invalidTraysMessage, setInvalidTraysMessage] = useState<string | undefined>(undefined)
    const [showProductPicker, setShowProductPicker] = useState(false)
    const { suppliedProducts, getProductById, data, loading, error } = usePrepareProductsData(orderNumber)
    const [createDeliveryBatch, { loading: creatingBatch }] = useMutation(CREATE_DELIVERY_BATCH_MUTATION, {
        onCompleted: data => {
            setInvalidTraysMessage(undefined)
            formHook.reset()
            navigate(-1)
        },
        refetchQueries: [ORDERS_FOR_DELIVERY_OF_SUPPLIER_QUERY]
    })

    const [submittedForm, setSubmittedForm] = useState<{
        products: WeightedProductAndAmount[]
    } | undefined>(undefined)

    const formHook = usePreparedProductsForm({
        orderNumber,
        products: data?.order.pendingPreparationProducts,
        onSubmit: value => {
            const invalidTrays = getProductIdsWithInvalidTrays(value.trays)
            if (invalidTrays.length > 0) {
                const trayNames = invalidTrays.map(it => getProductById(it)?.name).filter(Boolean).join(', ')
                const message = `Las bandejas de ${trayNames} tienen un peso superior a 20kg. Tiene que ser inferior`
                setInvalidTraysMessage(message)
                return
            }
            const icecreams = Array
                .from(value.trays.entries())
                .map(([productId, trays]) => {
                    const trayWeights = trays.map(trayValue => Math.round(
                        trayValue
                            // Transform kg into grams
                            //!Warning. Note that all trayValue = '' will be transformed into 0's
                            ? parseWeight(trayValue) * 1000
                            : 0
                    ))
                    // Therefore we remove any entries with 0 grams
                    const nonEmptyTrays = trayWeights.filter(trayValue => trayValue > 0)
                    return {
                        productId,
                        amount: nonEmptyTrays.length,
                        grams: nonEmptyTrays.reduce((a, b) => a + b, 0)
                    }
                })

            const others = value.others.map(it => ({
                productId: it.productId,
                amount: it.amount!,
                grams: null
            }))

            // Any products that have not been prepared are removed
            const products = [...icecreams, ...others].filter(it => it.amount > 0)

            if (products.length === 0) {

                // eslint-disable-next-line no-restricted-globals
                const yes = confirm(
                    "No has añadido ningún producto, ¿quieres continuar?"
                )
                if (!yes) return
            }
            console.log('setSubmittedForm', {
                order: { products }
            })

            setSubmittedForm({ products })
        }
    })


    if ((!loading && error?.message === 'not-found') || !orderNumber) {
        return (
            <NotFoundPage>
                <Button component={Link} to="../" variant="contained" color="primary">
                    Volver atrás
                </Button>
            </NotFoundPage >
        )
    }
    if (!loading && data) {
        return <>
            <LinearProgress sx={{ opacity: creatingBatch ? 1 : 0 }} />
            <Snackbar
                open={Boolean(invalidTraysMessage)}
                autoHideDuration={6000}
                onClose={() => setInvalidTraysMessage(undefined)}>
                <Alert
                    variant='filled'
                    severity='error'
                    sx={{ width: '100%' }}
                    onClose={() => setInvalidTraysMessage(undefined)}>
                    {invalidTraysMessage}
                </Alert>
            </Snackbar>
            <PrepareProductsForm
                {...formHook}
                order={data.order}
                onAddOrder={() => setShowProductPicker(true)}
                getProductById={getProductById} />

            <CompleteOrderDialog
                open={submittedForm != null}
                onClose={() => setSubmittedForm(undefined)}
                onComplete={(completeOrder) => {
                    if (!submittedForm) {
                        return
                    }

                    // Already creating; abort
                    if (creatingBatch) {
                        return
                    }

                    const values = {
                        orderNumber: orderNumber ? parseInt(orderNumber) : Number.NaN,
                        products: submittedForm.products,
                        completeOrder
                    }
                    createDeliveryBatch({
                        variables: {
                            batch: {
                                orderNumber: values.orderNumber,
                                weightedProducts: values.products,
                                completeOrder: values.completeOrder
                            }
                        }
                    })
                    console.log('Completing delivery order', values)
                    setSubmittedForm(undefined)
                }}
            />

            <AddProductBottomSheet
                open={showProductPicker}
                products={suppliedProducts}
                onSelectProduct={(prod) => {
                    if (prod.productType === ProductType.Icecream) {
                        formHook.addTray(prod.id)
                        setShowProductPicker(false)
                    }
                    if (prod.productType !== ProductType.Icecream) {
                        formHook.addOther(prod.id)
                        setShowProductPicker(false)
                    }
                }}
                onClose={() => setShowProductPicker(false)}
            />
        </>
    }

    return (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress />
        </Backdrop>
    )
}