import { useTheme } from "@mui/material";
import { DataGridProps, GridValidRowModel, DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import React from "react";
import EmptyRows from "./empty-rows";


export default function BDGDataGrid<R extends GridValidRowModel = any>(props: DataGridProps<R> & React.RefAttributes<HTMLDivElement>) {
    const theme = useTheme()
    return <MuiDataGrid
        sx={{ 
            cursor: 'pointer',
            backgroundColor: theme.palette.background.paper
        }}
        slots={{ noRowsOverlay: EmptyRows }}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        rowCount={props.rows?.length ?? 0}
        paginationMode="server"
        hideFooterPagination
        {...props} />
}

