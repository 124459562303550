import { useMutation, useQuery } from "@apollo/client";
import {
    Button, CircularProgress, FormControlLabel,
    LinearProgress, Switch, Typography
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { APP_SETTINGS_QUERY } from "../../../network/app-settings-query";
import { UPDATE_APP_SETTINGS_MUTATION } from "../../../network/update-app-settings-mutation";

function SysConfigForm(initialValues: { canMakeOrders: boolean }) {
    const [mutate, { loading: updating }] = useMutation(UPDATE_APP_SETTINGS_MUTATION, {
        refetchQueries: [APP_SETTINGS_QUERY]
    })
    const formik = useFormik({
        initialValues,
        onSubmit: values => {
            mutate({
                variables: {
                    settings: {
                        canMakeOrders: values.canMakeOrders
                    }
                }
            })
        }
    })

    return (
        <form className="flex flex-col max-w-lg" onSubmit={formik.handleSubmit}>
            <FormControlLabel
                name="canMakeOrders"
                label="Habilitar hacer pedidos"
                onChange={formik.handleChange}
                value={formik.values['canMakeOrders']}
                checked={formik.values['canMakeOrders']}
                control={ <Switch /> }
            />
            <Button variant="contained" disabled={updating} type="submit">
                Guardar cambios
            </Button>
        </form>
    )

}

export default function SysConfigPage() {
    const { loading, data } = useQuery(APP_SETTINGS_QUERY)
    if (!loading && data) {
        return (
            <div className="flex flex-col w-full h-full p-4">
                <Typography variant="h5">Configuración del sistema</Typography>
                <SysConfigForm {...data.appSettings} />
            </div>
        )
    }
    return <>
        <LinearProgress />
        <div className="flex flex-col justify-center items-center w-full h-full p-4">
            <CircularProgress />
        </div>
    </>

}
