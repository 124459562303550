import { gql } from "../__generated__/gql";


export const ALL_PRODUCTS_QUERY = gql(`
    query AllProducts($productType: ProductType) {
        rawProducts(productType: $productType) {
            id
            name
            productType
            icecreamGroup
            deleted
            returnable
            trays {
                id
                name
            }
        }
    }
`)