import React from 'react'
import IcecreamIcon from '@mui/icons-material/Icecream';
import CakeIcon from '@mui/icons-material/Cake';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavButton from '../../components/nav-button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import CookieIcon from '@mui/icons-material/Cookie';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import { Typography } from '@mui/material';
import { useCurrentOrder } from '../../context/current-order';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { ProductType } from '../../../__generated__/graphql';

interface OrderingNavButtonProps {
    productType: Required<ProductType>
    icon: React.ReactNode    
    children: React.ReactNode
}

const OrderingNavButton = ({productType, icon, children}: OrderingNavButtonProps) => {
    return (
        <NavButton className="w-1/2 md:w-1/4 h-40 px-1" to={`/order-products?productType=${productType}`}>
            {icon}
            {children}
        </NavButton>
    )
}

const NavButtons = [
    {
        productType: ProductType.Icecream,
        icon: <IcecreamIcon fontSize='large'/>,
        children: 'Pedir helado',
    },
    {
        productType: ProductType.Pastry,
        icon: <CakeIcon fontSize='large'/>,
        children: 'Pedir pastelería',
    },
    {
        productType: ProductType.Bakery,
        icon: <BakeryDiningIcon fontSize='large'/>,
        children: 'Pedir panadería',
    },
    {
        productType: ProductType.Accessories,
        icon: <MoreHorizIcon fontSize='large'/>,
        children: 'Pedir accesorios',
    },
    {
        productType: ProductType.BeveragesAndSlushies,
        icon: <LocalDrinkIcon fontSize='large'/>,
        children: 'Pedir bebidas y granizados',
    },
{
        productType: ProductType.RawMaterials,
        icon: <CookieIcon fontSize='large'/>,
        children: 'Pedir materia prima',
},
{

        productType: ProductType.VariousFood,
        icon: <LunchDiningIcon fontSize='large'/>,
        children: 'Pedir comida (varios)',
},
{

        productType: ProductType.Other,
        icon: <SanitizerIcon fontSize='large'/>,
        children: 'Pedir otros',
},

] satisfies Array<OrderingNavButtonProps>


export default function OrderingPage() {
    const {totalProducts} = useCurrentOrder()
    return (
        <>
            <Typography className="flex flex-row justify-center py-2 w-full">
                Tu pedido contiene <strong className='px-1'>{totalProducts}</strong> productos
            </Typography>
            <section className="flex flex-wrap justify-between w-full px-4 gap-y-4">
                {NavButtons.map((props) => <OrderingNavButton key={props.productType} {...props} />)}
                <NavButton className="w-full md:w-1/2 h-40 px-1" to="/order-products" color="info">
                    <VisibilityIcon fontSize='large'/>
                    Ver todos los productos
                </NavButton>
                <NavButton
                    className="w-full md:w-1/2 h-40 px-1" to="/checkout"
                    disabled={totalProducts <= 0} 
                    color="success" >
                    <ShoppingCartCheckoutIcon fontSize='large'/>
                    Revisar y enviar pedido
                </NavButton>
            </section>
        </>
    )
}