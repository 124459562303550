import { gql } from "../__generated__/gql";


export const AVAILABLE_PRODUCTS = gql(/* GraphQL */`
    query AvailableProducts($productType: ProductType) {
        availableProducts(productType: $productType) {
            id
            name
            productType
            icecreamGroup
            returnable
            isFavorite
        }
    }
`)