import React, { useMemo } from "react"
import { FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select as MuiSelect, SelectChangeEvent } from "@mui/material"

export interface SelectOption {
    label: string
    value: string | null,
    disabled?: boolean
}

export interface SelectProps<T extends SelectOption> {
    name?: string
    label: string
    selectedOption: T | null
    values: T[]
    helperText?: string
    setValue: (value: T | null) => void
}

export default function Select<T extends SelectOption>({
    label,
    name,
    selectedOption,
    values,
    setValue,
    error,
    helperText,
    onBlur,
    sx,
    size
}: SelectProps<T> & FormControlProps) {
    const labelId = useMemo(() => `${name}-${Date.now()}`, [name])
    const handleChange = (event: SelectChangeEvent) => {
        const val = event.target.value
        const selectedValue = values.find(({ value }) => `${value}` === val)
        return setValue(selectedValue ?? null)
    }
    const value = selectedOption?.value ?? null
    return (
        <FormControl sx={sx} size={size} error={error} onBlur={onBlur}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <MuiSelect
                id={`mui-select-${labelId}`}
                name={name}
                label={label}
                labelId={labelId}
                value={`${value}`}
                onChange={handleChange}
            >
                {values.map(({ label, value, disabled }) => {
                    return (
                        <MenuItem key={`${value}`} value={`${value}`} disabled={disabled}>
                            {label ?? `${value}`}
                        </MenuItem>
                    )
                })}
            </MuiSelect>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}