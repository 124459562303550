import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { z } from 'zod';
import { LIST_MY_ISSUES_QUERY } from '../../../network/issues/list-my-issues-query';
import { CREATE_ISSUE_MUTATION } from '../../../network/issues/create-issue-mutation';
import Issue from '../../components/Issue';
import BottomSheetDialog from '../../components/bottom-sheet/BottomSheetDialog';
import ErrorMessage from '../../components/error-message';
import LoadingButton from '../../components/loading-button';
import { validationFrom } from '../../forms/validation';

const tomorrow = () => (dayjs()
    .add(1, "day")
    .startOf("day")
    .toDate()
)


const zodSchema = z.object({
    pickupDate: z
        .coerce
        .date({
            required_error: 'Selecciona una fecha de recogida'
        })
        // The pickup date is valid if the pickup date is tomorrow or later
        .refine((date) => tomorrow().getTime() <= date.getTime(), {
            message: 'La fecha de recogida debe ser mañana o más tarde'
        }),
    observations: z.string({
        required_error: 'Debes dejar alguna indicación al repartidor sobre lo que debe recojer'
    })
})

const validationSchema = validationFrom(zodSchema)

interface CreateIssueFormProps {
    open: boolean;
    onClose: () => void;
}

function CreateIssueForm({ open, onClose }: CreateIssueFormProps) {
    const [mutate, { loading, error }] = useMutation(CREATE_ISSUE_MUTATION, {
        onCompleted: () => {
            onClose()
        },
        refetchQueries: [LIST_MY_ISSUES_QUERY]
    })

    const formik = useFormik({
        validationSchema,
        initialValues: {
            // Get tomorrow's date
            pickupDate: tomorrow(),
            observations: ''
        },
        onSubmit: async (values) => {
            try {
                await mutate({
                    variables: {
                        request: {
                            toBePickedUpOn: dayjs(values.pickupDate).toDate(),
                            observations: values.observations
                        }
                    }
                })
                formik.resetForm()
            } catch (_) {

            }
        }
    })

    const handleDateChange = (date: Dayjs | null) => {
        formik.setFieldValue('pickupDate', date)
    };

    return (
        <BottomSheetDialog keepMounted={false} open={open} onClose={onClose}>
            <DialogTitle className="text-center">
                Crear incidencia
            </DialogTitle>
            <form className='flex flex-col w-full' onSubmit={formik.handleSubmit}>
                <DialogContent className="flex flex-col w-full">
                    {error && <ErrorMessage description={error.message} />}
                    <DatePicker
                        className="!mx-4 !mt-4"
                        label="Debe ser recogido"
                        value={dayjs(formik.values.pickupDate)}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                                helperText: formik.errors.pickupDate as string
                            },
                        }}
                        shouldDisableDate={(date) => date.isBefore(tomorrow(), "day")}
                    />
                    <FormControl className="!mx-4 !mt-4">
                        <FormLabel>Observaciones</FormLabel>
                        <textarea
                            className='mt-2 p-2 rounded-lg border border-gray'
                            style={{ resize: 'none' }}
                            rows={6}
                            placeholder='Necesito que se recojan 3 cubetas de helado y una caja de refrescos…'
                            value={formik.values.observations}
                            onChange={(ev) => formik.setFieldValue('observations', ev.target.value)}
                        />

                        <FormHelperText error>{formik.errors.observations}</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions className="flex flex-row !mx-8 !my-4 ">
                    <Button className="w-40" variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>
                    <LoadingButton isLoading={loading} className="w-40" variant="contained" disabled={!formik.isValid} type="submit">
                        Enviar
                    </LoadingButton>
                </DialogActions>
            </form>
        </BottomSheetDialog >
    )
}


export default function MyIssuesPage() {
    const { data, loading, error } = useQuery(LIST_MY_ISSUES_QUERY)
    const [state, setState] = useState({
        showForm: false
    })

    const onFabClick = () => setState((prev) => ({ ...prev, showForm: true }))

    return (
        <div className="flex flex-col w-full h-full p-4  max-w-4xl mx-auto">
            <Typography variant='h5' className="pb-4">
                Tus incidencias
            </Typography>

            {error && <ErrorMessage description={error.message} />}
            
            <div className="flex flex-wrap w-full justify-around gap-4 ">
                {!loading && data && data.myIssues.map(req => (
                    <Issue key={req.id} {...req}/>
                ))}
            </div>

            <CreateIssueForm
                open={state.showForm}
                onClose={() => setState((prev) => ({ ...prev, showForm: false }))} />

            <Fab
                color="success"
                size='large'
                disabled={loading}
                variant={loading ? 'circular' : 'extended'}
                style={{ position: 'fixed', bottom: 24, right: 24 }}
                onClick={onFabClick}
            >
                {loading && <CircularProgress color="success" />}
                {!loading && <><AddIcon className='mr-2' />
                    Crear incidencia
                </>}
            </Fab>
        </div>
    )
}
