import { gql } from "../../../__generated__/gql";

export const LOCATION_BY_ID_WITH_SUPPLIED_PRODUCTS_QUERY = gql(/* GraphQL */`
    query LocationById($id: ID!) {
        locationById(id: $id) {
            id
            name
            suppliedProducts {
                ...CommonSupplierProduct
            }
        }
    }
`)